import { authorised } from "../../request";
import { ProductOption } from "../../types/ProductOption";

export async function createProductOption(data: Omit<ProductOption, "id">) {
  const API = authorised();

  return await API.request<ProductOption>({
    method: "POST",
    url: "/api/admin/product-options/",
    data
  });
}
