import React, { useState, useEffect } from "react";
import * as Formik from "formik";
import Typography from "@material-ui/core/Typography";
import { css } from "styled-components/macro";
import { SketchPicker } from "react-color";
import { useDebounce } from "../../helpers/useDebounce";

function ColorPicker({
  onChange,
  ...props
}: {
  color: string;
  onChange: (color: string) => void;
}) {
  const [color, setColor] = useState(props.color);
  const debouncedColor = useDebounce(color, 500);

  useEffect(() => {
    if (debouncedColor !== props.color) {
      onChange(color);
    }
  }, [color, debouncedColor, onChange, props.color]);

  return (
    <SketchPicker
      color={color}
      disableAlpha
      presetColors={[
        "#000000",
        "#272727",
        "#333333",
        "#ffffff",
        "#a3a3a3",
        "#f1f1f1",
      ]}
      onChange={(color) => {
        setColor(color.hex);
      }}
    />
  );
}

export function ColorPickerField(props: { name: string; label: string }) {
  const [showSelector, setShowSelector] = useState(false);

  return (
    <div>
      <Typography variant="body2">{props.label}</Typography>
      <Formik.Field
        name={props.name}
        render={({ form, field }: Formik.FieldProps) => {
          return (
            <div
              css={css`
                position: relative;
              `}
            >
              <div
                css={css`
                  margin-top: 10px;
                  background-color: ${field.value || "#ffffff"};
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  border: 1px solid grey;
                  cursor: pointer;
                `}
                onClick={() => {
                  setShowSelector(true);
                }}
              />
              {showSelector && (
                <div
                  css={css`
                    position: absolute;
                    z-index: 100;
                    left: -90px;
                    top: 50px;
                  `}
                >
                  <ColorPicker
                    color={field.value || "#ffffff"}
                    onChange={(color) => {
                      form.setFieldValue(props.name, color);
                      setShowSelector(false);
                    }}
                  />
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
}
