import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { User } from "../types/User";
import { SelectField } from "./fields/SelectField";
import { Error } from "../components/Error";
import { ConfirmModal } from "../modals/ConfirmModal";
import { CheckboxWithLabel } from "formik-material-ui";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import { AxiosError } from "axios";

type FormValues = Pick<
  User,
  "username" | "last_name" | "first_name" | "profile" | "date_joined"
>;
type Callback = () => void;

export function UserForm(props: {
  type: "update";
  initialValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={props.initialValues}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => {
        const joinedDate = new Date(values.date_joined);
        const acceptedTermsOfSale =
          values.profile && values.profile.terms_of_service
            ? new Date(values.profile.terms_of_service)
            : null;
        const tos =
          values.profile && values.profile.tos ? values.profile.tos : false;

        const cookiesDate =
          values.profile && values.profile.cookies_accepted_at
            ? new Date(values.profile.cookies_accepted_at)
            : null;

        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <div>
                        <Formik.FastField
                          name="first_name"
                          placeholder="First name"
                          type="text"
                          fullWidth
                          component={TextField}
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <Formik.FastField
                          name="last_name"
                          placeholder="Last name"
                          type="text"
                          fullWidth
                          component={TextField}
                          variant="outlined"
                        />
                      </div>
                      <SelectField
                        label="Type"
                        name={`profile.type`}
                        options={[
                          {
                            value: "CUSTOMER",
                            label: "CUSTOMER",
                          },
                          {
                            value: "DESIGNER",
                            label: "DESIGNER",
                          },
                          {
                            value: "ADMIN",
                            label: "ADMIN",
                          },
                        ]}
                      />
                      <div>
                        <Formik.FastField
                          name={"profile.optout_price_increase"}
                          Label={{
                            label: "Opt-out from price increase emails?",
                          }}
                          component={CheckboxWithLabel}
                        />
                      </div>
                      <hr />
                      <div>
                        <Typography variant="h4">Other Details</Typography>
                        <p>
                          <strong>Email: </strong> {values.username}
                        </p>
                        {values.profile && (
                          <p>
                            <strong>How Did You Find Drew: </strong>
                            {values.profile.how_did_you_find}
                            {": "}
                            {values.profile.how_did_you_find_value}
                          </p>
                        )}
                        <p>
                          <strong>Join Date: </strong>
                          {format(joinedDate, "MMM do, YYY")} at{" "}
                          {format(joinedDate, "hh:mm a")}
                        </p>
                        {acceptedTermsOfSale && (
                          <p>
                            <strong>Accepted Terms of Sale Date: </strong>
                            {format(acceptedTermsOfSale, "MMM do, YYY")} at{" "}
                            {format(acceptedTermsOfSale, "hh:mm a")}
                          </p>
                        )}
                        {tos && (
                          <p>
                            <strong>Accepted Terms of Service Date: </strong>
                            {format(joinedDate, "MMM do, YYY")} at{" "}
                            {format(joinedDate, "hh:mm a")}
                          </p>
                        )}

                        {cookiesDate && (
                          <p>
                            <strong>Cookies accepted on: </strong>
                            {format(cookiesDate, "MMM do, YYY")} at{" "}
                            {format(cookiesDate, "hh:mm a")}
                          </p>
                        )}
                      </div>
                    </Column>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Row justify="flex-end">
                        {props.onDelete && (
                          <div>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                setConfirmAction(() => {
                                  return props.onDelete;
                                });
                              }}
                            >
                              Delete
                            </Button>
                            {confirmAction !== null && (
                              <ConfirmModal
                                confirmAction={confirmAction}
                                handleClose={() => {
                                  setConfirmAction(null);
                                }}
                                open={true}
                                label="Please confirm deletion"
                              />
                            )}
                          </div>
                        )}
                        <div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={25} />
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </div>
                      </Row>
                      {error && <Error text={error} />}
                    </Column>
                  </Paper>
                </Column>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
