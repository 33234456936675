import React, { useState } from "react";
import * as Formik from "formik";
import Button from "@material-ui/core/Button";
import TrashIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDuplicateIcon from "@material-ui/icons/FileCopy";
import ArrowReplyIcon from "@material-ui/icons/Reply";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Column, Row, Spacing } from "../helpers/layout";
import { css } from "styled-components/macro";
import { ArrowTooltip } from "../components/ArrowTooltip";
import { Option, OptionSelector } from "../modals/OptionSelector";
import Typography from "@material-ui/core/Typography";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Page } from "../types/Page";
import { Serie } from "../types/Serie";
import { cloneBlock as clonePageBlock } from "../actions/page/cloneBlock";
import { cloneSerieBlock } from "../actions/serie/cloneSerieBlock";

type Callback = () => void;

type DuplicateType = "serie" | "page";

function DefaultWrapper(props: { children: React.ReactNode }) {
  return (
    <div
      css={css`
        padding-bottom: ${Spacing.m};
        border-bottom: 1px solid #eee;
      `}
    >
      {props.children}
    </div>
  );
}

export const RepeaterItem = React.memo(function (props: {
  index: number;
  count: number;
  children: React.ReactNode | React.ReactNode[];
  wrapper?: React.FunctionComponent<{
    children: React.ReactNode;
  }>;
  allowDuplicate?: boolean;
  enableReordering?: boolean;
  onDelete: () => void;
  onDuplicate: (option?: Option, type?: DuplicateType) => void;
  onMove: (position: number) => void;
  pagesList?: Page[];
  seriesList?: Serie[];
}) {
  const { index } = props;
  const Wrapper = props.wrapper ? props.wrapper : DefaultWrapper;
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);
  const [duplicate, setDuplicate] = useState<boolean>(false);

  const options = props.pagesList
    ? props.pagesList.map((item: Page) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  const seriesOptions = props.seriesList
    ? props.seriesList.map((item: Serie) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  return (
    <Wrapper>
      <Row>
        <div
          css={css`
            flex: 1 0 50%;
            min-width: 0;
          `}
        >
          {props.children}
        </div>
        <div>
          <Row>
            {props.allowDuplicate && (
              <ArrowTooltip title={"Duplicate"}>
                <>
                  <ArrowDuplicateIcon
                    titleAccess="Duplicate on this page"
                    onClick={() => props.onDuplicate()}
                  />
                  {props.pagesList && (
                    <>
                      <ArrowReplyIcon
                        onClick={() => setDuplicate(true)}
                        titleAccess="Duplicate on Other Page"
                      />
                      <OptionSelector
                        open={duplicate}
                        options={options}
                        onSubmit={(option: Option) =>
                          props.onDuplicate(option, "page")
                        }
                        handleClose={() => setDuplicate(false)}
                      />
                    </>
                  )}
                  {props.seriesList && (
                    <>
                      <ArrowReplyIcon
                        onClick={() => setDuplicate(true)}
                        titleAccess="Duplicate on Other Page"
                      />
                      <OptionSelector
                        open={duplicate}
                        options={seriesOptions}
                        onSubmit={(option: Option) =>
                          props.onDuplicate(option, "serie")
                        }
                        handleClose={() => setDuplicate(false)}
                      />
                    </>
                  )}
                </>
              </ArrowTooltip>
            )}
            {props.enableReordering && (
              <React.Fragment>
                {index - 1 >= 0 && (
                  <ArrowTooltip title={"Move up"}>
                    <ArrowUpwardIcon onClick={() => props.onMove(index - 1)} />
                  </ArrowTooltip>
                )}
                {index + 1 < props.count && (
                  <ArrowTooltip title={"Move down"}>
                    <ArrowDownwardIcon
                      onClick={() => props.onMove(index + 1)}
                    />
                  </ArrowTooltip>
                )}
              </React.Fragment>
            )}

            <ArrowTooltip title="Delete">
              <TrashIcon
                onClick={() => {
                  setConfirmAction(() => {
                    return props.onDelete;
                  });
                }}
              />
            </ArrowTooltip>
            {confirmAction !== null && (
              <ConfirmModal
                confirmAction={confirmAction}
                handleClose={() => {
                  setConfirmAction(null);
                }}
                open={true}
                label="Please confirm deletion"
              />
            )}
          </Row>
        </div>
      </Row>
    </Wrapper>
  );
});

export function Repeater<T>(props: {
  name: string;
  wrapper?: React.FunctionComponent<{
    children: React.ReactNode;
  }>;
  label?: string;
  addNewLabel?: string;
  enableReordering?: boolean;
  allowDuplicate?: boolean;
  options?: {
    label: string;
    icon?: JSX.Element;
    initialValue: T;
  }[];
  generateNewObject: (option?: object) => object;
  children: (props: { idx: number; value: T[] }) => React.ReactNode;
  pagesList?: Page[];
  seriesList?: Serie[];
  enableDuplicateToOtherPage?: boolean;
}) {
  const [insertPosition, setInsertPosition] = useState<null | number>(null);

  return (
    <Formik.Field
      name={props.name}
      render={({ field }: Formik.FieldProps) => {
        const value = field.value || [];

        return (
          <Formik.FieldArray
            name={props.name}
            render={(arrHelper) => {
              return (
                <React.Fragment>
                  <Column>
                    {props.label && (
                      <Typography variant="body2">{props.label}</Typography>
                    )}
                    <Column>
                      {value.map(
                        (
                          item: T & {
                            uuid?: string;
                            type: string;
                          },
                          index: number
                        ) => {
                          return (
                            <div
                              key={`${field.name}--item-${item.uuid || index}`}
                            >
                              <RepeaterItem
                                key={`${field.name}--item-${
                                  item.uuid || index
                                }`}
                                index={index}
                                count={field.value.length}
                                onDelete={() => {
                                  arrHelper.remove(index);
                                }}
                                allowDuplicate={props.allowDuplicate}
                                pagesList={props.pagesList}
                                seriesList={props.seriesList}
                                onMove={(position) => {
                                  arrHelper.move(index, position);
                                }}
                                onDuplicate={(
                                  option?: Option,
                                  type?: DuplicateType
                                ) => {
                                  const newItem = props.generateNewObject(item);
                                  if (!option || !type) {
                                    arrHelper.insert(index, newItem);
                                    return null;
                                  }

                                  if (
                                    props.enableDuplicateToOtherPage &&
                                    option &&
                                    option.value
                                  ) {
                                    if (type === "page") {
                                      clonePageBlock(option.value, newItem);
                                    }
                                    if (type === "serie") {
                                      cloneSerieBlock(
                                        option.value,
                                        newItem,
                                        item.type === "products" ? 1 : 0
                                      );
                                    }
                                  }
                                  return null;
                                }}
                                wrapper={props.wrapper}
                                enableReordering={props.enableReordering}
                              >
                                {props.children({
                                  idx: index,
                                  value: field.value,
                                })}
                              </RepeaterItem>
                              {index !== value.length - 1 && (
                                <div
                                  css={css`
                                    margin-top: ${Spacing.m};
                                  `}
                                >
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                      const position = index + 1;

                                      if (props.options) {
                                        setInsertPosition(position);
                                      } else if (props.generateNewObject) {
                                        arrHelper.insert(
                                          position,
                                          props.generateNewObject()
                                        );
                                      }
                                    }}
                                  >
                                    {props.addNewLabel}
                                  </Button>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                      <div>
                        <Button
                          fullWidth
                          variant="contained"
                          color="default"
                          onClick={() => {
                            const position = value.length;

                            if (props.options) {
                              setInsertPosition(position);
                            } else if (props.generateNewObject) {
                              arrHelper.insert(
                                position,
                                props.generateNewObject()
                              );
                            }
                          }}
                        >
                          {props.addNewLabel}
                        </Button>
                      </div>
                    </Column>
                  </Column>
                  {props.options && (
                    <OptionSelector
                      options={props.options}
                      open={insertPosition !== null}
                      handleClose={() => {
                        setInsertPosition(null);
                      }}
                      onSubmit={(option) => {
                        if (insertPosition !== null) {
                          arrHelper.insert(
                            insertPosition,
                            props.generateNewObject(option.initialValue)
                          );
                        }
                      }}
                    />
                  )}
                </React.Fragment>
              );
            }}
          />
        );
      }}
    />
  );
}
