import { authorised } from "../../request";
import { ProductOption } from "../../types/ProductOption";

export async function deleteProductOption(id: string) {
  const API = authorised();

  return await API.request<ProductOption>({
    method: "DELETE",
    url: `/api/admin/product-options/${id}/`
  });
}
