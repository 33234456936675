import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Paper } from "../../components/Paper";
import { Setting } from "../../types/Setting";
import { RouteChildrenProps } from "react-router";
import { Breadcrumbs } from "../../components/Breadcrumbs";

export function SettingList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Typography color="textPrimary">Settings</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Setting>
          url="settings"
          head={[{ label: "Name" }, { label: "Actions", align: "right" }]}
        >
          {result => {
            return (
              <TableRow key={result.id}>
                <TableCell>{result.name} </TableCell>
                <TableCell align="right">
                  <Link to={`/settings/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
