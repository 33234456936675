import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PageForm } from "../../forms/PageForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updatePage } from "../../actions/page/updatePage";
import { deletePage } from "../../actions/page/deletePage";
import { useDetail, useList } from "../../useAPI";
import { Page } from "../../types/Page";

export function PageEdit(
  props: RouteChildrenProps<{
    pageId: string;
  }>
) {
  const pageId = props.match!.params.pageId;
  const { data, error } = useDetail<Page>("pages", pageId);
  const { results } = useList<Page>('pages', 1, {'page_size': 100});

  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/pages"}>Pages</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <PageForm
          type="update"
          initialValues={data}
          pagesList={results}
          onSubmit={async values => {
            await updatePage(pageId, values);
          }}
          onDelete={async () => {
            await deletePage(pageId);

            props.history.push("/pages");
          }}
        />
      )}
    </Layout>
  );
}
