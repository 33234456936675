import { authorised } from "../../request";
import { Page } from "../../types/Page";

export async function cloneBlock(id: string | number, data: any): Promise<{}> {
  const API = authorised();

  return await API.request<Page>({
    method: "PUT",
    url: `/api/admin/pages/${id}/add-block/`,
    data
  });
}
