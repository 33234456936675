import { authorised } from "../../request";
import { InHomeTag } from "../../types/InHomeTag";

export async function updateInHomeTag(id: string, data: Omit<InHomeTag, "id">) {
  const API = authorised();

  return await API.request<InHomeTag>({
    method: "PATCH",
    url: `/api/admin/in-home-tags/${id}/`,
    data
  });
}
