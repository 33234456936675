import React from "react";
import { Column, Row } from "../../helpers/layout";
import { ImageField } from "./ImageField";
import { SelectField } from "./SelectField";
import { paddingOptions } from "../../helpers/form";
import styled from "styled-components/macro";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export const BlockImageField = (props: { name: string }) => {
  return (
    <Column>
      <Row>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding top"
            name={`${props.name}.padding.top`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding bottom"
            name={`${props.name}.padding.bottom`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding left"
            name={`${props.name}.padding.left`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding right"
            name={`${props.name}.padding.right`}
            options={paddingOptions}
          />
        </FieldWrapper>
      </Row>
      <SelectField
        fullWidth
        label="Size"
        name={`${props.name}.size`}
        options={[
          {
            value: "30",
            label: "30%",
          },
          {
            value: "40",
            label: "40%",
          },
          {
            value: "50",
            label: "50%",
          },
          {
            value: "60",
            label: "60%",
          },
          {
            value: "80",
            label: "80%",
          },
          {
            value: "100",
            label: "100%",
          },
        ]}
      />
      <ImageField name={`${props.name}.image`} label="Image" related />
    </Column>
  );
};
