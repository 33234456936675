import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { EstimateForm } from "../../forms/EstimateForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateEstimate } from "../../actions/estimate/updateEstimate";
import { deleteEstimate } from "../../actions/estimate/deleteEstimate";
import { createEstimate } from "../../actions/estimate/createEstimate";
import { useDetail } from "../../useAPI";
import { Estimate } from "../../types/Estimate";
import { createEstimateItem } from "../../actions/estimate/createEstimateItem";

export function EstimateEdit(
  props: RouteChildrenProps<{
    estimateId: string;
  }>
) {
  const estimateId = props.match!.params.estimateId;
  const { data, error } = useDetail<Estimate>("estimates", estimateId);

  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/estimates"}>Estimates</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.id : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <EstimateForm
          type="update"
          key={data.id}
          urlhash={data.urlhash}
          initialValues={data}
          onSubmit={async (values) => {
            await updateEstimate(estimateId, values);
          }}
          onDuplicate={async (values) => {
            const items = await Promise.all(
              values.items.map(async (item) => {
                const response = await createEstimateItem(item);

                return response.data;
              })
            );

            const response = await createEstimate({
              name: values.name || "New estimate",
              custom_discount: values.custom_discount,
              items: items,
            });

            props.history.push(`/estimates/${response.data.id}`);
          }}
          onDelete={async () => {
            await deleteEstimate(estimateId);

            props.history.push("/estimates");
          }}
        />
      )}
    </Layout>
  );
}
