import React from "react";
import { css } from "styled-components/macro";
import { ProductOption } from "../types/ProductOption";

export function PrintDimensions(props: {
  calculated: {
    width: number;
    gap: number;
    individualWidth?: number;
    height: number;
    individualHeight?: number;
  };
  selectedOptions: ProductOption[];
}) {
  const { calculated, selectedOptions } = props;

  const frame = selectedOptions.find((option) => option.type === "FRAME");
  const isUnframed = !frame || frame.name === "Unframed";
  const label = isUnframed ? "Unframed" : "Framed";

  return (
    <div>
      {calculated.width !== calculated.individualWidth ? (
        <React.Fragment>
          <h5
            css={css`
              font-size: 12px;
              letter-spacing: 0.05em;
            `}
          >
            Approximate {label} Dimensions: {calculated.width}" x{" "}
            {calculated.height}" (Individual images:{" "}
            {calculated.individualWidth}" x {calculated.individualHeight}
            ")
          </h5>
          <p
            css={css`
              font-size: 12px;
            `}
          >
            *This figures are approximate and include a {calculated.gap}” gap
            between images. Overall dimensions will vary based on how the images
            are hung.
          </p>
        </React.Fragment>
      ) : (
        <h5
          css={css`
            font-size: 12px;
            letter-spacing: 0.05em;
          `}
        >
          Approximate {label} Dimensions: {calculated.width}" x{" "}
          {calculated.height}"
        </h5>
      )}
    </div>
  );
}
