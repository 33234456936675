import { authorised } from "../../request";
import { PostTag } from "../../types/PostTag";

export async function createPostTag(data: Omit<PostTag, "id">) {
  const API = authorised();

  return await API.request<PostTag>({
    method: "POST",
    url: "/api/admin/post-tags/",
    data
  });
}
