import React from "react";
import * as Formik from "formik";
import { Column, Row } from "../../helpers/layout";
import { EditorField } from "./EditorField";
import { ImageField } from "./ImageField";
import { Repeater } from "../../components/Repeater";
import { Paper } from "../../components/Paper";
import { TextField } from "./TextField";
import { SelectField } from "./SelectField";
import { CheckboxWithLabel } from "formik-material-ui";

const CarouselItemsField = React.memo(function(props: {
  name: string;
  nested: boolean;
}) {
  const { name } = props;

  return (
    <Repeater
      name={`${name}.items`}
      wrapper={Paper}
      label="Carousel Items"
      enableReordering
      addNewLabel={"New Item"}
      generateNewObject={() => {
        return {
          name: "",
          description: "",
          button_url: "",
          label: "",
          image: null
        };
      }}
    >
      {({ idx }) => {
        return (
          <Row key={idx}>
            <Column>
              <Formik.FastField
                name={`${name}.items[${idx}].name`}
                label="Name"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              {props.nested && (
                <>
                  <Formik.FastField
                    name={`${name}.items[${idx}].subtitle_1`}
                    label="Subtitle 1"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name={`${name}.items[${idx}].subtitle_2`}
                    label="Subtitle 2"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                </>
              )}
              <Formik.FastField
                name={`${name}.items[${idx}].description`}
                label="Description"
                type="text"
                multiline
                fullWidth
                rows="4"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              <Formik.FastField
                name={`${name}.items[${idx}].button_url`}
                label="Button Url"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              <Formik.FastField
                name={`${name}.items[${idx}].label`}
                label="Label"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            </Column>
            <ImageField
              name={`${name}.items[${idx}].image`}
              label="Image"
              related
            />
          </Row>
        );
      }}
    </Repeater>
  );
});

export function BlockCarouselField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <SelectField
              label="Style"
              name={`${field.name}.style`}
              options={[
                {
                  value: "scroller",
                  label: "Scroller"
                },
                {
                  value: "slider",
                  label: "Slider"
                },
                {
                  value: "nested-slider",
                  label: "Nested Slider"
                }
              ]}
            />
            {field.value.style !== "nested-slider" && (
              <Formik.FastField
                name={`${field.name}.show_text`}
                Label={{ label: "Show text?" }}
                component={CheckboxWithLabel}
              />
            )}
            {(field.value.show_text ||
              field.value.style === "nested-slider") && (
              <SelectField
                label="Text Position"
                name={`${field.name}.layout`}
                options={[
                  {
                    value: "leftside",
                    label: "Leftside"
                  },
                  {
                    value: "rightside",
                    label: "Rightside"
                  }
                ]}
              />
            )}
            {field.value.show_text && (
              <EditorField name={`${field.name}.text`} label="Text" />
            )}
            <CarouselItemsField
              name={field.name}
              nested={field.value.style === "nested-slider"}
            />
          </Column>
        );
      }}
    />
  );
}
