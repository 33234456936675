import React, { useState } from "react";
import * as Formik from "formik";
import { css } from "styled-components/macro";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { MediaUploader } from "../../modals/MediaUploader";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ConfirmModal } from "../../modals/ConfirmModal";
import { FilePreview } from "../../components/FilePreview";
import { Row } from "../../helpers/layout";

type Callback = () => void;

export function FileField(props: {
  name: string;
  label: string;
  style?: "button";
  related?: boolean;
}) {
  const [showUploader, setShowUploader] = useState(false);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Field
      name={props.name}
      render={({ form, field }: Formik.FieldProps) => {
        return (
          <React.Fragment>
            {props.style === "button" ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowUploader(true);
                }}
              >
                {field.value ? "Change file" : "Select file"}
              </Button>
            ) : (
              <div>
                <Typography variant="body2">{props.label}</Typography>
                <div
                  css={css`
                    margin-top: 10px;
                  `}
                >
                  {!field.value && (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 200px;
                        border-radius: 10px;
                        background: #f5f5f5;
                      `}
                      onClick={() => {
                        setShowUploader(true);
                      }}
                    >
                      <div
                        css={css`
                          width: 200px;
                          text-align: center;
                        `}
                      >
                        <FileCopyIcon />
                        <p>Select File</p>
                      </div>
                    </div>
                  )}
                  {field.value && (
                    <Row align="center">
                      <div>
                        <FilePreview
                          file={props.related ? field.value.value : field.value}
                        />
                      </div>
                      <div>
                        <Fab
                          size={"small"}
                          color="secondary"
                          onClick={() => {
                            setConfirmAction(() => {
                              return () => {
                                form.setFieldValue(props.name, null);
                              };
                            });
                          }}
                        >
                          <DeleteIcon />
                        </Fab>
                      </div>
                      <div>
                        <Fab
                          size={"small"}
                          color="primary"
                          onClick={() => {
                            setShowUploader(true);
                          }}
                        >
                          <EditIcon />
                        </Fab>
                      </div>
                    </Row>
                  )}
                </div>
              </div>
            )}
            {confirmAction !== null && (
              <ConfirmModal
                confirmAction={confirmAction}
                handleClose={() => {
                  setConfirmAction(null);
                }}
                open={true}
                label="Please confirm deletion"
              />
            )}
            {showUploader && (
              <MediaUploader
                open={true}
                handleClose={() => {
                  setShowUploader(false);
                }}
                onSelect={(file) => {
                  if (props.related) {
                    form.setFieldValue(props.name, {
                      type: "Related",
                      model: "Media",
                      value: file,
                    });
                  } else {
                    form.setFieldValue(props.name, file);
                  }
                }}
              />
            )}
          </React.Fragment>
        );
      }}
    />
  );
}
