import { authorised } from "../../request";
import { Press } from "../../types/Press";

export async function updatePress(id: string, data: Omit<Press, "id">) {
  const API = authorised();

  return await API.request<Press>({
    method: "PATCH",
    url: `/api/admin/press/${id}/`,
    data
  });
}
