import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { SelectField } from "./fields/SelectField";
import { PickField } from "./fields/PickField";
import { SlugField } from "./fields/SlugField";
import { Serie } from "../types/Serie";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { SlugAutoCompleteField } from "./fields/SlugAutoCompleteField";
import { ImageField } from "./fields/ImageField";
import { formatISO } from "date-fns";
import { DateField } from "./fields/DateField";
import { CheckboxWithLabel } from "formik-material-ui";
import { getFrontendUrl } from "../helpers/router";
import FindInPage from "@material-ui/icons/FindInPage";
import { FormRow, FormLeftside, FormRightside } from "./Layout";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<
  Serie,
  | "name"
  | "slug"
  | "menu"
  | "description"
  | "keywords"
  | "visibility"
  | "products"
  | "featured_image"
  | "genre"
  | "about"
  | "location"
  | "copyright_holder"
  | "copyright_year"
  | "published_date"
  | "family_friendly"
>;
type Callback = () => void;

export function SerieConfigurationForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          slug: "",
          menu: null,
          description: "",
          keywords: "",
          visibility: "DRAFT",
          products: [],
          featured_image: null,
          genre: "",
          about: "",
          location: "",
          copyright_holder: "",
          copyright_year: formatISO(new Date()),
          published_date: formatISO(new Date()),
          family_friendly: true,
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        slug: Yup.string().required(),
        description: Yup.string(),
        keywords: Yup.string(),
        visibility: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Formik.Form>
          <FormRow>
            <FormLeftside>
              <Column>
                <Paper>
                  <Formik.FastField
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={SlugAutoCompleteField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="slug"
                    label="Slug"
                    type="text"
                    fullWidth
                    component={SlugField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="description"
                    label="Description"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="keywords"
                    label="Keywords"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                </Paper>
                <Paper>
                  <Formik.FastField
                    name="genre"
                    label="Genre"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="about"
                    label="About"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="location"
                    label="Location"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="copyright_holder"
                    label="Copyright Holder"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <DateField name="copyright_year" label="Copyright year" />
                  <DateField name="published_date" label="Published date" />
                  <div>
                    <Formik.FastField
                      name={"family_friendly"}
                      Label={{ label: "Family Friendly?" }}
                      component={CheckboxWithLabel}
                    />
                  </div>
                </Paper>
                <Paper>
                  <Column>
                    <PickField
                      name="products"
                      multiple
                      label="Products"
                      url="products"
                      enableSearch
                    />
                    <PickField
                      name="menu"
                      label="Menu"
                      url="menus"
                      enableSearch
                    />
                  </Column>
                </Paper>
              </Column>
            </FormLeftside>
            <FormRightside>
              <Column>
                <Paper>
                  <Column>
                    <SelectField
                      label="Visibility"
                      name="visibility"
                      options={[
                        {
                          value: "PUBLIC",
                          label: "Public",
                        },
                        {
                          value: "PRIVATE",
                          label: "Private",
                        },
                        {
                          value: "DRAFT",
                          label: "Draft",
                        },
                        {
                          value: "ARCHIVED",
                          label: "Archived",
                        },
                      ]}
                    />
                    <Row justify="flex-end">
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          window.open(
                            getFrontendUrl(`series/${values.slug}/shop`),
                            "_blank"
                          );
                        }}
                      >
                        <FindInPage />
                      </Button>
                      {props.onDelete && (
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setConfirmAction(() => {
                                return props.onDelete;
                              });
                            }}
                          >
                            Delete
                          </Button>
                          {confirmAction !== null && (
                            <ConfirmModal
                              confirmAction={confirmAction}
                              handleClose={() => {
                                setConfirmAction(null);
                              }}
                              open={true}
                              label="Please confirm deletion"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : props.type === "update" ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </div>
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
                <Paper>
                  <ImageField name={"featured_image"} label="Featured Image" />
                </Paper>
              </Column>
            </FormRightside>
          </FormRow>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
