import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Fab from "@material-ui/core/Fab";
import { ListTable } from "./ListTable";
import { Order } from "../types/Order";
import NumberFormat from "react-number-format";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import EditIcon from "@material-ui/icons/Edit";
import { css } from "styled-components/macro";

const getStatusColor = (order: Order) => {
  switch (order.status) {
    case "PENDING_PAYMENT":
      return "#ccc";

    case "COMPLETED":
    case "REFUNDED":
      return "#00A01D";

    case "PAYMENT_FAILED":
    case "CANCELED":
      return "red";
  }
};

const getStatusLabel = (order: Order) => {
  switch (order.status) {
    case "PENDING_PAYMENT":
      return "Pending payment";

    case "COMPLETED":
      return "Completed";

    case "REFUNDED":
      return "REFUNDED";

    case "PAYMENT_FAILED":
      return "Payment failed";

    case "CANCELED":
      return "Canceled";
  }
};

export function OrderListContent(props: { showMax?: number }) {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const order = query.get('ordering');
  const status = order && order === 'status,-id' ? '-status' : 'status'
  const direction = status === 'status' ? 'asc' : 'desc'

  return (
    <ListTable<Order>
      url="orders"
      head={[
        { label: "Name" },
        { label: "Status", action: () => history.push(`${history.location.pathname}?ordering=${status},-id`), direction },
        { label: "Date" },
        { label: "Total" },
        { label: "Actions", align: "right" },
      ]}
      showMax={props.showMax}
    >
      {(result) => {
        return (
          <TableRow key={result.id}>
            <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
            <TableCell className="pl-3 fw-normal">
              <span
                css={css`
                  background-color: ${getStatusColor(result)};
                  padding: 5px 10px;
                  border-radius: 11px;
                  color: white;
                `}
              >
                {getStatusLabel(result)}
              </span>
            </TableCell>
            <TableCell className="pl-3 fw-normal">
              {format(new Date(result.created), "MMM do, YYY")}
            </TableCell>
            <TableCell className="pl-3 fw-normal">
              <NumberFormat
                value={result.total}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </TableCell>
            <TableCell align="right">
              <Link to={`/orders/${result.id}`}>
                <Fab color="primary" aria-label="edit" size="small">
                  <EditIcon />
                </Fab>
              </Link>
            </TableCell>
          </TableRow>
        );
      }}
    </ListTable>
  );
}
