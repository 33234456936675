import { authorised } from "../../request";
import { Menu } from "../../types/Menu";

export async function deleteMenu(id: string) {
  const API = authorised();

  return await API.request<Menu>({
    method: "DELETE",
    url: `/api/admin/menus/${id}/`
  });
}
