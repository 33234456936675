import { authorised } from "../../request";
import { ProductTag } from "../../types/ProductTag";

export async function deleteProductTag(id: string) {
  const API = authorised();

  return await API.request<ProductTag>({
    method: "DELETE",
    url: `/api/admin/product-tags/${id}/`
  });
}
