import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import * as Formik from "formik";
import { css } from "styled-components/macro";

export const FieldError: React.FunctionComponent<{ name: string }> = props => {
  return (
    <Formik.ErrorMessage
      name={props.name}
      render={(errorMessage: any) => {
        if (typeof errorMessage !== "string") return null;

        return (
          <p
            css={css`
              color: red;
              font-size: 12px;
              padding-left: 10px;
            `}
          >
            {errorMessage}
          </p>
        );
      }}
    />
  );
};

export function DateField(props: {
  name: string;
  label: string;
  fullWidth?: boolean;
}) {
  return (
    <div>
      <Formik.FastField
        name={props.name}
        render={({ form, field }: Formik.FastFieldProps) => {
          const date = field.value ? new Date(field.value) : null;

          return (
            <div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                inputVariant="outlined"
                label={props.label}
                value={date}
                onChange={(date: Date | null) => {
                  form.setFieldValue(props.name, date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
              <FieldError name={props.name} />
            </div>
          );
        }}
      />
    </div>
  );
}
