import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import { ProductOption } from "../types/ProductOption";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { SelectField } from "./fields/SelectField";
import { ImageField } from "./fields/ImageField";
import { Repeater } from "../components/Repeater";
import { AutocompleteField } from "./fields/Autocomplete";
import { useList } from "../useAPI";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { Typography } from "@material-ui/core";

type FormValues = Pick<
  ProductOption,
  | "name"
  | "icon"
  | "type"
  | "config"
  | "sizes"
  | "square_sizes"
  | "worldwide_shipping"
  | "price_per_inch"
  | "discount_percentage"
  | "requires"
  | "price_components"
  | "admin_name"
>;
type Callback = () => void;

export function PricingField(props: {
  title: string;
  name: string;
  isFrameOrBorder: boolean;
  pricePerInch: boolean;
}) {
  return (
    <div
      css={css`
        margin-bottom: 40px;
      `}
    >
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Typography variant="h4">{props.title}</Typography>
      </div>
      <Repeater
        name={props.name}
        enableReordering
        addNewLabel={"New Size"}
        generateNewObject={() => {
          return {
            from_size: 0,
            to_size: 0,
            width: 0,
            height: 0,
            price: "0",
            discount: "0",
          };
        }}
      >
        {({ idx }) => {
          return (
            <Row>
              <div>
                <Formik.FastField
                  name={`${props.name}[${idx}].from_size`}
                  label="From Size"
                  type="number"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div>
                <Formik.FastField
                  name={`${props.name}[${idx}].to_size`}
                  label="To Size"
                  type="number"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
              </div>
              {props.isFrameOrBorder && (
                <React.Fragment>
                  <div>
                    <Formik.FastField
                      name={`${props.name}[${idx}].width`}
                      label="Width"
                      type="number"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <Formik.FastField
                      name={`${props.name}[${idx}].height`}
                      label="Height"
                      type="number"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />
                  </div>
                </React.Fragment>
              )}
              <div>
                <Formik.FastField
                  name={`${props.name}[${idx}].price`}
                  label={props.pricePerInch ? "Price per inch" : "Price"}
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </Row>
          );
        }}
      </Repeater>
    </div>
  );
}

export function ProductOptionForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [isDeleting] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);
  const { results } = useList<ProductOption>("product-options");
  const productOptions = results.map((result) => {
    return {
      value: result.id,
      label: result.admin_name || result.name,
    };
  });

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          admin_name: "",
          type: "FRAME",
          config: {},
          price_per_inch: false,
          worldwide_shipping: true,
          requires: [],
          price_components: [],
          discount_percentage: 0,
          icon: null,
          sizes: [
            {
              from_size: 0,
              to_size: 0,
              width: 0,
              height: 0,
              price: "0",
              discount: "0",
            },
          ],
          square_sizes: [
            {
              from_size: 0,
              to_size: 0,
              width: 0,
              height: 0,
              price: "0",
              discount: "0",
            },
          ],
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as any;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => {
        const isFrameOrBorder =
          values.type === "FRAME" || values.type === "BORDER";
        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Formik.FastField
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="admin_name"
                        label="Admin name"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <SelectField
                        label="Type"
                        name="type"
                        options={[
                          {
                            value: "FRAME",
                            label: "Frame",
                          },
                          {
                            value: "BORDER",
                            label: "Border",
                          },
                          {
                            value: "PLEXI",
                            label: "Plexi",
                          },
                        ]}
                      />
                      {values.type === "FRAME" && (
                        <Formik.FastField
                          name="config.color"
                          label="Color"
                          type="text"
                          fullWidth
                          component={TextField}
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                      <Formik.FastField
                        name={`discount_percentage`}
                        label="Discount (%)"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name={"price_per_inch"}
                        Label={{ label: "Price per inch" }}
                        component={CheckboxWithLabel}
                      />
                      {values.price_per_inch && (
                        <AutocompleteField
                          name="price_components"
                          filterSelectedOptions
                          options={[
                            {
                              label: "Print",
                              value: "PRINT",
                            },
                            {
                              label: "Frame",
                              value: "FRAME",
                            },
                            {
                              label: "Border",
                              value: "BORDER",
                            },
                          ]}
                          label="Price components"
                        />
                      )}
                      <AutocompleteField
                        name="requires"
                        options={productOptions}
                        label="Requires (one of)"
                        filterSelectedOptions
                      />
                      <Formik.FastField
                        name={"admin_only"}
                        Label={{ label: "Available for admin only" }}
                        component={CheckboxWithLabel}
                      />
                      <Formik.FastField
                        name={"worldwide_shipping"}
                        Label={{ label: "Worldwide shipping" }}
                        component={CheckboxWithLabel}
                      />
                      <PricingField
                        title="Default Pricing"
                        name={"sizes"}
                        pricePerInch={values.price_per_inch}
                        isFrameOrBorder={isFrameOrBorder}
                      />
                      <PricingField
                        title="Square Pricing"
                        name={"square_sizes"}
                        pricePerInch={values.price_per_inch}
                        isFrameOrBorder={isFrameOrBorder}
                      />
                    </Column>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Row justify="flex-end">
                        {props.onDelete && (
                          <div>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              disabled={isDeleting}
                              onClick={() => {
                                setConfirmAction(() => {
                                  return props.onDelete;
                                });
                              }}
                            >
                              {isDeleting ? (
                                <CircularProgress size={25} />
                              ) : (
                                "Delete"
                              )}
                            </Button>
                            {confirmAction !== null && (
                              <ConfirmModal
                                confirmAction={confirmAction}
                                handleClose={() => {
                                  setConfirmAction(null);
                                }}
                                open={true}
                                label="Please confirm deletion"
                              />
                            )}
                          </div>
                        )}
                        <div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={25} />
                            ) : props.type === "update" ? (
                              "Update"
                            ) : (
                              "Create"
                            )}
                          </Button>
                        </div>
                      </Row>
                      {error && <Error text={error} />}
                    </Column>
                  </Paper>
                  <Paper>
                    <ImageField name={"icon"} label="Icon" />
                  </Paper>
                </Column>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
