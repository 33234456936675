import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { ProductForm } from "../../forms/ProductForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateProduct } from "../../actions/product/updateProduct";
import { deleteProduct } from "../../actions/product/deleteProduct";
import { PaginatedList, useDetail, useFetch } from "../../useAPI";
import { Product } from "../../types/Product";
import { serializeDate, deserializeDate } from "../../helpers/utils";
import { User } from "../../types/User";

export function ProductEdit(
  props: RouteChildrenProps<{
    productId: string;
  }>
) {
  const productId = props.match!.params.productId;
  const { data, error } = useDetail<Product>("products", productId);
  const { data: interestedUsers } = useFetch<PaginatedList<User[]>>(
    `products/${productId}/price-increase/users`,
    {
      results: [],
      count: 0,
      next: null,
      previous: null,
    }
  );
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/products"}>Products</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <ProductForm
          type="update"
          productId={productId}
          interestedUsersCount={interestedUsers.count}
          initialValues={{
            ...data,
            subtitle: data.subtitle || "",
            production_date: serializeDate(data.production_date),
            sizes: data.sizes.map((s) => ({
              width: s.width.toString(),
              height: s.height.toString(),
              price: s.price,
            })),
            serie: data.serie,
          }}
          onSubmit={async (values) => {
            await updateProduct(productId, {
              ...values,
              production_date: deserializeDate(values.production_date),
              sizes: values.sizes.map((s) => {
                return {
                  width: parseFloat(s.width),
                  height: parseFloat(s.height),
                  price: s.price,
                };
              }),
            });
          }}
          onDelete={async () => {
            await deleteProduct(productId);

            props.history.push("/products");
          }}
        />
      )}
    </Layout>
  );
}
