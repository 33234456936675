import React, { Component } from "react";
import PropTypes from "prop-types";
import Option from "./Option";
import { stopPropagation } from "./utils";

class LayoutComponent extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    doExpand: PropTypes.func,
    doCollapse: PropTypes.func,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
    translations: PropTypes.object
  };

  state = {
    showModal: false,
    linkTarget: "",
    linkTitle: "",
    buttonLook: false,
    buttonStyle: "",
    linkTargetOption: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.expanded && !this.props.expanded) {
      this.setState({
        showModal: false,
        linkTarget: "",
        linkTitle: "",
        buttonLook: false,
        buttonStyle: "",
        linkTargetOption: false
      });
    }
  }

  removeLink = () => {
    const { onChange } = this.props;
    onChange("unlink");
  };

  addLink = () => {
    const { onChange } = this.props;
    const {
      linkTitle,
      linkTarget,
      linkTargetOption,
      buttonLook,
      buttonStyle
    } = this.state;
    onChange(
      "link",
      linkTitle,
      linkTarget,
      linkTargetOption,
      buttonLook,
      buttonStyle
    );
  };

  updateValue = event => {
    this.setState({
      [`${event.target.name}`]: event.target.value
    });
  };

  updateTargetOption = event => {
    this.setState({
      linkTargetOption: event.target.checked ? "_blank" : "_self"
    });
  };

  updateButtonLook = event => {
    this.setState({
      buttonLook: event.target.checked
    });
  };

  updateButtonStyle = event => {
    this.setState({
      buttonStyle: event.target.value
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false
    });
  };

  signalExpandShowModal = () => {
    const {
      onExpandEvent,
      currentState: { link, selectionText }
    } = this.props;
    const { linkTargetOption } = this.state;
    onExpandEvent();
    this.setState({
      showModal: true,
      linkTarget: (link && link.target) || "",
      buttonLook: (link && link.buttonLook) || false,
      buttonStyle: (link && link.buttonStyle) || "",
      linkTargetOption: (link && link.targetOption) || linkTargetOption,
      linkTitle: (link && link.title) || selectionText
    });
  };

  forceExpandAndShowModal = () => {
    const {
      doExpand,
      currentState: { link, selectionText }
    } = this.props;
    const { linkTargetOption } = this.state;
    doExpand();
    this.setState({
      showModal: true,
      linkTarget: link && link.target,
      buttonLook: link && link.buttonLook,
      buttonStyle: link && link.buttonStyle,
      linkTargetOption: (link && link.targetOption) || linkTargetOption,
      linkTitle: (link && link.title) || selectionText
    });
  };

  renderAddLinkModal() {
    const { doCollapse, translations } = this.props;
    const {
      linkTitle,
      linkTarget,
      linkTargetOption,
      buttonLook,
      buttonStyle
    } = this.state;

    const buttonStyles = [
      {
        value: "",
        label: "Default"
      },
      {
        value: "white",
        label: "White"
      },
      {
        value: "white-border",
        label: "White border"
      }
    ];

    return (
      <div
        className={"rdw-link-modal"}
        onClick={stopPropagation}
        style={{
          height: "auto"
        }}
      >
        <label className="rdw-link-modal-label" htmlFor="linkTitle">
          {translations["components.controls.link.linkTitle"]}
        </label>
        <input
          id="linkTitle"
          className="rdw-link-modal-input"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTitle"
          value={linkTitle}
        />
        <label className="rdw-link-modal-label" htmlFor="linkTarget">
          {translations["components.controls.link.linkTarget"]}
        </label>
        <input
          id="linkTarget"
          className="rdw-link-modal-input"
          onChange={this.updateValue}
          onBlur={this.updateValue}
          name="linkTarget"
          value={linkTarget}
        />
        <label
          className="rdw-link-modal-target-option"
          htmlFor="openLinkInNewWindow"
        >
          <input
            id="openLinkInNewWindow"
            type="checkbox"
            defaultChecked={linkTargetOption === "_blank"}
            value="_blank"
            onChange={this.updateTargetOption}
          />
          <span>
            {translations["components.controls.link.linkTargetOption"]}
          </span>
        </label>
        {/* Custom Addition */}
        <label className="rdw-link-modal-target-option" htmlFor="buttonLook">
          <input
            id="buttonLook"
            type="checkbox"
            checked={buttonLook}
            onChange={this.updateButtonLook}
          />
          <span>Button look</span>
        </label>
        {buttonLook && (
          <div className="rdw-link-modal-target-option" htmlFor="buttonStyle">
            {buttonStyles.map(style => {
              return (
                <label
                  className="rdw-link-modal-target-option"
                  style={{ marginRight: 10 }}
                  key={style.value}
                >
                  <input
                    type="radio"
                    value={style.value}
                    checked={style.value === buttonStyle}
                    onChange={this.updateButtonStyle}
                  />
                  <span>{style.label}</span>
                </label>
              );
            })}
          </div>
        )}

        {/* END: Custom Addition */}
        <span className="rdw-link-modal-buttonsection">
          <button
            className="rdw-link-modal-btn"
            onClick={this.addLink}
            disabled={!linkTarget || !linkTitle}
          >
            {translations["generic.add"]}
          </button>
          <button className="rdw-link-modal-btn" onClick={doCollapse}>
            {translations["generic.cancel"]}
          </button>
        </span>
      </div>
    );
  }

  render() {
    const { currentState, expanded, translations } = this.props;
    const { showModal } = this.state;

    return (
      <div className={"rdw-link-wrapper"} aria-label="rdw-link-control">
        <Option
          value="unordered-list-item"
          onClick={this.signalExpandShowModal}
          aria-haspopup="true"
          aria-expanded={showModal}
          title={translations["components.controls.link.link"]}
        >
          Link
        </Option>
        <Option
          disabled={!currentState.link}
          value="ordered-list-item"
          onClick={this.removeLink}
          title={translations["components.controls.link.unlink"]}
        >
          Unlink
        </Option>

        {expanded && showModal ? this.renderAddLinkModal() : undefined}
      </div>
    );
  }
}

export default LayoutComponent;
