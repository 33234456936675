import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import HomeIcon from "@material-ui/icons/Home";
import PagesIcon from "@material-ui/icons/Description";
import PostsIcon from "@material-ui/icons/ArtTrack";
import InHomesIcon from "@material-ui/icons/House";
import PressIcon from "@material-ui/icons/Chat";
import SeriesIcon from "@material-ui/icons/Theaters";
import ProductsIcon from "@material-ui/icons/Store";
import OrdersIcon from "@material-ui/icons/AttachMoney";
import SettingsIcon from "@material-ui/icons/Build";
import AwardsIcon from "@material-ui/icons/Stars";
import FilmsIcon from "@material-ui/icons/MovieFilter";
import MenuIcon from "@material-ui/icons/FormatListNumbered";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PresetsIcon from "@material-ui/icons/Repeat";

import { withRouter } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useLayout } from "../context/LayoutContext";
import { SidebarLink } from "./SidebarLink";
import classNames from "classnames";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  sidebarList: {
    marginTop: theme.spacing(6)
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625)
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
}));

type MenuItem = {
  label?: string;
  link?: string;
  type?: "title" | "divider";
  icon?: JSX.Element;
  children?: Array<MenuItem>;
};

const structure: Array<MenuItem> = [
  {
    label: "Dashboard",
    link: "/dashboard",
    icon: <HomeIcon />
  },
  {
    label: "Menu",
    link: "/menus",
    icon: <MenuIcon />
  },
  {
    label: "Pages",
    link: "/pages",
    icon: <PagesIcon />
  },
  {
    label: "Posts",
    link: "/posts",
    icon: <PostsIcon />
  },
  {
    type: "divider"
  },
  {
    label: "In Homes",
    link: "/in-homes",
    icon: <InHomesIcon />
  },
  {
    label: "Awards",
    link: "/awards",
    icon: <AwardsIcon />
  },
  {
    label: "Press",
    link: "/press",
    icon: <PressIcon />
  },
  {
    label: "Short Films",
    link: "/films",
    icon: <FilmsIcon />
  },
  {
    type: "divider"
  },
  {
    label: "Series",
    link: "/series",
    icon: <SeriesIcon />
  },

  {
    label: "Products",
    link: "/products",
    icon: <ProductsIcon />
  },
  {
    label: "Orders",
    link: "/orders",
    icon: <OrdersIcon />
  },
  {
    label: "Estimates",
    link: "/estimates",
    icon: <AssignmentTurnedInIcon />
  },
  {
    type: "divider"
  },
  {
    label: "Presets",
    link: "/presets",
    icon: <PresetsIcon />
  },
  {
    label: "Other",
    link: "/other",
    icon: <SettingsIcon />
  }
];

export function Sidebar() {
  const classes = useStyles();
  const { isDrawerOpened, toggleDrawer } = useLayout();
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    function handleWindowWidthChange() {
      const windowWidth = window.innerWidth;
      const breakpointWidth = 1000;
      const isSmallScreen = windowWidth < breakpointWidth;

      if (isSmallScreen && isPermanent) {
        setPermanent(false);
      } else if (!isSmallScreen && !isPermanent) {
        setPermanent(true);
      }
    }

    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      open={isDrawerOpened}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpened,
        [classes.drawerClose]: !isDrawerOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isDrawerOpened,
          [classes.drawerClose]: !isDrawerOpened
        })
      }}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleDrawer()}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <List>
        {structure.map((link, i) => (
          <SidebarLink key={`${link.label}-${i}`} {...link} />
        ))}
      </List>
    </Drawer>
  );
}

export default withRouter(Sidebar);
