import { authorised } from "../../request";
import { ProductTag } from "../../types/ProductTag";

export async function createProductTag(data: Omit<ProductTag, "id">) {
  const API = authorised();

  return await API.request<ProductTag>({
    method: "POST",
    url: "/api/admin/product-tags/",
    data
  });
}
