import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { Wishlist } from "../../types/Wishlist";
import { RouteChildrenProps } from "react-router";
import { format } from "date-fns";

export function WishlistList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Typography color="textPrimary">Wishlists</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Wishlist>
          url="wishlists"
          enableSearch
          head={[
            { label: "User" },
            { label: "Email" },
            { label: "Last Updated" },
            { label: "Number of items" },
            { label: "Actions", align: "right" }
          ]}
        >
          {result => {
            const lastUpdated = new Date(result.updated);
            return (
              <TableRow key={result.uuid}>
                <TableCell className="pl-3 fw-normal">
                  {result.user.first_name} {result.user.last_name}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.user.username}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {format(lastUpdated, "MMM do, YYY")} at{" "}
                  {format(lastUpdated, "hh:mm a")}
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.count}</TableCell>
                <TableCell align="right">
                  <Link to={`/wishlists/${result.uuid}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
