import { authorised } from "../../request";
import { Serie } from "../../types/Serie";

export async function cloneSerieBlock(id: string | number, data: any, isProduct: 0 | 1 = 0): Promise<{}> {
  const API = authorised();

  return await API.request<Serie>({
    method: "PUT",
    url: `/api/admin/series/${id}/add-block/?products=${isProduct}`,
    data
  });
}
