import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Paper } from "../../components/Paper";
import { User } from "../../types/User";
import { RouteChildrenProps } from "react-router";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { format } from "date-fns";

export function UserList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Typography color="textPrimary">Users</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<User>
          url="users"
          enableSearch
          head={[
            { label: "First Name" },
            { label: "Last Name" },
            { label: "Email" },
            { label: "Join Date" },
            { label: "Accepted Terms of Sale Date" },
            { label: "Accepted Terms of Service Date" },
            { label: "How Did You Find Drew" },
            { label: "Type" },
            { label: "Actions" },
          ]}
        >
          {(result) => {
            const joinedDate = new Date(result.date_joined);
            const acceptedTermsOfSale = result.profile && result.profile.terms_of_service ? new Date(result.profile.terms_of_service) : null;
            const tos = result.profile && result.profile.tos ? result.profile.tos : false;

            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  {result.first_name}
                </TableCell>
                <TableCell>{result.last_name} </TableCell>
                <TableCell>{result.username} </TableCell>
                <TableCell>
                  {format(joinedDate, "MMM do, YYY")} at{" "}
                  {format(joinedDate, "hh:mm a")}
                </TableCell>
                <TableCell>
                  {acceptedTermsOfSale && (
                    <div>
                      {format(acceptedTermsOfSale, "MMM do, YYY")} at{" "}
                      {format(acceptedTermsOfSale, "hh:mm a")}
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {tos && (
                    <div>
                      {format(joinedDate, "MMM do, YYY")} at{" "}
                      {format(joinedDate, "hh:mm a")}
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {result.profile && (
                    <div>
                      {result.profile.how_did_you_find}
                      {": "}
                      {result.profile.how_did_you_find_value}
                    </div>
                  )}
                </TableCell>
                <TableCell>{result.profile && result.profile.type}</TableCell>
                <TableCell align="right">
                  <Link to={`/users/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
