import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateUser } from "../../actions/user/updateUser";
import { useDetail } from "../../useAPI";
import { User } from "../../types/User";
import { UserForm } from "../../forms/UserForm";
import { deleteUser } from "../../actions/user/deleteUser";

export function UserEdit(
  props: RouteChildrenProps<{
    userId: string;
  }>
) {
  const userId = props.match!.params.userId;
  const { data, error } = useDetail<User>("users", userId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/users"}>Users</Link>
        <Typography color="textPrimary">
          {error
            ? error
            : data
            ? `${data.first_name} ${data.last_name}`
            : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <UserForm
          type="update"
          initialValues={data}
          onSubmit={async (values) => {
            await updateUser(userId, values);
          }}
          onDelete={async () => {
            await deleteUser(userId);

            props.history.push("/users");
          }}
        />
      )}
    </Layout>
  );
}
