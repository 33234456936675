import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import Themes from "./themes";
import * as serviceWorker from "./serviceWorker";
import { UserProvider } from "./context/UserContext";
import { CommonDataProvider } from "./context/CommonDataContext";
import { LayoutProvider } from "./context/LayoutContext";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as Sentry from "@sentry/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "react-sortable-tree/style.css";
import "react-resizable/css/styles.css";
import { Integrations } from "@sentry/tracing";
import "./global.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      "https://a72678fafa4045e3923caa65fad4f087@o520630.ingest.sentry.io/5634367",
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <CommonDataProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </CommonDataProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
