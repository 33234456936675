import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SerieProductInformationForm } from "../../forms/SerieProductInformationForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateSerie } from "../../actions/serie/updateSerie";
import { deleteSerie } from "../../actions/serie/deleteSerie";
import { useDetail } from "../../useAPI";
import { Serie } from "../../types/Serie";
import { Row } from "../../helpers/layout";
import { SerieFormNavigation } from "./SerieEditConfiguration";

export function SerieEditProductInformation(
  props: RouteChildrenProps<{
    serieId: string;
  }>
) {
  const serieId = props.match!.params.serieId;
  const { data, error } = useDetail<Serie>("series", serieId);
  return (
    <Layout>
      <Row justify="space-between" align="center">
        <Breadcrumbs>
          <Link to={"/series"}>Series</Link>
          <Typography color="textPrimary">
            {error ? error : data ? data.name : "Loading"}
          </Typography>
          <Typography color="textPrimary">Product Information</Typography>
        </Breadcrumbs>
        <SerieFormNavigation serieId={serieId} active="product-information" />
      </Row>
      {data && (
        <SerieProductInformationForm
          type="update"
          initialValues={{
            visibility: data.visibility,
            product_blocks: data.product_blocks
          }}
          onSubmit={async values => {
            await updateSerie(serieId, values);
          }}
          onDelete={async () => {
            await deleteSerie(serieId);

            props.history.push("/series");
          }}
        />
      )}
    </Layout>
  );
}
