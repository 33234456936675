import { authorised } from "../../request";
import { Announcement } from "../../types/Announcement";

export async function deleteAnnouncement(id: string) {
  const API = authorised();

  return await API.request<Announcement>({
    method: "DELETE",
    url: `/api/admin/announcements/${id}/`
  });
}
