import { authorised } from "../../request";
import { Award } from "../../types/Award";

export async function createAward(data: Omit<Award, "id">) {
  const API = authorised();

  return await API.request<Award>({
    method: "POST",
    url: "/api/admin/awards/",
    data
  });
}
