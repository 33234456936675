import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { AnnouncementForm } from "../../forms/AnnouncementForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateAnnouncement } from "../../actions/announcement/updateAnnouncement";
import { deleteAnnouncement } from "../../actions/announcement/deleteAnnouncement";
import { useDetail } from "../../useAPI";
import { Announcement } from "../../types/Announcement";
import { serializeDate, deserializeDate } from "../../helpers/utils";

export function AnnouncementEdit(
  props: RouteChildrenProps<{
    announcementId: string;
  }>
) {
  const announcementId = props.match!.params.announcementId;
  const { data, error } = useDetail<Announcement>(
    "announcements",
    announcementId
  );

  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/announcements"}>Announcements</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <AnnouncementForm
          type="update"
          initialValues={{
            start_date: serializeDate(data.start_date),
            end_date: serializeDate(data.end_date),
            desktop_text: data.desktop_text,
            mobile_text: data.mobile_text,
            name: data.name,
            location: data.location,
          }}
          onSubmit={async (values) => {
            const valuesData = {
              start_date: deserializeDate(values.start_date),
              end_date: deserializeDate(values.end_date),
              desktop_text: values.desktop_text,
              mobile_text: values.mobile_text,
              name: values.name,
              location: values.location,
            };
            await updateAnnouncement(announcementId, valuesData);
          }}
          onDelete={async () => {
            await deleteAnnouncement(announcementId);

            props.history.push("/announcements");
          }}
        />
      )}
    </Layout>
  );
}
