import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { Setting } from "../types/Setting";
import { Error } from "../components/Error";
import { BlocksField } from "./fields/BlocksField";

type FormValues = Pick<Setting, "value" | "blocks" | "checkbox">;

export function SettingForm(props: {
  type: "update";
  setting: Setting;
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { setting } = props;
  const [error, setError] = useState<string | null>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          value: "",
          blocks: [],
          checkbox: false,
        }
      }
      validationSchema={Yup.object().shape({
        value: Yup.string(),
        blocks: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(),
          })
        ),
        checkbox: Yup.boolean(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as any;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();
          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  {setting.type === "DEFAULT" && (
                    <Paper>
                      <Formik.FastField
                        name="value"
                        placeholder="Value"
                        type="text"
                        fullWidth
                        component={TextField}
                        variant="outlined"
                      />
                    </Paper>
                  )}
                  {setting.type === "CHECKBOX" && (
                    <Paper>
                      <Formik.FastField
                        name={"checkbox"}
                        Label={{ label: setting.name }}
                        component={CheckboxWithLabel}
                      />
                    </Paper>
                  )}
                  {setting.type === "BLOCKS" && <BlocksField name="blocks" />}
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Row justify="flex-end">
                        <div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={25} />
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </div>
                      </Row>
                      {error && <Error text={error} />}
                    </Column>
                  </Paper>
                </Column>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
