import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Product } from "../types/Product";
import { Preset } from "../types/Preset";
import { ProductTag } from "../types/ProductTag";
import { Serie } from "../types/Serie";
import { SelectField } from "./fields/SelectField";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { SlugField } from "./fields/SlugField";
import { ImageField } from "./fields/ImageField";
import { PickField } from "./fields/PickField";
import { PhotoSizesField } from "./fields/PhotoSizesField";
import { ImportField } from "./fields/ImportField";
import { SlugAutoCompleteField } from "./fields/SlugAutoCompleteField";
import FindInPage from "@material-ui/icons/FindInPage";
import { createProductTag } from "../actions/product-tag/createProductTag";
import { EditorField } from "./fields/EditorField";
import { getFrontendUrl } from "../helpers/router";
import { formatISO } from "date-fns";
import { CheckboxWithLabel } from "formik-material-ui";
import { DateField } from "./fields/DateField";
import { FormRow, FormLeftside, FormRightside } from "./Layout";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { PriceIncreaseModal } from "../modals/PriceIncreaseModal";
import { BlockCarouselField } from "./fields/BlockCarouselField";
import { css } from "styled-components";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";

type FormValues = Pick<
  Product,
  | "name"
  | "slug"
  | "subtitle"
  | "description"
  | "type"
  | "pricing"
  | "pairing_type"
  | "pairing_products"
  | "featured_image"
  | "related_in_homes"
  | "price"
  | "tags"
  | "serie_description"
  | "content"
  | "sold_out"
  | "alt"
  | "production_date"
  | "material"
  | "book_author"
  | "book_edition"
  | "book_format"
  | "note"
  | "product_caption"
  | "visibility"
  | "book_carousel"
  | "serial_number"
  | "book_options"
> & {
  sizes: Array<{
    width: string;
    height: string;
    price: string;
  }>;
  serie: Pick<Serie, "name" | "id"> | null;
};
type Callback = () => void;

export function ProductForm(props: {
  type: "create" | "update";
  productId?: string;
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
  interestedUsersCount?: number;
}) {
  const [error, setError] = useState<string | null>(null);
  const [bookOptionsError, setBookOptionsError] = useState(false);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);
  const [priceIncrease, setPriceIncrease] = useState(false);
  const emptyOption = {
    name: "",
    price: "0.00",
    width: 0,
    height: 0,
    length: 0,
    weight: 0,
    free_domestic_shipping: false,
    flat_rate_domestic_shipping: 0,
  };

  return (
    <>
      <Formik.Formik<FormValues>
        initialValues={
          props.initialValues || {
            name: "",
            slug: "",
            subtitle: "",
            description: "",
            type: "BOOK",
            pricing: "DEFAULT",
            pairing_type: null,
            pairing_products: [],
            featured_image: null,
            sizes: [],
            price: null,
            related_in_homes: [],
            serie: null,
            tags: [],
            serie_description: null,
            product_caption: null,
            content: null,
            sold_out: false,
            alt: "",
            production_date: formatISO(new Date()),
            material: "",
            book_author: "",
            book_edition: "",
            book_format: "",
            note: "",
            visibility: "PUBLIC",
            book_carousel: {
              style: "slider",
            },
            serial_number: "",
            book_options: [],
          }
        }
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          slug: Yup.string().required(),
          type: Yup.string().required(),
          description: Yup.string(),
          keywords: Yup.string(),
          sizes: Yup.array().of(
            Yup.object().shape({
              width: Yup.number().required(),
              height: Yup.number().required(),
              price: Yup.number().required(),
            })
          ),
          serie: Yup.object().nullable(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          if (values.type === "BOOK" && !(values.book_options || []).length) {
            setBookOptionsError(true);
            setSubmitting(false);
            return;
          }
          try {
            setSubmitting(true);
            if (values.type === "PHOTO" || values.type === "BOOK") {
              values.pairing_type = null;
            }
            await props.onSubmit(values);
          } catch (err) {
            const error = err as any;
            const message =
              error.response && error.response.data
                ? Object.values(error.response.data).join(", ")
                : error.toString();

            setError(message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => {
          const bookOptions = values.book_options || [];
          return (
            <Formik.Form>
              <FormRow>
                <FormLeftside>
                  <Column>
                    <Paper>
                      <Formik.FastField
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        component={SlugAutoCompleteField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="slug"
                        label="Slug"
                        type="text"
                        fullWidth
                        component={SlugField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="subtitle"
                        label="Subtitle"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="serial_number"
                        label="Serial Number (GTIN13 / ISBN / GTIN8)"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="description"
                        label="Description (SEO)"
                        type="text"
                        multiline
                        fullWidth
                        rows="4"
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="alt"
                        label="Image Alt"
                        type="text"
                        multiline
                        fullWidth
                        rows="4"
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <EditorField
                        name="product_caption"
                        label="Product Caption"
                      />
                      <Formik.FastField
                        name="note"
                        label="Product note"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <PickField<ProductTag>
                        name="tags"
                        multiple
                        canCreate
                        enableSearch
                        additionalFields={[
                          {
                            key: "category",
                            name: "Category",
                          },
                        ]}
                        formFields={[
                          {
                            name: "name",
                            label: "Name",
                            type: "text",
                            required: true,
                          },
                          {
                            name: "category",
                            label: "Category",
                            type: "select",
                            defaultValue: "",
                            options: [
                              {
                                value: "",
                                label: "None",
                              },
                              {
                                value: "room",
                                label: "Room",
                              },
                              {
                                value: "frame",
                                label: "Frame",
                              },
                              {
                                value: "pairing",
                                label: "Pairing",
                              },
                              {
                                value: "series",
                                label: "Series",
                              },
                              {
                                value: "color",
                                label: "Color",
                              },
                            ],
                            required: false,
                          },
                          {
                            name: "image",
                            label: "Image",
                            type: "image",
                            required: false,
                          },
                        ]}
                        createNotification="Product tag created and applied."
                        onCreate={async (v) => {
                          const response = await createProductTag(v);
                          const currentOptions = values.tags || [];
                          setFieldValue("tags", [
                            ...currentOptions,
                            response.data,
                          ]);
                        }}
                        label="Tags"
                        url="product-tags"
                      />
                    </Paper>
                    <Paper>
                      <EditorField
                        name={`serie_description`}
                        label="Product description inside series (big product layout)"
                      />
                    </Paper>
                    <Paper>
                      <Column>
                        <SelectField
                          label="Type"
                          name="type"
                          options={[
                            {
                              value: "BOOK",
                              label: "Book",
                            },
                            {
                              value: "PHOTO",
                              label: "Photo",
                            },
                            {
                              value: "PAIRING",
                              label: "Pairing",
                            },
                          ]}
                        />
                        {(values.type === "PHOTO" ||
                          values.type === "PAIRING") && (
                          <SelectField
                            label="Pricing"
                            name="pricing"
                            options={[
                              {
                                value: "DEFAULT",
                                label: "Default",
                              },
                              {
                                value: "SQUARE",
                                label: "Square",
                              },
                            ]}
                          />
                        )}
                        <div>
                          <Formik.FastField
                            name="sold_out"
                            Label={{ label: "Sold out?" }}
                            component={CheckboxWithLabel}
                          />
                        </div>
                        {values.type === "PAIRING" && (
                          <SelectField
                            label="Paiting Type"
                            name="pairing_type"
                            options={[
                              {
                                value: "BUNDLE",
                                label: "Product bundle",
                              },
                              {
                                value: "DIPTYCH",
                                label: "Diptych",
                              },
                              {
                                value: "TRIPTYCH",
                                label: "Triptych",
                              },
                              {
                                value: "QUAD",
                                label: "Quad",
                              },
                            ]}
                          />
                        )}
                        {values.type === "PAIRING" && (
                          <PickField
                            name="pairing_products"
                            label={
                              values.pairing_type === "BUNDLE"
                                ? "Products in bundle"
                                : "Related products"
                            }
                            url="products"
                            multiple
                            enableSearch
                          />
                        )}
                      </Column>
                    </Paper>
                    {(values.type === "PAIRING" || values.type === "PHOTO") && (
                      <Paper>
                        <Column>
                          <Formik.FastField
                            name="material"
                            label="Material"
                            type="text"
                            fullWidth
                            component={TextField}
                            margin="normal"
                            variant="outlined"
                          />
                          <DateField
                            name="production_date"
                            label="Production date"
                          />
                        </Column>
                      </Paper>
                    )}
                    {values.type === "BOOK" && (
                      <>
                        <Paper>
                          <Column>
                            <div>
                              <EditorField name="content" label="Content" />
                            </div>
                            <div>
                              <Formik.FastField
                                name="price"
                                label="Price"
                                type="number"
                                fullWidth
                                component={TextField}
                                variant="outlined"
                              />
                            </div>
                            <div>
                              <Formik.FastField
                                name="book_author"
                                label="Book Author"
                                type="text"
                                fullWidth
                                component={TextField}
                                variant="outlined"
                              />
                            </div>
                            <div>
                              <Formik.FastField
                                name="book_edition"
                                label="Book Edition"
                                type="text"
                                fullWidth
                                component={TextField}
                                variant="outlined"
                              />
                            </div>
                            <div>
                              <Formik.FastField
                                name="book_format"
                                label="Book Format"
                                type="text"
                                fullWidth
                                component={TextField}
                                variant="outlined"
                              />
                            </div>
                          </Column>
                        </Paper>
                        <Paper>
                          <Formik.FieldArray
                            name="book_options"
                            render={(arrHelper) => {
                              return (
                                <Column>
                                  <Typography variant="body2">
                                    Product Option
                                  </Typography>
                                  {bookOptions.map((option, index) => (
                                    <div
                                      key={index}
                                      css={css`
                                        position: relative;
                                      `}
                                    >
                                      <Column>
                                        <Formik.FastField
                                          margin="normal"
                                          name={`book_options.${index}.name`}
                                          label="Shipping Name"
                                          type="text"
                                          component={TextField}
                                          variant="outlined"
                                        />
                                        <Formik.FastField
                                          margin="normal"
                                          name={`book_options.${index}.price`}
                                          label="Shipping Price"
                                          type="number"
                                          component={TextField}
                                          variant="outlined"
                                        />
                                        <Formik.FastField
                                          margin="normal"
                                          name={`book_options.${index}.width`}
                                          label="Shipping Width (IN)"
                                          type="number"
                                          component={TextField}
                                          variant="outlined"
                                        />
                                        <Formik.FastField
                                          margin="normal"
                                          name={`book_options.${index}.height`}
                                          label="Shipping Height (IN)"
                                          type="number"
                                          component={TextField}
                                          variant="outlined"
                                        />
                                        <Formik.FastField
                                          margin="normal"
                                          name={`book_options.${index}.length`}
                                          label="Shipping Length (IN)"
                                          type="number"
                                          component={TextField}
                                          variant="outlined"
                                        />
                                        <Formik.FastField
                                          margin="normal"
                                          name={`book_options.${index}.weight`}
                                          label="Shipping Weight (LB)"
                                          type="number"
                                          component={TextField}
                                          variant="outlined"
                                        />
                                        <hr />
                                        <Row align="center">
                                          <Column>
                                            <Formik.FastField
                                              name={`book_options.${index}.free_domestic_shipping`}
                                              Label={{
                                                label:
                                                  "Free domestic shipping?",
                                              }}
                                              component={CheckboxWithLabel}
                                            />
                                          </Column>
                                          <Column>
                                            {!option.free_domestic_shipping ? (
                                              <Formik.FastField
                                                name={`book_options.${index}.flat_rate_domestic_shipping`}
                                                label="Flat Rate Domestic Shipping (USD)"
                                                type="number"
                                                component={TextField}
                                                variant="outlined"
                                              />
                                            ) : null}
                                          </Column>
                                        </Row>
                                        <hr />
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          onClick={() =>
                                            arrHelper.remove(index)
                                          }
                                        >
                                          Delete Option
                                        </Button>
                                        <hr />
                                      </Column>
                                    </div>
                                  ))}

                                  <Row>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        arrHelper.insert(
                                          bookOptions.length + 1,
                                          emptyOption
                                        )
                                      }
                                    >
                                      Add Option
                                    </Button>
                                  </Row>
                                </Column>
                              );
                            }}
                          />
                        </Paper>
                      </>
                    )}
                    {(values.type === "PHOTO" || values.type === "PAIRING") && (
                      <Paper>
                        <Column>
                          <Row justify="space-between">
                            <Typography variant="body2" color="textSecondary">
                              Pricing
                            </Typography>
                            <ImportField<Preset>
                              url="presets"
                              label="Import from preset"
                              onSelect={(preset) => {
                                setFieldValue("sizes", preset.sizes);
                              }}
                            />
                          </Row>
                          <PhotoSizesField name="sizes" value={values.sizes} />
                        </Column>
                      </Paper>
                    )}
                    {values.type === "BOOK" && (
                      <BlockCarouselField name="book_carousel" />
                    )}
                  </Column>
                </FormLeftside>
                <FormRightside>
                  <Column>
                    <Paper>
                      <Column>
                        <SelectField
                          label="Visibility"
                          name="visibility"
                          options={[
                            {
                              value: "DRAFT",
                              label: "Draft",
                            },
                            {
                              value: "PUBLIC",
                              label: "Public",
                            },
                            {
                              value: "ARCHIVED",
                              label: "Archived",
                            },
                            {
                              value: "RETIRED",
                              label: "Retired",
                            },
                          ]}
                        />
                        <Row justify="flex-end">
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              window.open(
                                getFrontendUrl(`shop/${values.slug}/`),
                                "_blank"
                              );
                            }}
                          >
                            <FindInPage />
                          </Button>
                          {props.onDelete && (
                            <div>
                              <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setConfirmAction(() => {
                                    return props.onDelete;
                                  });
                                }}
                              >
                                Delete
                              </Button>
                              {confirmAction !== null && (
                                <ConfirmModal
                                  confirmAction={confirmAction}
                                  handleClose={() => {
                                    setConfirmAction(null);
                                  }}
                                  open={true}
                                  label="Please confirm deletion"
                                />
                              )}
                            </div>
                          )}
                          <div>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <CircularProgress size={25} />
                              ) : props.type === "update" ? (
                                "Update"
                              ) : (
                                "Create"
                              )}
                            </Button>
                          </div>
                        </Row>
                        {error && <Error text={error} />}
                      </Column>
                    </Paper>
                    {props.productId && (
                      <Paper>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setPriceIncrease(true);
                          }}
                        >
                          Notify about price increase (
                          {props.interestedUsersCount || 0})
                        </Button>
                        <PriceIncreaseModal
                          product={{
                            id: props.productId,
                            name: values.name,
                          }}
                          handleClose={() => {
                            setPriceIncrease(false);
                          }}
                          open={priceIncrease}
                        />
                      </Paper>
                    )}
                    <Paper>
                      <PickField
                        name="serie"
                        label="Serie"
                        url="series"
                        enableSearch
                      />
                    </Paper>
                    <Paper>
                      <PickField
                        name="related_in_homes"
                        label="Related In Homes"
                        url="in-homes"
                        multiple
                        enableSearch
                      />
                    </Paper>
                    <Paper>
                      <ImageField
                        name={"featured_image"}
                        label="Featured Image"
                      />
                    </Paper>
                  </Column>
                </FormRightside>
              </FormRow>
            </Formik.Form>
          );
        }}
      </Formik.Formik>
      <Snackbar
        open={bookOptionsError}
        autoHideDuration={3000}
        onClose={() => setBookOptionsError(false)}
        message="Please add at least one book option."
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setBookOptionsError(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
