import { authorised } from "../../request";
import { PostTag } from "../../types/PostTag";

export async function updatePostTag(id: string, data: Omit<PostTag, "id">) {
  const API = authorised();

  return await API.request<PostTag>({
    method: "PATCH",
    url: `/api/admin/post-tags/${id}/`,
    data
  });
}
