import axios from "axios";

const ACCESS_TOKEN_KEY = "DD_access_token";
const REFRESH_TOKEN_KEY = "DD_refresh_token";

const config = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
};
const notAuthorised = axios.create(config);

export async function refreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  if (!refreshToken) {
    throw new Error("Refresh token doesn't exist");
  }

  const response = await notAuthorised.request({
    method: "POST",
    url: "/api/refresh/",
    data: {
      refresh: refreshToken
    }
  });

  localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access);
}

export async function login(values: { email: string; password: string }) {
  const response = await notAuthorised.request<{
    access: string;
    refresh: string;
  }>({
    method: "POST",
    url: "/api/login/",
    data: {
      username: values.email,
      password: values.password
    }
  });

  localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access);
  localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh);
}

export function logout() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function authorised() {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (!token) {
    throw new Error("Not Authorized");
  }

  return axios.create({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`
    }
  });
}
