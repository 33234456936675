import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Fab from "@material-ui/core/Fab";
import { ListTable } from "./ListTable";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { Estimate } from "../types/Estimate";

export function EstimateListContent(props: { showMax?: number }) {
  return (
    <ListTable<Estimate>
      url="estimates"
      head={[
        { label: "Id" },
        { label: "Name" },
        { label: "Status" },
        { label: "Total" },
        { label: "Actions", align: "right" }
      ]}
      showMax={props.showMax}
    >
      {result => {
        return (
          <TableRow key={result.id}>
            <TableCell className="pl-3 fw-normal">{result.id}</TableCell>
            <TableCell>{result.name}</TableCell>
            <TableCell>{result.status}</TableCell>
            <TableCell>
              <NumberFormat
                value={result.total}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </TableCell>
            <TableCell align="right">
              <Link to={`/estimates/${result.id}`}>
                <Fab color="primary" aria-label="edit" size="small">
                  <EditIcon />
                </Fab>
              </Link>
            </TableCell>
          </TableRow>
        );
      }}
    </ListTable>
  );
}
