import { authorised } from "../../request";

export async function notifyAboutPriceIncrease(
  id: string,
  numberOfItems: string,
  unselected: string[]
) {
  const API = authorised();

  return await API.request({
    method: "POST",
    url: `/api/admin/products/${id}/price-increase/`,
    data: {
      number_of_items: numberOfItems,
      unselected,
    },
  });
}
