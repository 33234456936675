import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { InHomeForm } from "../../forms/InHomeForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createInHome } from "../../actions/in-home/createInHome";
import { RouteChildrenProps } from "react-router";

export function InHomeCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/in-homes"}>In Homes</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <InHomeForm
        type="create"
        onSubmit={async values => {
          const response = await createInHome(values);

          props.history.push(`/in-homes/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
