import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PostForm } from "../../forms/PostForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createPost } from "../../actions/post/createPost";
import { RouteChildrenProps } from "react-router";

export function PostCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/posts"}>Posts</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <PostForm
        type="create"
        onSubmit={async values => {
          const response = await createPost(values);

          props.history.push(`/posts/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
