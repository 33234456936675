import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Visibility } from "../../components/Visibility";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { Post } from "../../types/Post";
import { RouteChildrenProps } from "react-router";
import { Row } from "../../helpers/layout";
import { getFrontendUrl } from "../../helpers/router";

export function PostList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/posts/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Posts</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Post>
          url="posts"
          head={[
            { label: "Name" },
            { label: "Slug" },
            { label: "Description" },
            { label: "Author" },
            { label: "Visibility", width: "130px" },
            { label: "Actions", align: "right" }
          ]}
        >
          {result => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell className="pl-3 fw-normal">{result.slug}</TableCell>
                <TableCell>{result.description}</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>
                  <Visibility type={result.visibility} />
                </TableCell>
                <TableCell align="right">
                  <Row align="center">
                    <a
                      href={getFrontendUrl(`blog/${result.slug}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Fab color="secondary" aria-label="edit" size="small">
                        <OpenInNewIcon />
                      </Fab>
                    </a>
                    <Link to={`/posts/${result.id}`}>
                      <Fab color="primary" aria-label="edit" size="small">
                        <EditIcon />
                      </Fab>
                    </Link>
                  </Row>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
