import React, { useState } from "react";
import * as Formik from "formik";
import { MediaUploader } from "../../modals/MediaUploader";
import { css } from "styled-components/macro";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import DeleteIcon from "@material-ui/icons/Delete";
import { Media } from "../../types/Media";
import { Product } from "../../types/Product";
import { InHomeConnection } from "../../types/InHome";
import { ModelSelector } from "../../modals/ModelSelector";
import Fab from "@material-ui/core/Fab";
import { ArrowTooltip } from "../../components/ArrowTooltip";
import { Image } from "../../components/Image";
import { ConfirmModal } from "../../modals/ConfirmModal";

type Callback = () => void;

export function InHomeBuilder(props: {
  image: Media;
  products: InHomeConnection[];
  onChange: (products: InHomeConnection[]) => void;
}) {
  const [showSelector, setShowSelector] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <React.Fragment>
      <div
        css={css`
          position: relative;
        `}
      >
        <Image
          style={{
            width: "100%",
            height: "auto",
          }}
          file={props.image}
        />
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            cursor: crosshair;
          `}
          onMouseMove={(e) => {
            const { offsetX, offsetY } = e.nativeEvent;
            const target = e.nativeEvent.target as HTMLElement;

            if (!target) return;

            const x = (offsetX / target.offsetWidth) * 100;
            const y = (offsetY / target.offsetHeight) * 100;

            const position = {
              x: Math.floor(x),
              y: Math.floor(y),
            };

            setPosition(position);
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowSelector(true);
          }}
        ></div>
        {props.products.map((item) => {
          return (
            <div
              css={css`
                position: absolute;
                top: ${item.position_y}%;
                left: ${item.position_x}%;
              `}
              key={`item-${item.product.id}`}
            >
              <ArrowTooltip title={item.product.name}>
                <Fab size="small" color="secondary">
                  <LocalMallIcon />
                </Fab>
              </ArrowTooltip>

              <div
                css={css`
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  top: -10px;
                  right: -10px;
                  background: red;
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                  border-radius: 50%;
                  color: #fff;
                `}
              >
                <DeleteIcon
                  fontSize="inherit"
                  onClick={() => {
                    setConfirmAction(() => {
                      return () => {
                        const newProducts = props.products.filter(
                          (i) => i.product.id !== item.product.id
                        );
                        props.onChange(newProducts);
                      };
                    });
                  }}
                />
                {confirmAction !== null && (
                  <ConfirmModal
                    confirmAction={confirmAction}
                    handleClose={() => {
                      setConfirmAction(null);
                    }}
                    open={true}
                    label="Please confirm deletion"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ModelSelector<Product>
        open={showSelector}
        handleClose={() => {
          setShowSelector(false);
        }}
        enableSearch
        label={"Select Product"}
        multiple={false}
        url={"products"}
        onSelect={(items: Product[]) => {
          const newProducts = [
            ...props.products,
            {
              product: items[0],
              position_x: position.x,
              position_y: position.y,
            },
          ];
          setShowSelector(false);

          props.onChange(newProducts);
        }}
      />
    </React.Fragment>
  );
}

export function InHomesProductsField() {
  const [showUploader, setShowUploader] = useState(false);

  return (
    <Formik.Field
      render={({ form }: Formik.FieldProps) => {
        return (
          <React.Fragment>
            {!form.values.image && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 200px;
                  border-radius: 10px;
                  background: #f5f5f5;
                `}
                onClick={() => {
                  setShowUploader(true);
                }}
              >
                <div
                  css={css`
                    width: 200px;
                    text-align: center;
                  `}
                >
                  <ImageSearchIcon />
                  <p>Select Image</p>
                </div>
              </div>
            )}
            {form.values.image && (
              <InHomeBuilder
                image={form.values.image}
                products={form.values.products}
                onChange={(products) => {
                  form.setFieldValue("products", products);
                }}
              />
            )}
            {showUploader && (
              <MediaUploader
                open={true}
                handleClose={() => {
                  setShowUploader(false);
                }}
                onSelect={(file) => {
                  form.setFieldValue("image", file);
                }}
              />
            )}
          </React.Fragment>
        );
      }}
    />
  );
}
