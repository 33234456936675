import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { FilePond } from "react-filepond";
import { storeUpload } from "../actions/storeUpload";
import { Media } from "../types/Media";
import { ListTable } from "../components/ListTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Image } from "../components/Image";

export function MediaUploader(props: {
  open: boolean;
  handleClose: () => void;
  onSelect: (file: Media) => void;
}) {
  const [file, setFile] = useState<Media | null>(null);
  const uploadUrl = `${process.env.REACT_APP_BACKEND_URL}/api/admin/fp/`;

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <FilePond
          allowMultiple={false}
          server={{
            process: `${uploadUrl}process/`,
            revert: `${uploadUrl}revert/`,
            restore: `${uploadUrl}restore/`,
            load: `${uploadUrl}load/`,
            fetch: `${uploadUrl}fetch/`
          }}
          onprocessfile={async (_error: any, file: any) => {
            const uploaded = await storeUpload(file.serverId);

            setFile(uploaded.data);
          }}
        />
        <ListTable<Media>
          url="media"
          enableSearch
          head={[
            {
              label: "Image",
              width: "150px"
            },

            {
              label: "Name",
              width: "300px"
            },
            {
              label: "Actions",
              align: "right"
            }
          ]}
        >
          {result => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  <Image
                    file={result}
                    style={{
                      width: "100%"
                    }}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.upload_name}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      props.onSelect(result);
                      props.handleClose();
                    }}
                  >
                    Select
                  </Button>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (!file) return;

            props.onSelect(file);
            props.handleClose();
          }}
          color="primary"
          disabled={!file}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
