import React from "react";
import * as Formik from "formik";
import { Column } from "../../helpers/layout";
import { PickField } from "./PickField";

export function BlockSeriesField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <PickField
              name={`${field.name}.series`}
              label="Series"
              url="series"
              multiple
              enableSearch
              relatedModel="Serie"
              sortable
            />
          </Column>
        );
      }}
    />
  );
}
