import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { FilmForm } from "../../forms/FilmForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateFilm } from "../../actions/film/updateFilm";
import { deleteFilm } from "../../actions/film/deleteFilm";
import { useDetail } from "../../useAPI";
import { Film } from "../../types/Film";

export function FilmEdit(
  props: RouteChildrenProps<{
    filmId: string;
  }>
) {
  const filmId = props.match!.params.filmId;
  const { data, error } = useDetail<Film>("films", filmId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/films"}>Films</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <FilmForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateFilm(filmId, values);
          }}
          onDelete={async () => {
            await deleteFilm(filmId);

            props.history.push("/films");
          }}
        />
      )}
    </Layout>
  );
}
