import { authorised } from "../../request";
import { Estimate } from "../../types/Estimate";

export async function updateEstimate(
  id: string,
  data: Omit<Estimate, "id" | "urlhash" | "shipping_total">
) {
  const API = authorised();

  return await API.request<Estimate>({
    method: "PATCH",
    url: `/api/admin/estimates/${id}/`,
    data,
  });
}
