import React from "react";
import { Product, ProductSize } from "../types/Product";
import { ProductOption, OptionComponent } from "../types/ProductOption";
import { Row, Column, Spacing } from "../helpers/layout";
import {
  calculateOptionPrice,
  getOptionSize,
  isOptionAvailable,
  OptionTypeOrder,
  calculateOptionDiscount,
  getPiecesNumber,
} from "../helpers/product";
import sortBy from "lodash/sortBy";
import { PrintOptionPreview } from "./PrintOptionPreview";
import { useCommonData } from "../context/CommonDataContext";

type OptionGroup = {
  name: string;
  type: OptionComponent;
  options: Array<{
    option: ProductOption;
    price: number;
    discount: number;
    isAvailable: boolean;
    isSelected: boolean;
  }>;
};

export function PrintOptions(props: {
  product: Product;
  size: ProductSize;
  selected: ProductOption[];
  onSelect: (options: ProductOption[]) => void;
}) {
  const { product } = props;
  const { settings } = useCommonData();
  const numberOfItems = getPiecesNumber(product);

  const productOptions = product.options
    .filter((option) => {
      return getOptionSize(product.pricing, props.size, option);
    })
    .map((option) => {
      const price = calculateOptionPrice(
        product.pricing,
        props.size,
        option,
        props.selected
      );
      const discount = calculateOptionDiscount(
        product.pricing,
        props.size,
        option,
        props.selected,
        settings
      );

      return {
        option,
        price: numberOfItems * price,
        discount: numberOfItems * discount,
        isSelected: !!props.selected.find((o) => o.id === option.id),
        isAvailable: isOptionAvailable(option, props.selected),
      };
    });

  if (!productOptions.length) {
    return null;
  }

  const groups = productOptions.reduce((groups: OptionGroup[], item) => {
    const groupExists = groups.find((group) => group.type === item.option.type);

    if (groupExists) {
      return groups.map((group) => {
        if (group.type === item.option.type) {
          return {
            ...group,
            options: [...group.options, item],
          };
        }

        return group;
      });
    }

    return [
      ...groups,
      {
        name: item.option.type,
        type: item.option.type,
        options: [item],
      },
    ];
  }, []);

  const orderedGroups = sortBy(groups, (group) => {
    return OptionTypeOrder[group.type] || OptionTypeOrder.Default;
  });

  return (
    <React.Fragment>
      {orderedGroups.map((group, _index) => {
        const options = group.options.sort((a, b) => {
          if (a.price < b.price) {
            return -1;
          }

          if (a.price > b.price) {
            return 1;
          }

          return 0;
        });

        const hasAvailableOptions =
          options.filter((option) => option.isAvailable).length > 0;

        if (!hasAvailableOptions) return null;

        return (
          <React.Fragment key={`option-group--${group.name}`}>
            <Column>
              <Row wrap="wrap" justify="space-between">
                <h4>Choose a {group.name.toLowerCase()}</h4>
              </Row>
              <div>
                <Row gutter={Spacing.l}>
                  {options.map((item) => {
                    if (!item.isAvailable) return null;

                    return (
                      <PrintOptionPreview
                        key={`print-option--${item.option.id}`}
                        option={item.option}
                        isAvailable={item.isAvailable}
                        isSelected={item.isSelected}
                        price={item.price}
                        discount={item.discount}
                        onClick={() => {
                          let newSelected = props.selected.filter(
                            (o) => o.type !== item.option.type
                          );

                          newSelected = [...newSelected, item.option];

                          newSelected = newSelected.filter((o) =>
                            isOptionAvailable(o, newSelected)
                          );

                          props.onSelect(newSelected);
                        }}
                      />
                    );
                  })}
                </Row>
              </div>
            </Column>
            <hr />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
