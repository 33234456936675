import { authorised } from "../../request";
import { InHomeTag } from "../../types/InHomeTag";

export async function deleteInHomeTag(id: string) {
  const API = authorised();

  return await API.request<InHomeTag>({
    method: "DELETE",
    url: `/api/admin/in-home-tags/${id}/`
  });
}
