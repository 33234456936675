import { Media } from "../types/Media";

export function getImageUrl(file: Media, width?: number) {
  const url = `${process.env.REACT_APP_MEDIA_URL}/${file.file_path}`;

  if (process.env.REACT_APP_IMAGE_RESIZING === "false") return url;

  const max = 9999;

  return `${process.env.REACT_APP_RESIZE_URL}/fit/${
    width || 1024
  }/${max}/sm/0/plain/local:///${file.file_path}`;
}
