import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import * as Formik from "formik";

const FieldError: React.FunctionComponent<{ name: string }> = props => {
  return (
    <Formik.ErrorMessage
      name={props.name}
      render={(errorMessage: any) => {
        if (typeof errorMessage !== "string") return null;

        return <p>{errorMessage}</p>;
      }}
    />
  );
};

type Option = {
  value: string | number;
  label: string;
};

export function AutocompleteField(props: {
  name: string;
  label: string;
  options: Option[];
  filterSelectedOptions?: boolean;
}) {
  const { options, name, label } = props;

  return (
    <div>
      <Formik.Field
        name={props.name}
        render={({ form, field }: Formik.FieldProps) => {
          const chosen = options.filter(option => {
            return field.value.includes(option.value);
          });

          return (
            <div>
              <Autocomplete
                options={options}
                getOptionLabel={option => option.label}
                style={{ width: "100%" }}
                filterSelectedOptions={props.filterSelectedOptions}
                onChange={(_event, options) => {
                  form.setFieldValue(
                    name,
                    options.map((option: Option) => {
                      return option.value;
                    })
                  );
                }}
                multiple={true}
                value={chosen}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label={label}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: chosen.length > 0 }}
                    />
                  );
                }}
              />
              <FieldError name={props.name} />
            </div>
          );
        }}
      />
    </div>
  );
}
