import { authorised } from "../../request";
import { PostTag } from "../../types/PostTag";

export async function deletePostTag(id: string) {
  const API = authorised();

  return await API.request<PostTag>({
    method: "DELETE",
    url: `/api/admin/post-tags/${id}/`
  });
}
