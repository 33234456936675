import { authorised } from "../../request";
import { Award } from "../../types/Award";

export async function deleteAward(id: string) {
  const API = authorised();

  return await API.request<Award>({
    method: "DELETE",
    url: `/api/admin/awards/${id}/`
  });
}
