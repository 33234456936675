import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PostForm } from "../../forms/PostForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updatePost } from "../../actions/post/updatePost";
import { deletePost } from "../../actions/post/deletePost";
import { useDetail } from "../../useAPI";
import { Post } from "../../types/Post";

export function PostEdit(
  props: RouteChildrenProps<{
    postId: string;
  }>
) {
  const postId = props.match!.params.postId;
  const { data, error } = useDetail<Post>("posts", postId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/posts"}>Posts</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <PostForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updatePost(postId, values);
          }}
          onDelete={async () => {
            await deletePost(postId);

            props.history.push("/posts");
          }}
        />
      )}
    </Layout>
  );
}
