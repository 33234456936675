import React from "react";
import * as Formik from "formik";
import { TextField } from "./TextField";
import { Column } from "../../helpers/layout";

export const BlockQuoteField = (props: { name: string }) => (
  <Column>
    <Formik.FastField
      name={`${props.name}.quote`}
      label="Quote"
      type="textarea"
      multiline
      fullWidth
      rows="4"
      component={TextField}
      margin="normal"
      variant="outlined"
    />
    <Formik.FastField
      name={`${props.name}.author`}
      label="Author"
      type="text"
      fullWidth
      component={TextField}
      margin="normal"
      variant="outlined"
    />
  </Column>
);
