import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { HelpTextForm } from "../../forms/HelpTextForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateHelpText } from "../../actions/help-text/updateHelpText";
import { deleteHelpText } from "../../actions/help-text/deleteHelpText";
import { useDetail } from "../../useAPI";
import { HelpText } from "../../types/HelpText";

export function HelpTextEdit(
  props: RouteChildrenProps<{
    helpTextId: string;
  }>
) {
  const helpTextId = props.match!.params.helpTextId;
  const { data, error } = useDetail<HelpText>("help-texts", helpTextId);

  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/help-texts"}>Help Texts</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.title : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <HelpTextForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateHelpText(helpTextId, values);
          }}
          onDelete={async () => {
            await deleteHelpText(helpTextId);

            props.history.push("/help-texts");
          }}
        />
      )}
    </Layout>
  );
}
