import React from "react";
import { Address } from "../types/Address";
import { Column } from "../helpers/layout";

export function AddressPreview(props: { address: Address }) {
  const {
    first_name,
    last_name,
    company_name,
    country,
    city,
    street_address,
    state,
    postal_code,
    email,
    phone_number
  } = props.address;

  return (
    <Column>
      <div>
        <div>
          {first_name} {last_name}
        </div>
        {company_name && <div>{company_name}</div>}
        <div>{street_address}</div>
        <div>
          {state}, {city} {postal_code}
        </div>
        <div>{country}</div>
      </div>
      <div>
        <strong>Email:</strong>
        <p>{email}</p>
      </div>
      <div>
        <strong>Phone number:</strong>
        <p>{phone_number}</p>
      </div>
    </Column>
  );
}
