import { authorised } from "../../request";
import { HelpText } from "../../types/HelpText";

export async function updateHelpText(id: string, data: Omit<HelpText, "id">) {
  const API = authorised();

  return await API.request<HelpText>({
    method: "PATCH",
    url: `/api/admin/help-texts/${id}/`,
    data
  });
}
