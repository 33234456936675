import React from "react";
import * as Formik from "formik";
import SortableTree, { ExtendedNodeData, TreeItem } from "react-sortable-tree";
import { removeNode } from "react-sortable-tree";
import TrashIcon from "@material-ui/icons/Delete";

export function TreeField<T>(props: {
  name: string;
  items: TreeItem[];
  generateNodeProps: (
    data: ExtendedNodeData,
    buttons: JSX.Element[]
  ) => { [index: string]: any };
}) {
  return (
    <Formik.Field
      name={props.name}
      render={({ form, field }: Formik.FieldProps) => {
        return (
          <SortableTree
            treeData={field.value}
            onChange={treeData => form.setFieldValue(props.name, treeData)}
            maxDepth={4}
            canDrag={({ node }) => !node.noDragging}
            isVirtualized={false}
            generateNodeProps={rowInfo => {
              const buttons = [
                <TrashIcon
                  fontSize="small"
                  onClick={() => {
                    const treeData = removeNode({
                      treeData: field.value,
                      path: rowInfo.path,
                      getNodeKey: ({ treeIndex }) => {
                        return treeIndex;
                      }
                    });

                    if (treeData) {
                      form.setFieldValue(props.name, treeData.treeData);
                    }
                  }}
                />
              ];

              return props.generateNodeProps(rowInfo, buttons);
            }}
          />
        );
      }}
    />
  );
}
