import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { Award } from "../../types/Award";
import { RouteChildrenProps } from "react-router";
import { Image } from "../../components/Image";

export function AwardList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/awards/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Awards</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Award>
          url="awards"
          head={[
            { label: "Logo", width: "100px" },
            { label: "Rewarder" },
            { label: "Reward" },
            { label: "Products" },
            { label: "Films" },
            { label: "Year" },
            { label: "Actions", align: "right" },
          ]}
        >
          {(result) => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  {result.rewarder && (
                    <Image
                      file={result.rewarder.logo}
                      style={{
                        width: "100%",
                      }}
                      width={150}
                    />
                  )}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.rewarder ? result.rewarder.name : "Not specified"}
                </TableCell>
                <TableCell>{result.reward}</TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.products.map((p) => p.name).join(", ")}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.films.map((f) => f.name).join(", ")}
                </TableCell>
                <TableCell>{result.year}</TableCell>
                <TableCell align="right">
                  <Link to={`/awards/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
