import { authorised } from "../../request";
import { InHomeTag } from "../../types/InHomeTag";

export async function createInHomeTag(data: Omit<InHomeTag, "id">) {
  const API = authorised();

  return await API.request<InHomeTag>({
    method: "POST",
    url: "/api/admin/in-home-tags/",
    data
  });
}
