import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileCopy from "@material-ui/icons/FileCopy";
import * as Formik from "formik";
import * as Yup from "yup";
import { Menu, MenuItem } from "../types/Menu";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { TreeField } from "./fields/TreeField";
import { SlugField } from "./fields/SlugField";
import { SlugAutoCompleteField } from "./fields/SlugAutoCompleteField";
import { MenuItemModal } from "../modals/MenuItemModal";
import EditIcon from "@material-ui/icons/Edit";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<Menu, "name" | "identifier" | "items">;
type Callback = () => void;

const updateItem = (items: MenuItem[], update: MenuItem): MenuItem[] => {
  return items.map((item) => {
    if (item.uuid === update.uuid) {
      return update;
    }

    if (item.children) {
      return {
        ...item,
        children: updateItem(item.children, update),
      };
    }

    return item;
  });
};

export function MenuForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDuplicate?: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);
  const [showMenuItemModal, setShowMenuItemModal] = useState<
    MenuItem | "new" | null
  >(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          identifier: "",
          items: [],
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        identifier: Yup.string().required(),
        items: Yup.array().of(
          Yup.object().shape({
            type: Yup.string(),
            name: Yup.string(),
            url: Yup.string(),
            new_window: Yup.boolean(),
          })
        ),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Formik.Form>
          <Row>
            <div
              css={css`
                flex: 1 0 50%;
                width: 50%;
              `}
            >
              <Column>
                <Paper>
                  <Formik.FastField
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={SlugAutoCompleteField}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ field_name: "identifier" }}
                  />
                  <Formik.FastField
                    name="identifier"
                    label="Identifier"
                    type="text"
                    fullWidth
                    component={SlugField}
                    margin="normal"
                    variant="outlined"
                  />
                </Paper>
                <Row>
                  <div
                    css={css`
                      flex: 0 0 280px;
                    `}
                  >
                    <Paper>
                      <Column>
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setShowMenuItemModal("new");
                          }}
                        >
                          Add new item
                        </Button>
                      </Column>
                    </Paper>
                  </div>
                  <div
                    css={css`
                      flex: 1 0 30%;
                    `}
                  >
                    <TreeField
                      name="items"
                      items={values.items}
                      generateNodeProps={(rowInfo, buttons) => ({
                        title: rowInfo.node.name,
                        subtitle: <div>{rowInfo.node.url}</div>,
                        buttons: [
                          <EditIcon
                            fontSize="small"
                            onClick={() => {
                              setShowMenuItemModal({
                                ...rowInfo.node,
                                copy_to_menu: [],
                              } as MenuItem);
                            }}
                          />,
                          ...buttons,
                        ],
                      })}
                    />
                  </div>
                </Row>
              </Column>
            </div>
            <div
              css={css`
                flex: 0 0 280px;
              `}
            >
              <Paper>
                <Column>
                  <Row justify="flex-end">
                    {props.onDuplicate && (
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          props.onDuplicate!(values);
                        }}
                      >
                        <FileCopy />
                      </Button>
                    )}
                    {props.onDelete && (
                      <div>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setConfirmAction(() => {
                              return props.onDelete;
                            });
                          }}
                        >
                          Delete
                        </Button>
                        {confirmAction !== null && (
                          <ConfirmModal
                            confirmAction={confirmAction}
                            handleClose={() => {
                              setConfirmAction(null);
                            }}
                            open={true}
                            label="Please confirm deletion"
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={25} />
                        ) : props.type === "update" ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </div>
                  </Row>
                  {error && <Error text={error} />}
                </Column>
              </Paper>
            </div>
          </Row>
          <MenuItemModal
            open={showMenuItemModal !== null}
            handleClose={() => {
              setShowMenuItemModal(null);
            }}
            initialValues={
              showMenuItemModal === "new" ? null : showMenuItemModal
            }
            onSubmit={(item) => {
              if (showMenuItemModal === "new") {
                setFieldValue("items", [...values.items, item]);
              } else {
                setFieldValue("items", updateItem(values.items, item));
              }
            }}
          />
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
