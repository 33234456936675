import { authorised } from "../../request";
import { InHome } from "../../types/InHome";

export async function createInHome(data: Omit<InHome, "id">) {
  const API = authorised();

  return await API.request<InHome>({
    method: "POST",
    url: "/api/admin/in-homes/",
    data
  });
}
