import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { TaxRateForm } from "../../forms/TaxRateForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateTaxRate } from "../../actions/tax-rate/updateTaxRate";
import { deleteTaxRate } from "../../actions/tax-rate/deleteTaxRate";
import { useDetail } from "../../useAPI";
import { TaxRate } from "../../types/TaxRate";

export function TaxRateEdit(
  props: RouteChildrenProps<{
    taxRateId: string;
  }>
) {
  const taxRateId = props.match!.params.taxRateId;
  const { data, error } = useDetail<TaxRate>("tax-rates", taxRateId);

  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/tax-rates"}>Tax Rates</Link>
        <Typography color="textPrimary">
          {error ? error : data ? `${data.country} ${data.state}` : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <TaxRateForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateTaxRate(taxRateId, values);
          }}
          onDelete={async () => {
            await deleteTaxRate(taxRateId);

            props.history.push("/tax-rates");
          }}
        />
      )}
    </Layout>
  );
}
