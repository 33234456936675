import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { css } from "styled-components/macro";

export function SearchForm(props: {
  query: string;
  onSearch: (query: string) => void;
}) {
  const [searchQuery, setSearchQuery] = useState(props.query);

  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          max-width: 200px;
          padding-right: 70px;
        `}
      >
        <input
          placeholder="Search"
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();

              props.onSearch(searchQuery);
            }
          }}
          css={css`
            width: 100%;
            height: 35px;
            border-radius: 5px;
            padding: 0 10px;
            background: #eee;
            border: none;
            outline: none;
          `}
        />
        <button
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            padding: 5px 15px;
            margin: auto;
            background: black;
            border-radius: 5px;
            border: none;
            outline: none;
          `}
          onClick={() => {
            props.onSearch(searchQuery);
          }}
        >
          <SearchIcon htmlColor="#fff" />
        </button>
      </div>
    </div>
  );
}
