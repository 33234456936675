import { authorised } from "../../request";
import { ProductOption } from "../../types/ProductOption";

export async function updateProductOption(
  id: string,
  data: Omit<ProductOption, "id" | "serie">
) {
  const API = authorised();

  return await API.request<ProductOption>({
    method: "PATCH",
    url: `/api/admin/product-options/${id}/`,
    data
  });
}
