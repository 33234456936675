import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SerieConfigurationForm } from "../../forms/SerieConfigurationForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createSerie } from "../../actions/serie/createSerie";
import { RouteChildrenProps } from "react-router";
import { deserializeDate } from "../../helpers/utils";

export function SerieCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/series"}>Series</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <SerieConfigurationForm
        type="create"
        onSubmit={async (values) => {
          const response = await createSerie({
            ...values,
            copyright_year: deserializeDate(values.copyright_year),
            published_date: deserializeDate(values.published_date),
            blocks: [],
            product_blocks: [],
          });

          props.history.push(`/series/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
