import { authorised } from "../../request";
import { EstimateItem } from "../../types/EstimateItem";

export async function updateEstimateItem(
  id: string,
  data: Omit<EstimateItem, "id" | "subtotal" | "discount" | "total" | "book_option">
) {
  const API = authorised();

  return await API.request<EstimateItem>({
    method: "PATCH",
    url: `/api/admin/estimate-items/${id}/`,
    data
  });
}
