import { authorised } from "../../request";
import { Film } from "../../types/Film";

export async function updateFilm(id: string, data: Omit<Film, "id">) {
  const API = authorised();

  return await API.request<Film>({
    method: "PATCH",
    url: `/api/admin/films/${id}/`,
    data
  });
}
