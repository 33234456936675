import { authorised } from "../../request";
import { Press } from "../../types/Press";

export async function createPress(data: Omit<Press, "id">) {
  const API = authorised();

  return await API.request<Press>({
    method: "POST",
    url: "/api/admin/press/",
    data
  });
}
