import { authorised } from "../../request";
import { Order } from "../../types/Order";

export async function updateOrder(id: string, data: Pick<Order, "status">) {
  const API = authorised();

  return await API.request<Order>({
    method: "PATCH",
    url: `/api/admin/orders/${id}/`,
    data
  });
}
