import React, { useState } from 'react';
import * as Formik from "formik";
import unionBy from 'lodash/unionBy'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Image } from '../../components/Image';
import { ModelSelector } from '../../modals/ModelSelector';


type Props = {
  name: string;
}

type Item = {
  name: string;
  id: number;
  featured_image: any;
}

const ListProductsField:React.FC<Props> = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Formik.Field
      name={props.name}
      render={({ form, field }: Formik.FieldProps) => {
        const setValue = (value: Item[]) => form.setFieldValue(props.name, value);
        return (
          <>
            <Button onClick={() => setOpen(true)}>Add</Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {field.value.map((item: Item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {typeof item.featured_image !== "undefined" && (
                        <Image
                          file={item.featured_image}
                          width={150}
                          style={{
                            width: "150px",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          setValue(
                            field.value.filter(
                              (product: Item) => product.id !== item.id
                            )
                          )
                        }
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {isOpen && (
              <ModelSelector
                open={true}
                handleClose={() => {
                  setOpen(false);
                }}
                enableSearch
                multiple
                url="products"
                label="Products"
                selectedValues={field.value}
                onSelect={(items) => {
                  const newItems = items.map((newItem) => ({
                    id: newItem.id,
                    featured_image: newItem.featured_image,
                    name: newItem.name,
                  }));
                  setValue(
                    unionBy(
                      [...field.value, ...newItems],
                      "id"
                    ).sort((a: Item, b: Item) => a.name.localeCompare(b.name))
                  );
                  setOpen(false);
                }}
              />
            )}
          </>
        );
      }}
    />
  )
}

export default ListProductsField;
