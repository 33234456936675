import React from "react";
import { ProductOption } from "../types/ProductOption";
import { css } from "styled-components/macro";
import { Column, Spacing } from "../helpers/layout";
import { useUserState } from "../context/UserContext";
import NumberFormat from "react-number-format";
import { Image } from "./Image";

export function PrintOptionPreview(props: {
  option: ProductOption;
  price: number;
  discount: number;
  isAvailable: boolean;
  isSelected: boolean;
  onClick: () => void;
}) {
  const { isAuthenticated } = useUserState();
  const price = props.price - props.discount;

  return (
    <div
      onClick={() => props.onClick()}
      css={css`
        opacity: ${props.isSelected ? "1" : props.isAvailable ? "0.5" : "0.1"};
        text-align: center;
        pointer-events: ${props.isAvailable ? "all" : "none"};
        cursor: pointer;
      `}
    >
      <Column gutter={Spacing.m}>
        {props.option.icon && (
          <div>
            <Image
              style={{
                height: 45
              }}
              file={props.option.icon}
            />
          </div>
        )}
        <Column gutter={Spacing.xs}>
          <h4>{props.option.name}</h4>
          {isAuthenticated && (
            <h4>
              +{" "}
              <NumberFormat
                value={price}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h4>
          )}
        </Column>
      </Column>
    </div>
  );
}
