import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { WishlistForm } from "../../forms/WishlistForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { useDetail } from "../../useAPI";
import { Wishlist } from "../../types/Wishlist";

export function WishlistEdit(
  props: RouteChildrenProps<{
    wishlistId: string;
  }>
) {
  const wishlistId = props.match!.params.wishlistId;
  const { data, error } = useDetail<Wishlist>("wishlists", wishlistId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/wishlists"}>Wishlists</Link>
        <Typography color="textPrimary">
          {error
            ? error
            : data
            ? `${data.user.first_name} ${data.user.last_name}`
            : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && <WishlistForm wishlist={data} />}
    </Layout>
  );
}
