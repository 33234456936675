import { authorised } from "../../request";
import { Press } from "../../types/Press";

export async function deletePress(id: string) {
  const API = authorised();

  return await API.request<Press>({
    method: "DELETE",
    url: `/api/admin/press/${id}/`
  });
}
