import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PresetForm } from "../../forms/PresetForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updatePreset } from "../../actions/preset/updatePreset";
import { deletePreset } from "../../actions/preset/deletePreset";
import { useDetail } from "../../useAPI";
import { Preset } from "../../types/Preset";

export function PresetEdit(
  props: RouteChildrenProps<{
    presetId: string;
  }>
) {
  const presetId = props.match!.params.presetId;
  const { data, error } = useDetail<Preset>("presets", presetId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/presets"}>Presets</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <PresetForm
          type="update"
          initialValues={{
            name: data.name,
            sizes: data.sizes.map(s => {
              return {
                width: s.width.toString(),
                height: s.height.toString(),
                price: s.price
              };
            })
          }}
          onSubmit={async values => {
            await updatePreset(presetId, {
              name: values.name,
              sizes: values.sizes.map(s => {
                return {
                  width: parseFloat(s.width),
                  height: parseFloat(s.height),
                  price: s.price
                };
              })
            });
          }}
          onDelete={async () => {
            await deletePreset(presetId);

            props.history.push("/presets");
          }}
        />
      )}
    </Layout>
  );
}
