import { authorised } from "../../request";
import { Page } from "../../types/Page";

export async function deletePage(id: string) {
  const API = authorised();

  return await API.request<Page>({
    method: "DELETE",
    url: `/api/admin/pages/${id}/`
  });
}
