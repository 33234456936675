import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import { MenuItemForm } from "../forms/MenuItemForm";
import { MenuItem } from "../types/Menu";

export function MenuItemModal(props: {
  open: boolean;
  initialValues: MenuItem | null;
  handleClose: () => void;
  onSubmit: (size: MenuItem) => void;
}) {
  const formRef = useRef<Formik>(null);
  const { initialValues } = props;

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>
        {initialValues ? initialValues.name : "New menu item"}
      </DialogTitle>
      <DialogContent>
        <MenuItemForm
          type={initialValues ? "update" : "create"}
          formRef={formRef}
          initialValues={initialValues}
          onSubmit={item => {
            props.onSubmit(item);
            props.handleClose();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!formRef || !formRef.current) return;

            formRef.current.submitForm();
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
