import React from "react";
import { AtomicBlockUtils } from "draft-js";
import { css } from "styled-components/macro";

export function HROption(props: {
  editorState: any;
  onChange: any;
  modalHandler: any;
}) {
  const { editorState, onChange } = props;

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <div
        className="rdw-option-wrapper"
        onClick={() => {
          const entityKey = editorState
            .getCurrentContent()
            .createEntity("HR", "MUTABLE")
            .getLastCreatedEntityKey();

          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            "---"
          );
          onChange(newEditorState);
        }}
      >
        Line
      </div>
    </div>
  );
}
