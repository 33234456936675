import React from "react";
import { Canvas, Spacing } from "../types/Canvas";
import * as layout from "../helpers/layout";
import { Image } from "./Image";
import { css } from "styled-components/macro";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import { ArrowTooltip } from "./ArrowTooltip";
import { CanvasItem } from "./CanvasItem";
import { FileInfo } from "./FileInfo";

export function getSpacing(spacing: Spacing) {
  if (spacing === "l") {
    return layout.Spacing.xl;
  }
  if (spacing === "xl") {
    return layout.Spacing.xxl;
  }
  if (spacing === "xxl") {
    return layout.Spacing.xxxl;
  }
  if (spacing === "xxxl") {
    return layout.Spacing.xxxxl;
  }

  return layout.Spacing.none;
}

export function CanvasPreview(props: { canvas: Canvas; onEdit?: () => void }) {
  const {
    image,
    align,
    padding,
    components,
    bg_color,
    bg_opacity
  } = props.canvas;

  return (
    <div>
      <div
        css={css`
          width: 100%;
          overflow: hidden;
          border: 1px solid gray;
          position: relative;
        `}
      >
        <div
          css={css`
            position: relative;
            width: 1791px;
            zoom: 0.51;
            ${padding &&
              css`
                padding-top: ${getSpacing(padding.top)};
                padding-bottom: ${getSpacing(padding.bottom)};
                padding-left: ${getSpacing(padding.left)};
                padding-right: ${getSpacing(padding.right)};
              `};
          `}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              css={css`
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: ${bg_color};
              `}
            ></div>
            <Image
              file={image.value}
              style={{
                position: "relative",
                display: "block",
                width: "100%",
                opacity: bg_opacity
              }}
            />

            {/* Render existing items */}
            {components.map(component => {
              return (
                <CanvasItem
                  key={`component--${component.id}`}
                  item={component}
                  align={align}
                />
              );
            })}
          </div>
        </div>
        {/* Render edit button */}
        {props.onEdit && (
          <div
            css={css`
              position: absolute;
              top: 10px;
              right: 10px;
            `}
          >
            <ArrowTooltip title={"Edit canvas"}>
              <Fab
                size="small"
                color="secondary"
                onClick={() => {
                  props.onEdit!();
                }}
              >
                <EditIcon />
              </Fab>
            </ArrowTooltip>
          </div>
        )}
      </div>
      <FileInfo file={image.value} />
    </div>
  );
}
