import React from "react";
import Button from "@material-ui/core/Button";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { Wishlist } from "../types/Wishlist";
import { OrderItemPreview } from "../components/OrderItemPreview";
import { useHistory } from "react-router-dom";

export function WishlistForm(props: { wishlist: Wishlist }) {
  const { wishlist } = props;
  const history = useHistory();

  return (
    <Row>
      <div
        css={css`
          flex: 1 0 50%;
          width: 50%;
        `}
      >
        <Column>
          <Paper>
            {wishlist.items.map(item => {
              return (
                <OrderItemPreview
                  item={item}
                  key={`wishlist-items--${item.id}`}
                />
              );
            })}
          </Paper>
        </Column>
      </div>
      <div
        css={css`
          flex: 0 0 280px;
        `}
      >
        <Column>
          <Paper>
            <Column>
              <Row justify="flex-end">
                <div>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push("/wishlists");
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </Row>
            </Column>
          </Paper>
        </Column>
      </div>
    </Row>
  );
}
