import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Rewarder } from "../types/Rewarder";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { ImageField } from "./fields/ImageField";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<Rewarder, "name" | "logo" | "white_logo">;
type Callback = () => void;

export function RewarderForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          logo: null,
          white_logo: null,
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Formik.Form>
          <Row>
            <div
              css={css`
                flex: 1 0 50%;
                width: 50%;
              `}
            >
              <Column>
                <Paper>
                  <Formik.FastField
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                </Paper>
              </Column>
            </div>
            <div
              css={css`
                flex: 0 0 280px;
              `}
            >
              <Column>
                <Paper>
                  <Column>
                    <Row justify="flex-end">
                      {props.onDelete && (
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setConfirmAction(() => {
                                return props.onDelete;
                              });
                            }}
                          >
                            Delete
                          </Button>
                          {confirmAction !== null && (
                            <ConfirmModal
                              confirmAction={confirmAction}
                              handleClose={() => {
                                setConfirmAction(null);
                              }}
                              open={true}
                              label="Please confirm deletion"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : props.type === "update" ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </div>
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
                <Paper>
                  <ImageField name={"logo"} label="Logo" />
                </Paper>
                <Paper>
                  <ImageField name={"white_logo"} label="White Logo" />
                </Paper>
              </Column>
            </div>
          </Row>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
