import React from "react";
import * as Formik from "formik";
import { Column, Row } from "../../helpers/layout";
import { PickField } from "./PickField";
import { SelectField } from "./SelectField";
import { Typography } from "@material-ui/core";

export function BlockProductsField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <Row>
              <SelectField
                label="Layout"
                name={`${field.name}.layout`}
                options={[
                  {
                    value: "masonry",
                    label: "Masonry",
                  },
                  {
                    value: "grid",
                    label: "Grid",
                  },
                ]}
              />
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <SelectField
                  label="Style"
                  name={`${field.name}.layout_style`}
                  options={[
                    {
                      value: "masonry",
                      label: "3 Columns Layout",
                    },
                    {
                      value: "wide",
                      label: "2 Columns Layout",
                    },
                  ]}
                />
                {field.value.layout === "grid" && (
                  <Typography
                    variant="caption"
                    style={{ display: "block", marginTop: "10px" }}
                  >
                    Due to layout complexity, the 3 columns layout will apply
                    only on screens wider than 1280 pixels.
                  </Typography>
                )}
              </div>
            </Row>
            <PickField
              name={`${field.name}.products`}
              label="Products"
              url="products"
              multiple
              enableSearch
              sortable
              relatedModel="Product"
            />
          </Column>
        );
      }}
    />
  );
}
