import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Copyright } from "../components/Copyright";
import { useUserState } from "../context/UserContext";
import * as Yup from "yup";

interface FormValues {
  email: string;
  password: string;
}

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export function Login() {
  const classes = useStyles();
  const { login } = useUserState();
  const [error, setError] = useState<string | null>(null);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Admin Panel
        </Typography>
        <Formik.Formik<FormValues>
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required(),
            password: Yup.string().required()
          })}
          onSubmit={async (values: FormValues, { setSubmitting }) => {
            try {
              setSubmitting(true);
              await login(values);
            } catch (error) {
              setError(error.toString());
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Formik.Form className={classes.form}>
              <Formik.FastField
                name="email"
                label="Email"
                type="email"
                fullWidth
                component={TextField}
                variant="outlined"
                margin="normal"
              />
              <Formik.FastField
                name="password"
                label="Password"
                type="password"
                fullWidth
                component={TextField}
                variant="outlined"
                margin="normal"
              />
              <FormHelperText error={!!error}>{error}</FormHelperText>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={25} /> : "Sign In"}
              </Button>
            </Formik.Form>
          )}
        </Formik.Formik>
        <Copyright />
      </div>
    </Container>
  );
}
