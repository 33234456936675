import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { Image } from "../../components/Image";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { ProductOption } from "../../types/ProductOption";
import { RouteChildrenProps } from "react-router";

export function ProductOptionList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/product-options/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Product Options</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<ProductOption>
          url="product-options"
          head={[
            {
              label: "Icon",
            },
            {
              label: "Type",
            },
            {
              label: "Name",
            },
            {
              label: "Admin Name",
            },
            {
              label: "Actions",
              align: "right",
            },
          ]}
        >
          {(result) => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  <Image
                    file={result.icon}
                    style={{
                      height: 40,
                    }}
                    width={150}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.type}</TableCell>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.admin_name}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/product-options/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
