import { authorised } from "../../request";
import { ProductTag } from "../../types/ProductTag";

export async function updateProductTag(
  id: string,
  data: Omit<ProductTag, "id">
) {
  const API = authorised();

  return await API.request<ProductTag>({
    method: "PATCH",
    url: `/api/admin/product-tags/${id}/`,
    data
  });
}
