import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { TaxRate } from "../../types/TaxRate";
import { RouteChildrenProps } from "react-router";

export function TaxRateList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/tax-rates/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Tax Rates</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<TaxRate>
          url="tax-rates"
          head={[
            {
              label: "Country"
            },
            {
              label: "State"
            },
            {
              label: "Rate"
            },
            {
              label: "Actions",
              align: "right"
            }
          ]}
        >
          {result => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  {result.country}
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.state}</TableCell>
                <TableCell className="pl-3 fw-normal">{result.rate}%</TableCell>
                <TableCell align="right">
                  <Link to={`/tax-rates/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
