import { authorised } from "../../request";
import { InHome } from "../../types/InHome";

export async function deleteInHome(id: string) {
  const API = authorised();

  return await API.request<InHome>({
    method: "DELETE",
    url: `/api/admin/in-homes/${id}/`
  });
}
