import { authorised } from "../../request";
import { Film } from "../../types/Film";

export async function deleteFilm(id: string) {
  const API = authorised();

  return await API.request<Film>({
    method: "DELETE",
    url: `/api/admin/films/${id}/`
  });
}
