import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import { Film } from "../types/Film";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { ImageField } from "./fields/ImageField";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<
  Film,
  | "name"
  | "subtitle"
  | "location"
  | "url"
  | "duration"
  | "cover"
  | "white_logos"
  | "description"
  | "button_url"
  | "button_label"
>;
type Callback = () => void;

export function FilmForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          subtitle: "",
          url: "",
          duration: "",
          location: "",
          description: "",
          cover: null,
          white_logos: false,
          button_url: "",
          button_label: "",
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        subtitle: Yup.string(),
        url: Yup.string().url().required(),
        button_url: Yup.string().nullable(),
        button_label: Yup.string().nullable(),
        duration: Yup.string().required(),
        location: Yup.string(),
        description: Yup.string(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Formik.FastField
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="subtitle"
                        label="Subtitle"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="description"
                        label="Description"
                        type="text"
                        multiline
                        fullWidth
                        rows="4"
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                    </Column>
                  </Paper>
                  <Paper>
                    <Column>
                      <Formik.FastField
                        name="url"
                        label="Video url"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="duration"
                        label="Duration"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="location"
                        label="Location"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                    </Column>
                  </Paper>

                  <Paper>
                    <Column>
                      <Formik.FastField
                        name="button_url"
                        label="Button url"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <Formik.FastField
                        name="button_label"
                        label="Button Label"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                    </Column>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Row justify="flex-end">
                        {props.onDelete && (
                          <div>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                setConfirmAction(() => {
                                  return props.onDelete;
                                });
                              }}
                            >
                              Delete
                            </Button>
                            {confirmAction !== null && (
                              <ConfirmModal
                                confirmAction={confirmAction}
                                handleClose={() => {
                                  setConfirmAction(null);
                                }}
                                open={true}
                                label="Please confirm deletion"
                              />
                            )}
                          </div>
                        )}
                        <div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={25} />
                            ) : props.type === "update" ? (
                              "Update"
                            ) : (
                              "Create"
                            )}
                          </Button>
                        </div>
                      </Row>
                      {error && <Error text={error} />}
                    </Column>
                  </Paper>
                  <Paper>
                    <ImageField name="cover" label="Cover" />
                  </Paper>
                  <Paper>
                    <Formik.FastField
                      name={"white_logos"}
                      Label={{ label: "White Logos?" }}
                      component={CheckboxWithLabel}
                    />
                  </Paper>
                </Column>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
