import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { User } from "../types/User";
import { ListTable } from "../components/ListTable";
import { IconButton, TableCell, TableRow, TextField } from "@material-ui/core";
import { Column } from "../helpers/layout";
import { notifyAboutPriceIncrease } from "../actions/product/notifyAboutPriceIncrease";
import Checkbox from "@material-ui/core/Checkbox";
import { format } from "date-fns";
import { RemoveRedEyeRounded } from "@material-ui/icons";
import { authorised } from "../request";

type UserWithWishlistDate = User & {
  item_added_at: string | null;
};

const EmailPreview = ({
  product,
  user,
  setUser,
  numberOfItems,
}: {
  product: string;
  user: string | null;
  setUser: (user: string | null) => void;
  numberOfItems: string;
}) => {
  const [previewData, setPreview] = useState<{
    subject: string;
    html: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getEmailPreview = useCallback(async () => {
    setError(null);
    const API = authorised();
    const response = await API.request<{ subject: string; html: string }>({
      url: `/api/admin/products/${product}/price-increase-preview/`,
      params: {
        user_id: user,
        number_of_items: numberOfItems,
      },
    });
    if (response.status === 200) {
      setPreview(response.data);
    } else {
      setError("Failed to load preview");
    }
  }, [numberOfItems, product, user]);

  useEffect(() => {
    if (user) {
      getEmailPreview();
    } else {
      setPreview(null);
      setError(null);
    }
  }, [getEmailPreview, user]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      scroll="paper"
      open={!!user}
      style={{ marginTop: 60 }}
    >
      <DialogTitle>
        {previewData ? `Subject: ${previewData.subject}` : "Email preview"}
        <Button
          onClick={() => setUser(null)}
          style={{ position: "absolute", right: 20 }}
        >
          Close
        </Button>
      </DialogTitle>
      <DialogContent>
        {error ? (
          <div>{error}</div>
        ) : previewData ? (
          <div>
            <div
              style={{ marginTop: 40 }}
              dangerouslySetInnerHTML={{
                __html: previewData.html,
              }}
            />
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export function PriceIncreaseModal(props: {
  open: boolean;
  handleClose: () => void;
  product: {
    id: string;
    name: string;
  };
}) {
  const [numberOfItems, setNumberOfItems] = useState("1");
  const [sending, setSending] = useState(false);
  const [unselected, setUnselected] = useState<string[]>([]);
  const [previewUser, setPreviewUser] = useState<string | null>(null);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={props.open}
        style={{ marginTop: 60 }}
      >
        <DialogTitle>Notify about price increase</DialogTitle>
        <DialogContent>
          <Column>
            <div>
              <h4>Number of pieces left:</h4>
              <TextField
                type="number"
                fullWidth
                value={numberOfItems}
                variant="outlined"
                onChange={(e) => {
                  setNumberOfItems(e.target.value);
                }}
              />
            </div>
            <div>
              <h4>Users who will receive email:</h4>
              <ListTable<UserWithWishlistDate>
                url={`products/${props.product.id}/price-increase/users`}
                head={[
                  {
                    label: "Selected",
                  },
                  {
                    label: "Name",
                  },
                  {
                    label: "Date",
                  },
                  {
                    label: "Email",
                  },
                  {
                    label: "Preview",
                  },
                ]}
                emptyMessage={`There are no users who have "${props.product.name}" in their cart, wishlist, comparison or curate your space.`}
              >
                {(result) => {
                  return (
                    <TableRow key={result.id}>
                      <TableCell className="pl-3 fw-normal">
                        <Checkbox
                          checked={
                            !unselected.find((userId) => userId === result.id)
                          }
                          onChange={() => {
                            setUnselected((unselected) => {
                              const hasItem = unselected.find(
                                (userId) => userId === result.id
                              );

                              if (hasItem) {
                                return unselected.filter(
                                  (userId) => userId !== result.id
                                );
                              }

                              return [...unselected, result.id];
                            });
                          }}
                          value="checkedB"
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                      </TableCell>
                      <TableCell className="pl-3 fw-normal">
                        {result.first_name} {result.last_name}
                      </TableCell>
                      <TableCell className="pl-3 fw-normal">
                        {result.item_added_at &&
                          format(
                            Date.parse(result.item_added_at),
                            "MMM do, YYY"
                          )}
                      </TableCell>
                      <TableCell className="pl-3 fw-normal">
                        {result.username}
                      </TableCell>
                      <TableCell className="pl-3 fw-normal">
                        <IconButton
                          onClick={() => setPreviewUser(result.id)}
                          color="primary"
                        >
                          <RemoveRedEyeRounded />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }}
              </ListTable>
            </div>
          </Column>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={sending}
            onClick={async () => {
              try {
                setSending(true);

                await notifyAboutPriceIncrease(
                  props.product.id,
                  numberOfItems,
                  unselected
                );

                props.handleClose();
              } finally {
                setSending(false);
              }
            }}
            color="primary"
          >
            {sending ? "Sending.." : "Send notifications"}
          </Button>
        </DialogActions>
      </Dialog>
      <EmailPreview
        product={props.product.id}
        user={previewUser}
        setUser={setPreviewUser}
        numberOfItems={numberOfItems}
      />
    </>
  );
}
