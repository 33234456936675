import React from "react";
import { Media } from "../types/Media";
import { Row } from "../helpers/layout";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { css } from "styled-components/macro";

export function FilePreview(props: { file: Media | null }) {
  return (
    <Row>
      <div
        css={css`
          flex: 0 0 auto;
        `}
      >
        <FileCopyIcon />
      </div>
      <div
        css={css`
          flex: 1 0 10%;
        `}
      >
        {props.file ? props.file.upload_name : "No file"}
      </div>
    </Row>
  );
}
