import { authorised } from "../../request";
import { Serie } from "../../types/Serie";

export async function deleteSerie(id: string) {
  const API = authorised();

  return await API.request<Serie>({
    method: "DELETE",
    url: `/api/admin/series/${id}/`
  });
}
