import { authorised } from "../../request";
import { Menu } from "../../types/Menu";

export async function createMenu(data: Omit<Menu, "id">) {
  const API = authorised();

  return await API.request<Menu>({
    method: "POST",
    url: "/api/admin/menus/",
    data
  });
}
