import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { Image } from "../../components/Image";
import { Product } from "../../types/Product";
import { Row } from "../../helpers/layout";
import { RouteChildrenProps } from "react-router";
import { getFrontendUrl } from "../../helpers/router";
import { format } from "date-fns";

export function ProductList(props: RouteChildrenProps<{}>) {
  const { history } = props;
  const query = new URLSearchParams(history.location.search);

  const ordering = query.get("ordering");
  const type = ordering && ordering === "type,-id" ? "-type" : "type";
  const direction = type === "type" ? "asc" : "desc";

  const visibility =
    ordering && ordering === "visibility,-id" ? "-visibility" : "visibility";
  const visibilityDirection = visibility === "visibility" ? "asc" : "desc";

  const created =
    ordering && ordering === "created,-id" ? "-created" : "created";

  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/products/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Products</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Product>
          url="products"
          enableSearch
          head={[
            {
              label: "Featured Image",
              width: "150px",
            },
            {
              label: "Name",
              width: "300px",
            },
            {
              label: "Type",
              width: "150px",
              direction,
              action: () =>
                history.push(
                  `${history.location.pathname}?ordering=${type},-id`
                ),
            },
            {
              label: "Status",
              width: "150px",
              direction: visibilityDirection,
              action: () =>
                history.push(
                  `${history.location.pathname}?ordering=${visibility},-id`
                ),
            },
            // {
            //   width: "250px",
            //   label: "Description",
            // },
            {
              width: "120px",
              label: "Created Date",
              direction: created.startsWith("created") ? "asc" : "desc",
              action: () =>
                history.push(
                  `${history.location.pathname}?ordering=${created},-id`
                ),
            },
            {
              label: "Actions",
              align: "right",
              width: "100px",
            },
          ]}
        >
          {(result) => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  <Image
                    file={result.featured_image}
                    style={{
                      width: "100%",
                    }}
                    width={150}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell className="pl-3 fw-normal">{result.type}</TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.visibility}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {format(Date.parse(result.created), "MM/dd/yyyy")}
                </TableCell>
                <TableCell align="right">
                  <Row align="center">
                    <a
                      href={getFrontendUrl(`shop/${result.slug}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Fab color="secondary" aria-label="edit" size="small">
                        <OpenInNewIcon />
                      </Fab>
                    </a>
                    <Link to={`/products/${result.id}`}>
                      <Fab color="primary" aria-label="edit" size="small">
                        <EditIcon />
                      </Fab>
                    </Link>
                  </Row>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
