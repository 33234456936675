import { authorised } from "../../request";
import { User } from "../../types/User";

export async function updateUser(id: string, data: Partial<Omit<User, "id">>) {
  const API = authorised();

  return await API.request<User>({
    method: "PATCH",
    url: `/api/admin/users/${id}/`,
    data
  });
}
