import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { InHome } from "../../types/InHome";
import { RouteChildrenProps } from "react-router";
import { Image } from "../../components/Image";
import { Chip } from "@material-ui/core";
import { Spacing } from "../../helpers/layout";


const getOrder = (order: string) => {
  if (order) {
    if (order.startsWith('-')) {
      return order.substring(1);
    }
    return `-${order}`;
  }
  return "";
};

const clickOrder = (history: any, queryOrder: string) => (order: string) => {
  if (queryOrder === order) {
    const newOrder = getOrder(order);
    return history.push(`${history.location.pathname}?ordering=${newOrder}`);
  }
  return history.push(`${history.location.pathname}?ordering=${order}`);
};

export function InHomeList(props: RouteChildrenProps<{}>) {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const queryOrder = query.get("ordering") || "";
  const clickHandler = clickOrder(history, queryOrder);

  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/in-homes/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">In Homes</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<InHome>
          url="in-homes"
          enableSearch
          head={[
            { label: "Image", width: "150px" },
            {
              label: "Name",
              action: () => clickHandler("name"),
              direction: queryOrder === "name" ? "asc" : "desc",
            },
            {
              label: "Products",
              action: () => clickHandler("products"),
              direction: queryOrder === "products" ? "asc" : "desc",
            },
            { label: "Related In Homes" },
            {
              label: "Archive Visible",
              action: () => clickHandler("visible_in_archive"),
              direction: queryOrder === "visible_in_archive" ? "asc" : "desc",
              width: '110px',
            },
            {
              label: "Tags",
              action: () => clickHandler("tags"),
              direction: queryOrder === "tags" ? "asc" : "desc",
            },
            { label: "Actions", align: "right" },
          ]}
        >
          {(result) => {
            const tags = result.tags.map((tag) => tag.name);
            const products = result.products.map(
              (connection) => connection.product.name
            );

            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  <Image
                    file={result.image}
                    style={{
                      width: "100%",
                    }}
                    width={150}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell>{products.join(", ")}</TableCell>
                <TableCell>
                  {result.products.map((product) =>
                    product.product.related_in_homes.map((relatedProduct) => (
                      <Link
                        to={`/in-homes/${relatedProduct.id}`}
                        target="_blank"
                        key={relatedProduct.id}
                        style={{ textDecoration: "none" }}
                      >
                        <Chip
                          style={{ cursor: "pointer", margin: Spacing.xs }}
                          color="secondary"
                          label={relatedProduct.name}
                        />
                      </Link>
                    ))
                  )}
                </TableCell>
                <TableCell>
                  {result.visible_in_archive ? "Yes" : "No"}
                </TableCell>
                <TableCell>{tags.join(", ")}</TableCell>
                <TableCell align="right">
                  <Link to={`/in-homes/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
