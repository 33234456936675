import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import { ModelSelector } from "../../modals/ModelSelector";

export function ImportField<
  T extends {
    id: number;
    name: string;
  }
>(props: { label: string; url: string; onSelect: (item: T) => void }) {
  const [showSelector, setShowSelector] = useState(false);

  return (
    <React.Fragment>
      <div>
        <Chip
          label={props.label}
          onClick={() => {
            setShowSelector(true);
          }}
        />
      </div>
      <ModelSelector<T>
        open={showSelector}
        handleClose={() => {
          setShowSelector(false);
        }}
        label={props.label}
        url={props.url}
        onSelect={items => {
          props.onSelect(items[0]);
          setShowSelector(false);
        }}
      />
    </React.Fragment>
  );
}
