import React from "react";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { RouteChildrenProps } from "react-router";
import { OrderListContent } from "../../components/OrderListContent";

export function OrderList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Typography color="textPrimary">Orders</Typography>
      </Breadcrumbs>
      <Paper>
        <OrderListContent />
      </Paper>
    </Layout>
  );
}
