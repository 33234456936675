import React from "react";
import { Media } from "../types/Media";
import { CSSProperties } from "styled-components";
import noImage from "../static/images/noimage.jpg";
import { getImageUrl } from "../helpers/getImageUrl";
import { isImage } from "../helpers/utils";

export function Image(props: {
  file: Media | null;
  style?: CSSProperties | undefined;
  width?: number;
}) {
  const mimeType = props.file ? props.file.mime_type : "image/jpeg";
  const src = props.file ? getImageUrl(props.file, props.width) : noImage;

  if (!isImage(mimeType)) {
    return null;
  }

  return (
    <img
      style={{
        maxWidth: "100%",
        ...props.style,
      }}
      src={src}
      alt={props.file ? props.file.upload_name : "Image"}
    />
  );
}
