import CountryRegionData from "country-region-data/data.json";

export function getStateList(countryShortCode: string) {
  const country = CountryRegionData.find(
    country => country.countryShortCode === countryShortCode
  );

  if (country) {
    return country.regions.map(region => {
      return {
        value: region.shortCode || region.name,
        label: region.name
      };
    });
  }

  return [];
}
