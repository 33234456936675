import { EstimateItem } from "../types/EstimateItem";
import { ProductOption } from "../types/ProductOption";
import { Settings } from "../types/Settings";
import { ProductSize, Product, ProductPricing } from "../types/Product";
import { sanitizePrice, roundToPrecision } from "./utils";

type LineItem = Pick<EstimateItem, "product" | "size" | "options">;

/**
 * Returns Option Size properties based on selected Print Size.
 *
 * @param size
 * @param option
 */
export function getOptionSize(
  pricing: ProductPricing,
  size: ProductSize,
  option: ProductOption
) {
  const biggestSide = Math.max(size.width, size.height);
  const sizes = pricing === "SQUARE" ? option.square_sizes : option.sizes;

  return sizes.find((size) => {
    return size.from_size <= biggestSide && biggestSide <= size.to_size;
  });
}

/**
 * Returns number of pieces based on product type
 *
 * @param product
 */
export function getPiecesNumber(
  product: Pick<Product, "pairing_type" | "pairing_products">
) {
  const { pairing_type, pairing_products } = product;

  if (pairing_type === "DIPTYCH") {
    return 2;
  }

  if (pairing_type === "TRIPTYCH") {
    return 3;
  }

  if (pairing_type === "QUAD") {
    return 4;
  }

  if (pairing_type === "BUNDLE") {
    return pairing_products.length;
  }

  return 1;
}

/**
 * Returns number of pieces per row. Used in product previews
 *
 * @param product
 */
export function getPiecePerRowNumber(
  product: Pick<Product, "pairing_type" | "pairing_products">
) {
  const { pairing_type, pairing_products } = product;

  if (pairing_type === "DIPTYCH") {
    return 2;
  }

  if (pairing_type === "TRIPTYCH") {
    return 3;
  }

  if (pairing_type === "QUAD") {
    return 2;
  }

  if (pairing_type === "BUNDLE") {
    return pairing_products.length;
  }

  return 1;
}

/**
 * Calculates Product Option Discount. Global discount takes over of locally specified.
 *
 * @param printSize
 * @param option
 * @param chosenOptions
 * @param settings
 */
export function calculateOptionDiscount(
  pricing: ProductPricing,
  printSize: ProductSize,
  option: ProductOption,
  chosenOptions: ProductOption[],
  settings: Settings
) {
  const price = calculateOptionPrice(pricing, printSize, option, chosenOptions);
  const localDiscount = option.discount_percentage;
  const globalDiscount = Number(settings.global_discount);
  const discountPercentage = globalDiscount ? globalDiscount : localDiscount;
  const discount = (price * discountPercentage) / 100;

  if (discount < 0) {
    return 0;
  }

  if (discount > price) {
    return price;
  }

  return discount;
}

/**
 * Returns size sum of option's price components
 *
 * @param printSize
 * @param option
 * @param chosenOptions
 */
export function calculatePriceComponentsSize(
  pricing: ProductPricing,
  printSize: ProductSize,
  option: ProductOption,
  chosenOptions: ProductOption[]
) {
  const priceComponents = option.price_components;
  const initialSize = priceComponents.includes("PRINT")
    ? {
        width: printSize.width,
        height: printSize.height,
      }
    : {
        width: 0,
        height: 0,
      };

  return chosenOptions.reduce((size, option) => {
    if (option.type !== "PLEXI" && priceComponents.includes(option.type)) {
      const optionSize = getOptionSize(pricing, printSize, option);

      if (optionSize) {
        return {
          width: size.width + optionSize.width * 2,
          height: size.height + optionSize.height * 2,
        };
      }
    }

    return size;
  }, initialSize);
}

/**
 * Calculates product option price based on print size and other selected options
 *
 * @param printSize
 * @param option
 * @param chosenOptions
 */
export function calculateOptionPrice(
  pricing: ProductPricing,
  printSize: ProductSize,
  option: ProductOption,
  chosenOptions: ProductOption[]
) {
  const optionSize = getOptionSize(pricing, printSize, option);

  if (!optionSize) return 0;

  const price = parseFloat(optionSize.price);

  if (!option.price_per_inch) return price;

  const pricingSize = calculatePriceComponentsSize(
    pricing,
    printSize,
    option,
    chosenOptions
  );

  const biggestSide = Math.max(pricingSize.width, pricingSize.height);

  const newPrice = biggestSide * price;

  return roundToPrecision(newPrice, 5);
}

/**
 * Calculates size of the print + all chosen options (border, frame, etc)
 *
 * @param printSize
 * @param options
 */
export function calculateSize(
  pricing: ProductPricing,
  printSize: ProductSize,
  options: ProductOption[]
) {
  return options.reduce(
    (calculated, option) => {
      const optionSize = getOptionSize(pricing, printSize, option);

      if (!optionSize) {
        return calculated;
      }

      return {
        width: calculated.width + 2 * optionSize.width,
        height: calculated.height + 2 * optionSize.height,
      };
    },
    {
      width: printSize.width,
      height: printSize.height,
    }
  );
}

/**
 * Calculates total
 *
 * @param subtotal
 * @param discount
 */
export function calculateTotal(subtotal: number, discount: number) {
  const total = subtotal - discount;

  if (total > 0) {
    return total;
  }

  return 0;
}

/**
 * Calculates Order Item details
 *
 * @param item
 * @param settings
 */
export function calculateOrderItem(item: LineItem, settings: Settings) {
  const { options, size, product } = item;

  // If size is not selected
  if (!size) {
    const subtotal = parseFloat(item.product.price || "0.00");
    const discount = 0;
    const total = calculateTotal(subtotal, discount);

    return {
      subtotal: sanitizePrice(subtotal),
      discount: sanitizePrice(discount),
      total: sanitizePrice(total),
      gap: 0,
      width: 0,
      height: 0,
    };
  }

  const itemSize = calculateSize(product.pricing, size, options);
  const pieces = getPiecesNumber(item.product);
  const piecesPerRow = getPiecePerRowNumber(item.product);
  const piecesPerColumn = pieces / piecesPerRow;

  // Calculates Order Item Price: print price + all options price
  const itemPrice = options.reduce(
    (calculated, option) => {
      const optionPrice = calculateOptionPrice(
        product.pricing,
        size,
        option,
        options
      );
      const optionDiscount = calculateOptionDiscount(
        product.pricing,
        size,
        option,
        options,
        settings
      );

      // Since prints can include multiple pieces like (dyptich, triptych, etc) - we need to multiply option price by number of pieces
      const subtotal = pieces * optionPrice;
      const discount = pieces * optionDiscount;
      const total = calculateTotal(subtotal, discount);

      return {
        subtotal: calculated.subtotal + subtotal,
        discount: calculated.discount + discount,
        total: calculated.total + total,
      };
    },
    {
      subtotal: parseFloat(size.price),
      discount: 0,
      total: parseFloat(size.price),
    }
  );

  // Gap for Bundle items is different from dyptich, triptych, etc
  const gap = product.pairing_type === "BUNDLE" ? 3 : 1;
  const horizontalGap = (piecesPerRow - 1) * gap;
  const verticalGap = (piecesPerColumn - 1) * gap;

  return {
    subtotal: sanitizePrice(itemPrice.subtotal),
    gap: gap,
    discount: sanitizePrice(itemPrice.discount),
    total: sanitizePrice(itemPrice.total),
    width: itemSize.width * piecesPerRow + horizontalGap,
    height: itemSize.height * piecesPerColumn + verticalGap,
    individualWidth: itemSize.width,
    individualHeight: itemSize.height,
  };
}

/**
 * Checks if product option is available based on other selected options
 *
 * @param option
 * @param selected
 */
export function isOptionAvailable(
  option: ProductOption,
  selected: ProductOption[]
) {
  if (option.requires && option.requires.length) {
    const hasAllRequirements = option.requires.reduce(
      (hasAll, requirementId) => {
        const requirement = selected.find(
          (option) => option.id === requirementId
        );

        if (requirement) {
          return true;
        }

        return hasAll;
      },
      false
    );

    return hasAllRequirements;
  }

  return true;
}

/**
 * Order of Product Option Categories (Types)
 */
export const OptionTypeOrder: {
  [key: string]: number;
} = {
  FRAME: -3,
  BORDER: -2,
  PLEXI: -1,
  DEFAULT: 0,
};
