import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { ProductOptionForm } from "../../forms/ProductOptionForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateProductOption } from "../../actions/product-option/updateProductOption";
import { deleteProductOption } from "../../actions/product-option/deleteProductOption";
import { useDetail } from "../../useAPI";
import { ProductOption } from "../../types/ProductOption";

export function ProductOptionEdit(
  props: RouteChildrenProps<{
    productOptionId: string;
  }>
) {
  const productOptionId = props.match!.params.productOptionId;
  const { data, error } = useDetail<ProductOption>(
    "product-options",
    productOptionId
  );
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/product-options"}>Product Options</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <ProductOptionForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateProductOption(productOptionId, values);
          }}
          onDelete={async () => {
            await deleteProductOption(productOptionId);

            props.history.push("/product-options");
          }}
        />
      )}
    </Layout>
  );
}
