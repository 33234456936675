import { authorised } from "../../request";
import { TaxRate } from "../../types/TaxRate";

export async function deleteTaxRate(id: string) {
  const API = authorised();

  return await API.request<TaxRate>({
    method: "DELETE",
    url: `/api/admin/tax-rates/${id}/`
  });
}
