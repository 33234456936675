import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { MenuForm } from "../../forms/MenuForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateMenu } from "../../actions/menu/updateMenu";
import { deleteMenu } from "../../actions/menu/deleteMenu";
import { useDetail } from "../../useAPI";
import { Menu } from "../../types/Menu";
import { createMenu } from "../../actions/menu/createMenu";

export function MenuEdit(
  props: RouteChildrenProps<{
    menuId: string;
  }>
) {
  const menuId = props.match!.params.menuId;
  const { data, error } = useDetail<Menu>("menus", menuId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/menus"}>Menus</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <MenuForm
          type="update"
          key={data.id}
          initialValues={data}
          onSubmit={async (values) => {
            const newValues = {
              ...values,
              items: values.items.map((item) => {
                const { copy_to_menu, ...rest } = item;
                return rest;
              }),
            };

            await updateMenu(menuId, newValues);
          }}
          onDuplicate={async (values) => {
            const response = await createMenu({
              ...values,
              identifier: `${values.identifier}-2`,
            });

            props.history.push(`/menus/${response.data.id}`);
          }}
          onDelete={async () => {
            await deleteMenu(menuId);

            props.history.push("/menus");
          }}
        />
      )}
    </Layout>
  );
}
