import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PressForm } from "../../forms/PressForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createPress } from "../../actions/press/createPress";
import { RouteChildrenProps } from "react-router";

export function PressCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/press"}>Press</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <PressForm
        type="create"
        onSubmit={async values => {
          const response = await createPress(values);

          props.history.push(`/press/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
