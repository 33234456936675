import { authorised } from "../../request";
import { Estimate } from "../../types/Estimate";

export async function deleteEstimate(id: string) {
  const API = authorised();

  return await API.request<Estimate>({
    method: "DELETE",
    url: `/api/admin/estimates/${id}/`
  });
}
