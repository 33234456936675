import { authorised } from "../../request";
import { Award } from "../../types/Award";

export async function updateAward(id: string, data: Omit<Award, "id">) {
  const API = authorised();

  return await API.request<Award>({
    method: "PATCH",
    url: `/api/admin/awards/${id}/`,
    data
  });
}
