import React, { useState } from "react";
import * as Formik from "formik";
import { TextField } from "./fields/TextField";
import * as Yup from "yup";
import { Column } from "../helpers/layout";
import { ImageField } from "./fields/ImageField";
import { Product } from "../types/Product";

export type FormValues = Pick<
  Product,
  "name" | "price" | "featured_image" | "estimate_description"
>;

export function CustomLineItemForm(props: {
  formRef: React.MutableRefObject<any>;
  initialValue: FormValues;
  onSubmit: (values: FormValues) => void;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_error, setError] = useState<string | null>(null);

  return (
    <Formik.Formik<FormValues>
      ref={props.formRef}
      initialValues={props.initialValue}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        estimate_description: Yup.string(),
        price: Yup.number().required(),
      })}
      onSubmit={async (values: any, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as any;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {() => {
        return (
          <Formik.Form>
            <Column>
              <div>
                <ImageField name={"featured_image"} label="Image" />
              </div>
              <div>
                <Formik.FastField
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  component={TextField}
                  variant="outlined"
                />
              </div>
              <div>
                <Formik.FastField
                  name="estimate_description"
                  label="Description"
                  type="text"
                  multiline
                  fullWidth
                  rows="4"
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div>
                <Formik.FastField
                  name="price"
                  label="Price"
                  type="number"
                  fullWidth
                  component={TextField}
                  variant="outlined"
                />
              </div>
            </Column>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
