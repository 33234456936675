import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { OrderForm } from "../../forms/OrderForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateOrder } from "../../actions/order/updateOrder";
import { useDetail } from "../../useAPI";
import { Order } from "../../types/Order";

export function OrderEdit(
  props: RouteChildrenProps<{
    orderId: string;
  }>
) {
  const orderId = props.match!.params.orderId;
  const { data, error } = useDetail<Order>("orders", orderId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/orders"}>Orders</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <OrderForm
          type="update"
          order={data}
          initialValues={{
            status: data.status
          }}
          onSubmit={async values => {
            await updateOrder(orderId, values);
          }}
        />
      )}
    </Layout>
  );
}
