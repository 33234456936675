import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Estimate } from "../types/Estimate";
import NumberFormat from "react-number-format";
import EstimateItemsField from "./fields/EstimateItemsField";
import { EstimateItem } from "../types/EstimateItem";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import FileCopy from "@material-ui/icons/FileCopy";
import ShareIcon from "@material-ui/icons/Share";
import Chip from "@material-ui/core/Chip";
import { getFrontendUrl } from "../helpers/router";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { DiscountField } from "./fields/DiscountField";
import { AxiosError } from "axios";

type FormValues = Pick<
  Estimate,
  "name" | "status" | "items" | "custom_discount" | "subtotal" | "total"
>;
type Callback = () => void;

function calculateTotals(values: {
  items: EstimateItem[];
  custom_discount: string;
}) {
  const totals = values.items.reduce(
    (totals, item) => {
      return {
        subtotal: totals.subtotal + parseFloat(item.subtotal),
        discount: totals.discount + parseFloat(item.discount),
      };
    },
    {
      subtotal: 0,
      discount: 0,
    }
  );

  return totals;
}

export function EstimateForm(props: {
  type: "create" | "update";
  urlhash?: string;
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDuplicate?: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);
  const history = useHistory();

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          status: "PENDING",
          custom_discount: "0",
          subtotal: "0",
          total: "0",
          items: [],
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        status: Yup.string().required(),
        subtotal: Yup.number(),
        custom_discount: Yup.number()
          .required()
          .when("items", (items: EstimateItem[], schema: any) => {
            return schema.test(
              "should-be-less-than-subtotal",
              "Should be less than products total",
              (value: number) => {
                const discount = value || 0;
                const totals = calculateTotals({
                  items,
                  custom_discount: discount.toString(),
                });

                const productsTotal = totals.subtotal;

                return discount <= productsTotal;
              }
            );
          }),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => {
        const totals = calculateTotals(values);
        const customDiscount = Number(values.custom_discount);
        const totalDiscount =
          customDiscount > totals.discount ? customDiscount : totals.discount;
        const total =
          totals.subtotal - totalDiscount > 0
            ? totals.subtotal - totalDiscount
            : 0;

        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  {props.type === "update" && (
                    <Paper>
                      <Row justify="space-between" align="center">
                        <div
                          css={css`
                            flex: 0 0 auto;
                          `}
                        >
                          <ShareIcon />
                        </div>
                        {props.urlhash && (
                          <div
                            css={css`
                              flex: 1 0 20%;
                            `}
                          >
                            <input
                              css={css`
                                height: 30px;
                                width: 100%;
                                max-width: 450px;
                                border: none;
                              `}
                              type="text"
                              value={getFrontendUrl(
                                `estimate/${props.urlhash}/`
                              )}
                            />
                          </div>
                        )}
                        <div
                          css={css`
                            flex: 0 0 auto;
                          `}
                        >
                          <Chip label={values.status} />
                        </div>
                      </Row>
                    </Paper>
                  )}
                  <Paper>
                    <Formik.FastField
                      name="name"
                      label="Name"
                      type="text"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />
                  </Paper>
                  <Paper>
                    <EstimateItemsField
                      name="items"
                      label="Items"
                      disabled={values.status !== "PENDING"}
                    />
                  </Paper>
                  <Paper>
                    <Row justify="space-between">
                      <div>
                        {values.status === "PENDING" && (
                          <DiscountField
                            name={"custom_discount"}
                            label="Discount"
                            fullWidth
                            subtotal={totals.subtotal}
                          />
                        )}
                      </div>
                      <div
                        css={css`
                          text-align: right;
                        `}
                      >
                        <p>
                          Subtotal:{" "}
                          <NumberFormat
                            value={totals.subtotal}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </p>
                        <p>
                          Discount:{" "}
                          <NumberFormat
                            value={totalDiscount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </p>
                        <p>
                          <strong>
                            Total:{" "}
                            <NumberFormat
                              value={total}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </strong>
                        </p>
                      </div>
                    </Row>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Paper>
                  <Column>
                    <Row justify="flex-end">
                      {props.onDuplicate && (
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            props.onDuplicate!(values);
                          }}
                        >
                          <FileCopy />
                        </Button>
                      )}
                      {values.status === "PENDING" ? (
                        <React.Fragment>
                          {props.onDelete && (
                            <div>
                              <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setConfirmAction(() => {
                                    return props.onDelete;
                                  });
                                }}
                              >
                                Delete
                              </Button>
                              {confirmAction !== null && (
                                <ConfirmModal
                                  confirmAction={confirmAction}
                                  handleClose={() => {
                                    setConfirmAction(null);
                                  }}
                                  open={true}
                                  label="Please confirm deletion"
                                />
                              )}
                            </div>
                          )}
                          <div>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <CircularProgress size={25} />
                              ) : props.type === "update" ? (
                                "Update"
                              ) : (
                                "Create"
                              )}
                            </Button>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div>
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              history.push(`/estimates`);
                            }}
                          >
                            Continue
                          </Button>
                        </div>
                      )}
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
