import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { MenuForm } from "../../forms/MenuForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createMenu } from "../../actions/menu/createMenu";
import { RouteChildrenProps } from "react-router";

export function MenuCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/menus"}>Menus</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <MenuForm
        type="create"
        onSubmit={async values => {
          const response = await createMenu(values);

          props.history.push(`/menus/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
