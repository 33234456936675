import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PresetForm } from "../../forms/PresetForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createPreset } from "../../actions/preset/createPreset";
import { RouteChildrenProps } from "react-router";

export function PresetCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/presets"}>Presets</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <PresetForm
        type="create"
        onSubmit={async values => {
          const response = await createPreset({
            name: values.name,
            sizes: values.sizes.map(s => {
              return {
                width: parseFloat(s.width),
                height: parseFloat(s.height),
                price: s.price
              };
            })
          });

          props.history.push(`/presets/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
