import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./helpers/router";
import { Error } from "./pages/Error";
import { Dashboard } from "./pages/Dashboard";

import { PageCreate } from "./pages/page/PageCreate";
import { PageList } from "./pages/page/PageList";
import { PageEdit } from "./pages/page/PageEdit";

import { PostCreate } from "./pages/post/PostCreate";
import { PostList } from "./pages/post/PostList";
import { PostEdit } from "./pages/post/PostEdit";

import { PostTagCreate } from "./pages/post-tag/PostTagCreate";
import { PostTagList } from "./pages/post-tag/PostTagList";
import { PostTagEdit } from "./pages/post-tag/PostTagEdit";

import { SerieCreate } from "./pages/serie/SerieCreate";
import { SerieList } from "./pages/serie/SerieList";
import { SerieEditConfiguration } from "./pages/serie/SerieEditConfiguration";
import { SerieEditLanding } from "./pages/serie/SerieEditLanding";
import { SerieEditProductInformation } from "./pages/serie/SerieEditProductInformation";

import { InHomeCreate } from "./pages/in-home/InHomeCreate";
import { InHomeList } from "./pages/in-home/InHomeList";
import { InHomeEdit } from "./pages/in-home/InHomeEdit";

import { AwardCreate } from "./pages/award/AwardCreate";
import { AwardList } from "./pages/award/AwardList";
import { AwardEdit } from "./pages/award/AwardEdit";

import { RewarderCreate } from "./pages/rewarder/RewarderCreate";
import { RewarderList } from "./pages/rewarder/RewarderList";
import { RewarderEdit } from "./pages/rewarder/RewarderEdit";

import { MenuCreate } from "./pages/menu/MenuCreate";
import { MenuList } from "./pages/menu/MenuList";
import { MenuEdit } from "./pages/menu/MenuEdit";

import { PressCreate } from "./pages/press/PressCreate";
import { PressList } from "./pages/press/PressList";
import { PressEdit } from "./pages/press/PressEdit";

import { ProductCreate } from "./pages/product/ProductCreate";
import { ProductList } from "./pages/product/ProductList";
import { ProductEdit } from "./pages/product/ProductEdit";

import { ProductOptionCreate } from "./pages/product-option/ProductOptionCreate";
import { ProductOptionList } from "./pages/product-option/ProductOptionList";
import { ProductOptionEdit } from "./pages/product-option/ProductOptionEdit";

import { OrderList } from "./pages/order/OrderList";
import { OrderEdit } from "./pages/order/OrderEdit";

import { PresetCreate } from "./pages/preset/PresetCreate";
import { PresetList } from "./pages/preset/PresetList";
import { PresetEdit } from "./pages/preset/PresetEdit";

import { FilmCreate } from "./pages/film/FilmCreate";
import { FilmList } from "./pages/film/FilmList";
import { FilmEdit } from "./pages/film/FilmEdit";

import { EstimateCreate } from "./pages/estimate/EstimateCreate";
import { EstimateList } from "./pages/estimate/EstimateList";
import { EstimateEdit } from "./pages/estimate/EstimateEdit";

import { HelpTextCreate } from "./pages/help-text/HelpTextCreate";
import { HelpTextList } from "./pages/help-text/HelpTextList";
import { HelpTextEdit } from "./pages/help-text/HelpTextEdit";

import { AnnouncementCreate } from "./pages/announcement/AnnouncementCreate";
import { AnnouncementList } from "./pages/announcement/AnnouncementList";
import { AnnouncementEdit } from "./pages/announcement/AnnouncementEdit";

import { ProductTagCreate } from "./pages/product-tag/ProductTagCreate";
import { ProductTagList } from "./pages/product-tag/ProductTagList";
import { ProductTagEdit } from "./pages/product-tag/ProductTagEdit";

import { InHomeTagCreate } from "./pages/in-home-tag/InHomeTagCreate";
import { InHomeTagList } from "./pages/in-home-tag/InHomeTagList";
import { InHomeTagEdit } from "./pages/in-home-tag/InHomeTagEdit";

import { TaxRateCreate } from "./pages/tax-rate/TaxRateCreate";
import { TaxRateList } from "./pages/tax-rate/TaxRateList";
import { TaxRateEdit } from "./pages/tax-rate/TaxRateEdit";

import { SettingList } from "./pages/setting/SettingList";
import { SettingEdit } from "./pages/setting/SettingEdit";

import { UserList } from "./pages/user/UserList";
import { UserEdit } from "./pages/user/UserEdit";

import { WishlistList } from "./pages/wishlist/WishlistList";
import { WishlistEdit } from "./pages/wishlist/WishlistEdit";

import { Login } from "./pages/Login";
import { Other } from "./pages/Other";
import { SerieEditShopConfiguration } from "./pages/serie/SeriedEditShopConfiguration";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <PrivateRoute path="/dashboard" component={Dashboard} />

        <PrivateRoute path="/pages/create" component={PageCreate} />
        <PrivateRoute path="/pages/:pageId" component={PageEdit} />
        <PrivateRoute path="/pages" component={PageList} />

        <PrivateRoute path="/posts/create" component={PostCreate} />
        <PrivateRoute path="/posts/:postId" component={PostEdit} />
        <PrivateRoute path="/posts" component={PostList} />

        <PrivateRoute path="/post-tags/create" component={PostTagCreate} />
        <PrivateRoute path="/post-tags/:postTagId" component={PostTagEdit} />
        <PrivateRoute path="/post-tags" component={PostTagList} />

        <PrivateRoute path="/series/create" component={SerieCreate} />
        <PrivateRoute
          path="/series/:serieId"
          component={SerieEditConfiguration}
          exact
        />
        <PrivateRoute
          path="/series/:serieId/landing"
          component={SerieEditLanding}
          exact
        />
        <PrivateRoute
          path="/series/:serieId/product-information"
          component={SerieEditProductInformation}
          exact
        />
        <PrivateRoute
          path="/series/:serieId/shop-configuration"
          component={SerieEditShopConfiguration}
          exact
        />
        <PrivateRoute path="/series" component={SerieList} />

        <PrivateRoute path="/in-homes/create" component={InHomeCreate} />
        <PrivateRoute path="/in-homes/:inHomeId" component={InHomeEdit} />
        <PrivateRoute path="/in-homes" component={InHomeList} />

        <PrivateRoute path="/awards/create" component={AwardCreate} />
        <PrivateRoute path="/awards/:awardId" component={AwardEdit} />
        <PrivateRoute path="/awards" component={AwardList} />

        <PrivateRoute path="/rewarders/create" component={RewarderCreate} />
        <PrivateRoute path="/rewarders/:rewarderId" component={RewarderEdit} />
        <PrivateRoute path="/rewarders" component={RewarderList} />

        <PrivateRoute path="/menus/create" component={MenuCreate} />
        <PrivateRoute path="/menus/:menuId" component={MenuEdit} />
        <PrivateRoute path="/menus" component={MenuList} />

        <PrivateRoute path="/press/create" component={PressCreate} />
        <PrivateRoute path="/press/:pressId" component={PressEdit} />
        <PrivateRoute path="/press" component={PressList} />

        <PrivateRoute path="/products/create" component={ProductCreate} />
        <PrivateRoute path="/products/:productId" component={ProductEdit} />
        <PrivateRoute path="/products" component={ProductList} />

        <PrivateRoute
          path="/product-options/create"
          component={ProductOptionCreate}
        />
        <PrivateRoute
          path="/product-options/:productOptionId"
          component={ProductOptionEdit}
        />
        <PrivateRoute path="/product-options" component={ProductOptionList} />

        <PrivateRoute path="/orders/:orderId" component={OrderEdit} />
        <PrivateRoute path="/orders" component={OrderList} />

        <PrivateRoute path="/presets/create" component={PresetCreate} />
        <PrivateRoute path="/presets/:presetId" component={PresetEdit} />
        <PrivateRoute path="/presets" component={PresetList} />

        <PrivateRoute path="/films/create" component={FilmCreate} />
        <PrivateRoute path="/films/:filmId" component={FilmEdit} />
        <PrivateRoute path="/films" component={FilmList} />

        <PrivateRoute path="/help-texts/create" component={HelpTextCreate} />
        <PrivateRoute path="/help-texts/:helpTextId" component={HelpTextEdit} />
        <PrivateRoute path="/help-texts" component={HelpTextList} />

        <PrivateRoute
          path="/announcements/create"
          component={AnnouncementCreate}
        />
        <PrivateRoute
          path="/announcements/:announcementId"
          component={AnnouncementEdit}
        />
        <PrivateRoute path="/announcements" component={AnnouncementList} />

        <PrivateRoute path="/tax-rates/create" component={TaxRateCreate} />
        <PrivateRoute path="/tax-rates/:taxRateId" component={TaxRateEdit} />
        <PrivateRoute path="/tax-rates" component={TaxRateList} />

        <PrivateRoute
          path="/product-tags/create"
          component={ProductTagCreate}
        />
        <PrivateRoute
          path="/product-tags/:productTagId"
          component={ProductTagEdit}
        />
        <PrivateRoute path="/product-tags" component={ProductTagList} />

        <PrivateRoute path="/in-home-tags/create" component={InHomeTagCreate} />
        <PrivateRoute
          path="/in-home-tags/:inHomeTagId"
          component={InHomeTagEdit}
        />
        <PrivateRoute path="/in-home-tags" component={InHomeTagList} />

        <PrivateRoute path="/estimates/create" component={EstimateCreate} />
        <PrivateRoute path="/estimates/:estimateId" component={EstimateEdit} />
        <PrivateRoute path="/estimates" component={EstimateList} />

        <PrivateRoute path="/users/:userId" component={UserEdit} />
        <PrivateRoute path="/users" component={UserList} />

        <PrivateRoute path="/wishlists/:wishlistId" component={WishlistEdit} />
        <PrivateRoute path="/wishlists" component={WishlistList} />

        <PrivateRoute path="/settings/:settingId" component={SettingEdit} />
        <PrivateRoute path="/settings" component={SettingList} />

        <PrivateRoute path="/other" component={Other} />

        <PublicRoute path="/login" component={Login} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );
}
