import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { TaxRate } from "../types/TaxRate";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { SelectField } from "./fields/SelectField";
import CountryRegionData from "country-region-data/data.json";
import { getStateList } from "../helpers/location";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<TaxRate, "country" | "state" | "rate">;
type Callback = () => void;

export function TaxRateForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          country: "",
          state: "",
          rate: "0.00",
        }
      }
      validationSchema={Yup.object().shape({
        country: Yup.string().required(),
        state: Yup.string().required(),
        rate: Yup.number().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => {
        const states = getStateList(values.country);

        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <SelectField
                        label="Country"
                        name="country"
                        options={CountryRegionData.map((option) => ({
                          value: option.countryShortCode || option.countryName,
                          label: option.countryName,
                        }))}
                      />
                      <SelectField
                        label={"State"}
                        name="state"
                        options={states}
                      />
                      <Formik.FastField
                        name="rate"
                        label="Rate (%)"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                    </Column>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Row justify="flex-end">
                        {props.onDelete && (
                          <div>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                setConfirmAction(() => {
                                  return props.onDelete;
                                });
                              }}
                            >
                              Delete
                            </Button>
                            {confirmAction !== null && (
                              <ConfirmModal
                                confirmAction={confirmAction}
                                handleClose={() => {
                                  setConfirmAction(null);
                                }}
                                open={true}
                                label="Please confirm deletion"
                              />
                            )}
                          </div>
                        )}
                        <div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={25} />
                            ) : props.type === "update" ? (
                              "Update"
                            ) : (
                              "Create"
                            )}
                          </Button>
                        </div>
                      </Row>
                      {error && <Error text={error} />}
                    </Column>
                  </Paper>
                </Column>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
