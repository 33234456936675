import { authorised } from "../../request";
import { InHome } from "../../types/InHome";

export async function updateInHome(id: string, data: Omit<InHome, "id">) {
  const API = authorised();

  return await API.request<InHome>({
    method: "PATCH",
    url: `/api/admin/in-homes/${id}/`,
    data
  });
}
