import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Fab from "@material-ui/core/Fab";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Visibility } from "../../components/Visibility";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { Serie } from "../../types/Serie";
import { Row } from "../../helpers/layout";
import { RouteChildrenProps } from "react-router";
import { Image } from "../../components/Image";
import { getFrontendUrl } from "../../helpers/router";
import { cloneSerie } from "../../actions/serie/cloneSerie";
import { Tooltip } from "@material-ui/core";

export function SerieList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/series/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Series</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Serie>
          url="series"
          head={[
            { label: "Featured Image", width: "150px" },
            { label: "Name" },
            { label: "Slug" },
            { label: "Description" },
            { label: "Author" },
            { label: "Visibility", width: "130px" },
            { label: "Actions", align: "right" },
          ]}
        >
          {(result) => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  <Image
                    file={result.featured_image}
                    style={{
                      width: "100%",
                    }}
                    width={150}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell className="pl-3 fw-normal">{result.slug}</TableCell>
                <TableCell>{result.description}</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>
                  <Visibility type={result.visibility} />
                </TableCell>
                <TableCell align="right">
                  <Row align="center">
                    <Tooltip title="Duplicate Serie">
                      <span
                        onClick={async () => {
                          const clone = await cloneSerie(result.id);
                          props.history.push(`/series/${clone.data.id}`);
                        }}
                      >
                        <Fab
                          color="secondary"
                          aria-label="duplicate"
                          size="small"
                        >
                          <FileCopyIcon />
                        </Fab>
                      </span>
                    </Tooltip>
                    <Tooltip title="View Serie">
                      <a
                        href={getFrontendUrl(`series/${result.slug}`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Fab color="secondary" aria-label="edit" size="small">
                          <OpenInNewIcon />
                        </Fab>
                      </a>
                    </Tooltip>
                    <Tooltip title="Edit Serie">
                      <Link to={`/series/${result.id}`}>
                        <Fab color="primary" aria-label="edit" size="small">
                          <EditIcon />
                        </Fab>
                      </Link>
                    </Tooltip>
                  </Row>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
