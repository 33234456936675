import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { AnnouncementForm } from "../../forms/AnnouncementForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createAnnouncement } from "../../actions/announcement/createAnnouncement";
import { RouteChildrenProps } from "react-router";
import { deserializeDate } from "../../helpers/utils";

export function AnnouncementCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/announcements"}>Announcements</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <AnnouncementForm
        type="create"
        onSubmit={async (values) => {
          let valuesData = {
            start_date: deserializeDate(values.start_date),
            end_date: deserializeDate(values.end_date),
            desktop_text: values.desktop_text,
            mobile_text: values.mobile_text,
            name: values.name,
            location: values.location,
          };
          const response = await createAnnouncement(valuesData);

          props.history.push(`/announcements/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
