import { authorised } from "../../request";
import { Menu } from "../../types/Menu";

export async function updateMenu(id: string, data: Omit<Menu, "id">) {
  const API = authorised();

  return await API.request<Menu>({
    method: "PATCH",
    url: `/api/admin/menus/${id}/`,
    data
  });
}
