import React from "react";
import { ProductSize } from "../types/Product";
import { Row } from "../helpers/layout";
import { css } from "styled-components/macro";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";

function sizesEqual(size1: ProductSize, size2: ProductSize) {
  return size1.width === size2.width && size1.height === size2.height;
}

function PrintSizePreview(props: {
  size: ProductSize;
  isSelected: boolean;
  onClick: () => void;
  isCustom?: boolean;
}) {
  const { size, isSelected } = props;
  const modifier = 2;

  return (
    <div
      onClick={() => {
        props.onClick();
      }}
      css={css`
        text-align: center;
      `}
    >
      <div
        css={css`
          position: relative;
          width: ${size.width * modifier}px;
          height: ${size.height * modifier}px;
          border: 2px solid #000;
          box-sizing: border-box;
          background: ${isSelected ? "#E6E6E6" : "transparent"};
        `}
      >
        {props.isCustom && (
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 20px;
              top: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              line-height: 20px;
            `}
          >
            custom
          </div>
        )}
      </div>
      <Typography variant="body2">
        {size.width}" x {size.height}"
      </Typography>
      <strong>
        <NumberFormat
          value={size.price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </strong>
    </div>
  );
}

export function PrintSizes(props: {
  sizes: ProductSize[];
  selected: ProductSize | null;
  onClick: (size: ProductSize) => void;
}) {
  if (!props.sizes.length) return <p>No available sizes</p>;

  const isCustomSize = props.sizes.reduce((isCustom, size) => {
    if (props.selected && sizesEqual(props.selected, size)) {
      return false;
    }

    return isCustom;
  }, true);

  return (
    <Row wrap="wrap">
      {props.sizes.map((size, i) => {
        const isSelected = props.selected
          ? sizesEqual(props.selected, size)
          : false;

        return (
          <PrintSizePreview
            isSelected={isSelected}
            size={size}
            onClick={() => {
              props.onClick(size);
            }}
            key={i}
          />
        );
      })}
      {isCustomSize && props.selected && (
        <PrintSizePreview
          isSelected={true}
          size={props.selected}
          onClick={() => {}}
          isCustom={true}
        />
      )}
    </Row>
  );
}
