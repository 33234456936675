import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { CustomLineItemForm, FormValues } from "../forms/CustomLineItemForm";
import { Formik } from "formik";

export function CustomLineItemModal(props: {
  open: boolean;
  handleClose: () => void;
  initialValue: FormValues;
  onSubmit: (item: FormValues) => void;
}) {
  const formRef = useRef<Formik>(null);
  const { initialValue } = props;

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Custom Line Item</DialogTitle>
      <DialogContent>
        <CustomLineItemForm
          formRef={formRef}
          initialValue={initialValue}
          onSubmit={props.onSubmit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!formRef || !formRef.current) return;

            formRef.current.submitForm();
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
