import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Press } from "../types/Press";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { SelectField } from "./fields/SelectField";
import { ImageField } from "./fields/ImageField";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { FileField } from "./fields/FileField";
import { AxiosError } from "axios";

type FormValues = Pick<
  Press,
  "name" | "link" | "image" | "file" | "type" | "cover" | "logo"
>;
type Callback = () => void;

export function PressForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          type: "LINK",
          cover: null,
          logo: null,
          link: "",
          image: null,
          file: null,
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        link: Yup.string().when("type", {
          is: "LINK",
          then: Yup.string().url().required(),
        }),
        image: Yup.object().nullable().when("type", {
          is: "IMAGE",
          then: Yup.object().required(),
        }),
        file: Yup.object().nullable().when("type", {
          is: "FILE",
          then: Yup.object().required(),
        }),
        cover: Yup.object().required(),
        logo: Yup.object().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, errors }) => {
        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Formik.FastField
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                      <SelectField
                        label="Type"
                        name="type"
                        options={[
                          {
                            value: "LINK",
                            label: "Link",
                          },
                          {
                            value: "FILE",
                            label: "File",
                          },
                          {
                            value: "IMAGE",
                            label: "Image",
                          },
                        ]}
                      />
                      {values.type === "LINK" && (
                        <Formik.FastField
                          name="link"
                          label="Link"
                          type="url"
                          fullWidth
                          component={TextField}
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                      {values.type === "IMAGE" && (
                        <ImageField name="image" label="Image" />
                      )}
                      {values.type === "FILE" && (
                        <FileField name="file" label="File" />
                      )}
                    </Column>
                  </Paper>
                  <Paper>
                    <Row>
                      <div
                        css={css`
                          flex: 1 0 40%;
                        `}
                      >
                        <ImageField name="cover" label="Cover" />
                      </div>
                      <div
                        css={css`
                          flex: 1 0 40%;
                        `}
                      >
                        <ImageField name="logo" label="Logo" />
                      </div>
                    </Row>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Paper>
                  <Column>
                    <Row justify="flex-end">
                      {props.onDelete && (
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setConfirmAction(() => {
                                return props.onDelete;
                              });
                            }}
                          >
                            Delete
                          </Button>
                          {confirmAction !== null && (
                            <ConfirmModal
                              confirmAction={confirmAction}
                              handleClose={() => {
                                setConfirmAction(null);
                              }}
                              open={true}
                              label="Please confirm deletion"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : props.type === "update" ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </div>
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
