import React from "react";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Layout } from "../components/Layout";
import { Column, Spacing } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { OrderListContent } from "../components/OrderListContent";
import { EstimateListContent } from "../components/EstimateListContent";

function Widget(props: { children: React.ReactNode | React.ReactNode[] }) {
  return (
    <div
      css={css`
        flex: 1 0 300px;
      `}
    >
      <Paper>{props.children}</Paper>
    </div>
  );
}

export function Dashboard(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Column gutter={Spacing.xl}>
        <Typography variant="h1" color="primary">
          Dashboard
        </Typography>
        <div>
          <Widget>
            <Breadcrumbs>
              <Typography variant="h4" color="primary">
                Latest orders
              </Typography>
            </Breadcrumbs>
            <OrderListContent showMax={5} />
          </Widget>
        </div>
        <div>
          <Widget>
            <Breadcrumbs>
              <Typography variant="h4" color="primary">
                Estimates
              </Typography>
            </Breadcrumbs>
            <EstimateListContent showMax={5} />
          </Widget>
        </div>
      </Column>
    </Layout>
  );
}
