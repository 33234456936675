import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ProductSize } from "../types/Product";
import { PrintSizeForm } from "../forms/PrintSizeForm";
import { Formik } from "formik";

export function SizeConfigurator(props: {
  open: boolean;
  initialValue: ProductSize;
  sizes: ProductSize[];
  handleClose: () => void;
  onSubmit: (size: ProductSize) => void;
}) {
  const formRef = useRef<Formik>(null);
  const { sizes, initialValue } = props;

  if (!sizes.length) return null;

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Size Configurator</DialogTitle>
      <DialogContent>
        <PrintSizeForm
          formRef={formRef}
          initialValue={{
            width: initialValue.width.toString(),
            height: initialValue.height.toString(),
            price: initialValue.price.toString()
          }}
          sizes={sizes}
          onSubmit={size => {
            props.onSubmit({
              width: parseFloat(size.width),
              height: parseFloat(size.height),
              price: size.price
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!formRef || !formRef.current) return;

            formRef.current.submitForm();
          }}
          color="primary"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
