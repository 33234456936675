import { authorised } from "../../request";
import { Rewarder } from "../../types/Rewarder";

export async function updateRewarder(id: string, data: Omit<Rewarder, "id">) {
  const API = authorised();

  return await API.request<Rewarder>({
    method: "PATCH",
    url: `/api/admin/rewarders/${id}/`,
    data
  });
}
