import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { FilmForm } from "../../forms/FilmForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createFilm } from "../../actions/film/createFilm";
import { RouteChildrenProps } from "react-router";

export function FilmCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/films"}>Films</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <FilmForm
        type="create"
        onSubmit={async values => {
          const response = await createFilm(values);

          props.history.push(`/films/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
