import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Product, ProductSize } from "../types/Product";
import { ProductOption } from "../types/ProductOption";
import { css } from "styled-components/macro";
import { Row, Column } from "../helpers/layout";
import { PrintSizes } from "../components/PrintSizes";
import { PrintOptions } from "../components/PrintOptions";
import SettingsIcon from "@material-ui/icons/Settings";
import { SizeConfigurator } from "../modals/SizeConfigurator";
import { Image } from "../components/Image";
import { calculateOrderItem } from "../helpers/product";
import NumberFormat from "react-number-format";
import { useCommonData } from "../context/CommonDataContext";
import { PrintDimensions } from "../components/PrintDimensions";

export function ProductConfigurator(props: {
  open: boolean;
  product: Product;
  initialValue: {
    size: ProductSize | null;
    options: ProductOption[];
  };
  handleClose: () => void;
  onSubmit: (
    product: Product,
    size: ProductSize,
    options: ProductOption[],
    total: string
  ) => void;
}) {
  const { product, initialValue } = props;
  const [size, setSize] = useState<ProductSize>(
    initialValue.size || product.sizes[0]
  );
  const { settings } = useCommonData();
  const [options, setOptions] = useState<ProductOption[]>(initialValue.options);
  const [showSizeConfigurator, setShowSizeConfigurator] = useState(false);
  const calculated = calculateOrderItem(
    {
      product: product,
      size,
      options,
    },
    settings
  );

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={props.open}
        onClose={props.handleClose}
      >
        <DialogTitle>Product Configurator</DialogTitle>
        <DialogContent>
          <Row>
            <div
              css={css`
                flex: 0 0 200px;
              `}
            >
              {product.featured_image && (
                <Image
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  file={product.featured_image}
                />
              )}
            </div>
            <div
              css={css`
                flex: 1 0 30%;
              `}
            >
              <Column>
                <div>
                  <Column>
                    <Row justify="space-between">
                      <Typography variant="h5">Size</Typography>
                      <div>
                        <Row
                          onClick={() => {
                            setShowSizeConfigurator(true);
                          }}
                        >
                          <SettingsIcon fontSize="small" />
                          <Typography variant="body2">Custom size</Typography>
                        </Row>
                      </div>
                    </Row>
                    <PrintSizes
                      sizes={product.sizes}
                      selected={size}
                      onClick={(size) => {
                        setSize(size);
                        setOptions([]);
                      }}
                    />
                  </Column>
                </div>
                {size && (
                  <PrintOptions
                    product={product}
                    size={size}
                    selected={options}
                    onSelect={(options) => {
                      setOptions(options);
                    }}
                  />
                )}
                <Column>
                  <PrintDimensions
                    calculated={calculated}
                    selectedOptions={options}
                  />
                  <Row align="center">
                    <Typography variant="h4">
                      Total:{" "}
                      <NumberFormat
                        value={calculated.total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </Typography>
                  </Row>
                </Column>
              </Column>
            </div>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!size}
            onClick={async () => {
              if (!size) return;

              try {
                await props.onSubmit(product, size, options, calculated.total);
                props.handleClose();
              } catch (e) {
                console.log(e);
              }
            }}
            color="primary"
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
      <SizeConfigurator
        open={showSizeConfigurator}
        sizes={product.sizes}
        initialValue={size}
        handleClose={() => {
          setShowSizeConfigurator(false);
        }}
        onSubmit={(size) => {
          setSize(size);
          setShowSizeConfigurator(false);
        }}
      />
    </React.Fragment>
  );
}
