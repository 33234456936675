import React, { useState } from "react";
import * as Formik from "formik";
import { Editor } from "react-draft-wysiwyg";
import Typography from "@material-ui/core/Typography";
import { ContentBlock, convertFromRaw, RawDraftContentState } from "draft-js";
import { css } from "styled-components/macro";
import { HROption } from "./EditorComponents/HROption";
import { SpacingOption } from "./EditorComponents/SpacingOption";
import Link from "./EditorComponents/Link";
import { useDebounceFunction } from "../../helpers/useDebounce";
import CustomImage from "./EditorComponents/CustomImage";

const fontOptions = [
  "freight-big-pro",
  "freight-big-light",
  "freight-big-light-uppercase",
  "freight-big-book",
  "freight-big-book-uppercase",
  "freight-big-medium",
  "freight-big-medium-uppercase",
  "freight-big-bold",
  "freight-big-bold-uppercase",
  "Gotham-thin",
  "Gotham-book",
];
const blockTypes = ["Normal", "Blockquote"];

const fontSizeOptions = [
  8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 30, 34, 36, 42, 48, 60,
  72, 96, 120, 144, 168, 192, 215, 240, 270, 302, 338, 378,
];

function getSimpleOption() {
  return {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "textAlign",
      "colorPicker",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    blockType: {
      options: blockTypes,
    },
    fontFamily: {
      options: fontOptions,
    },
    fontSize: {
      options: fontSizeOptions,
    },
  };
}

function getAllOptions() {
  return {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    blockType: {
      options: blockTypes,
    },
    fontFamily: {
      options: fontOptions,
    },
    fontSize: {
      options: fontSizeOptions,
    },
    image: {
      previewImage: true,
    },
  };
}

function customBlockRenderFunc(
  rawState: RawDraftContentState,
  contentBlock: ContentBlock
) {
  if (!rawState) return contentBlock;

  const contentState = convertFromRaw(rawState);
  const entityKey = contentBlock.getEntityAt(0);

  const entity = entityKey ? contentState.getEntity(entityKey) : undefined;

  const type = entity ? entity.getType() : undefined;
  if (type === "IMAGE") {
    return undefined;
  }
  return contentBlock;
}

export const PlainEditorField = React.memo(function (props: {
  label: string;
  value: RawDraftContentState;
  onChange: (state: RawDraftContentState) => void;
  simple?: boolean;
}) {
  const [state, setState] = useState<RawDraftContentState>(props.value);

  useDebounceFunction(
    () => {
      props.onChange(state);
    },
    [state],
    400
  );

  const customToolBarButtons = [
    // @ts-ignore
    <HROption />,
    // @ts-ignore
    <SpacingOption />,
    // @ts-ignore
    <Link />,
  ];

  if (!props.simple) {
    customToolBarButtons.push(
      // @ts-ignore
      <CustomImage />
    );
  }

  return (
    <div>
      <Typography variant="body2">{props.label}</Typography>
      <div
        css={css`
          margin-top: 10px;

          .DraftEditor-editorContainer {
            font-size: 16px;
            line-height: 1.68;
            letter-spacing: 0.02em;

            figure {
              margin: 0;
            }
          }
          .rdw-link-decorator-icon {
            display: none;
          }
        `}
      >
        <Editor
          initialContentState={state}
          handlePastedText={() => false}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          toolbarStyle={{
            background: "#eee",
            marginBottom: 0,
            borderRadius: "5px 5px 0 0",
          }}
          editorStyle={{
            height: 300,
            background: "#f6f6f6",
            padding: 10,
            borderRadius: "0 0 5px 5px",
          }}
          customBlockRenderFunc={customBlockRenderFunc.bind(null, state)}
          onContentStateChange={(state) => {
            setState(state);
          }}
          toolbar={props.simple ? getSimpleOption() : getAllOptions()}
          toolbarCustomButtons={customToolBarButtons}
        />
      </div>
    </div>
  );
});

export const EditorField = React.memo(function (props: {
  name: string;
  label: string;
  simple?: boolean;
}) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ form, field }: Formik.FastFieldProps) => {
        const state = field.value;

        return (
          <PlainEditorField
            label={props.label}
            value={state}
            onChange={(state) => {
              form.setFieldValue(props.name, state);
            }}
            simple={props.simple}
          />
        );
      }}
    />
  );
});
