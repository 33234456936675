import { authorised } from "../../request";
import { Film } from "../../types/Film";

export async function createFilm(data: Omit<Film, "id">) {
  const API = authorised();

  return await API.request<Film>({
    method: "POST",
    url: "/api/admin/films/",
    data
  });
}
