import { useState, useEffect } from "react";
import { authorised } from "./request";

export async function fetch<T>(url: string) {
  const API = authorised();

  return await API.request<T>({
    method: "GET",
    url: `/api/admin/${url}`,
  });
}

export function useFetch<T>(url: string, initialValue: T) {
  const [data, setData] = useState<T>(initialValue);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);

        const response = await fetch<T>(url);

        setData(response.data);
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.detail) {
          setError(e.response.data.detail);
        } else {
          setError("Error occured");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return {
    data,
    isLoading,
    error,
  };
}

export type PaginatedList<T> = {
  results: T[];
  count: number;
  next: string | null;
  previous: string | null;
};

type Filters = {
  [key: string]: string | number;
};

export function useList<T>(url: string, page: number = 1, filters?: Filters) {
  const params: Filters = {
    page,
    ...filters,
  };
  const queryparams = Object.keys(params).reduce((querystr, key) => {
    const joiner = querystr.length > 0 ? "&" : "?";

    return `${querystr}${joiner}${key}=${params[key]}`;
  }, "");

  const { data, isLoading, error } = useFetch<PaginatedList<T>>(
    `${url}/${queryparams}`,
    {
      count: 0,
      next: null,
      previous: null,
      results: [],
    }
  );

  return {
    results: data.results,
    count: data.count,
    hasPrevious: data.previous,
    hasNext: data.next,
    isLoading,
    error,
  };
}

export function useDetail<T>(url: string, id: string) {
  return useFetch<T | null>(`${url}/${id}/`, null);
}
