import React, { useState, useEffect } from "react";
import { refreshToken, login, logout } from "../request";

type LoginValues = { email: string; password: string };

type State = {
  isAuthenticated: boolean | null;
};

type Context = State & {
  login: (values: LoginValues) => Promise<void>;
  logout: () => Promise<void>;
};

const defaultState = {
  isAuthenticated: null
};

const UserStateContext = React.createContext<Context>({
  ...defaultState,
  login: async (_values: LoginValues) => {},
  logout: async () => {}
});

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<State>(defaultState);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        await refreshToken();

        setState(state => ({
          ...state,
          isAuthenticated: true
        }));
      } catch {
        setState(state => ({
          ...state,
          isAuthenticated: false
        }));
      }
    };

    fetchToken();
  }, []);

  const actions = {
    login: async (values: LoginValues) => {
      try {
        await login(values);

        setState(state => ({
          ...state,
          isAuthenticated: true
        }));
      } catch (e) {
        setState(state => ({
          ...state,
          isAuthenticated: false
        }));

        throw new Error(e.response.data.detail || "Something went wrong");
      }
    },
    logout: async () => {
      setState(state => ({
        ...state,
        isAuthenticated: false
      }));
      logout();
    }
  };

  return (
    <UserStateContext.Provider value={{ ...state, ...actions }}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useUserState() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }

  return context;
}
