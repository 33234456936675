import { authorised } from "../../request";
import { Product } from "../../types/Product";

export async function deleteProduct(id: string) {
  const API = authorised();

  return await API.request<Product>({
    method: "DELETE",
    url: `/api/admin/products/${id}/`
  });
}
