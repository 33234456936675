import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import * as Yup from "yup";
import { SelectField } from "./fields/SelectField";
import { BlocksField } from "./fields/BlocksField";
import { Serie } from "../types/Serie";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { getFrontendUrl } from "../helpers/router";
import FindInPage from "@material-ui/icons/FindInPage";
import { FormRow, FormLeftside, FormRightside } from "./Layout";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<
  Serie,
  "visibility" | "blocks" | "header_style" | "slug"
>;
type Callback = () => void;

export function SerieLandingForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  seriesList?: Serie[];
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          visibility: "DRAFT",
          blocks: [],
          header_style: "DEFAULT",
          slug: "",
        }
      }
      validationSchema={Yup.object().shape({
        visibility: Yup.string().required(),
        blocks: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(),
          })
        ),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Formik.Form>
          <FormRow>
            <FormLeftside>
              <BlocksField
                name="blocks"
                seriesList={props.seriesList}
                enableDuplicateToOtherPage
              />
            </FormLeftside>
            <FormRightside>
              <Column>
                <Paper>
                  <Column>
                    <SelectField
                      label="Visibility"
                      name="visibility"
                      options={[
                        {
                          value: "PUBLIC",
                          label: "Public",
                        },
                        {
                          value: "PRIVATE",
                          label: "Private",
                        },
                        {
                          value: "DRAFT",
                          label: "Draft",
                        },
                        {
                          value: "ARCHIVED",
                          label: "Archived",
                        },
                      ]}
                    />
                    <Row justify="flex-end">
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          window.open(
                            getFrontendUrl(`series/${values.slug}/`),
                            "_blank"
                          );
                        }}
                      >
                        <FindInPage />
                      </Button>
                      {props.onDelete && (
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setConfirmAction(() => {
                                return props.onDelete;
                              });
                            }}
                          >
                            Delete
                          </Button>
                          {confirmAction !== null && (
                            <ConfirmModal
                              confirmAction={confirmAction}
                              handleClose={() => {
                                setConfirmAction(null);
                              }}
                              open={true}
                              label="Please confirm deletion"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : props.type === "update" ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </div>
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
                <Paper>
                  <SelectField
                    label="Header Style"
                    name="header_style"
                    options={[
                      {
                        value: "DEFAULT",
                        label: "Default",
                      },
                      {
                        value: "DARK",
                        label: "Dark",
                      },
                      {
                        value: "OVER",
                        label: "Over",
                      },
                    ]}
                  />
                </Paper>
              </Column>
            </FormRightside>
          </FormRow>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
