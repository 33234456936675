import React, { useEffect } from "react";
import * as Formik from "formik";
import { TextField } from "./fields/TextField";
import * as Yup from "yup";
import { Row } from "../helpers/layout";
import styled, { css } from "styled-components/macro";
import { sanitizeInput } from "../helpers/utils";
import NumberFormat from "react-number-format";
import { ProductSize } from "../types/Product";

const FieldWrapper = styled.div`
  flex: 1 0 20%;
`;

type FormValues = {
  width: string;
  height: string;
  price: string;
};

type Interval = {
  from: ProductSize;
  to: ProductSize;
};

function getOrderedSizes(sizes: ProductSize[]) {
  return sizes.sort((a, b) => {
    if (a.width < b.width) {
      return -1;
    }

    if (a.width > b.width) {
      return 1;
    }

    return 0;
  });
}

function getInterval(width: number, sizes: ProductSize[]) {
  const ordered = getOrderedSizes(sizes);
  let interval: Interval | null = null;

  for (let i = 0; i < ordered.length; i++) {
    if (
      ordered[i + 1] &&
      ordered[i].width <= width &&
      width <= ordered[i + 1].width
    ) {
      interval = {
        from: ordered[i],
        to: ordered[i + 1],
      };
    }
  }

  // If size exceeds specified range - we will use the biggest two sizes as interval
  if (!interval && width > ordered[ordered.length - 1].width) {
    return {
      from: ordered[ordered.length - 2],
      to: ordered[ordered.length - 1],
    };
  }

  return interval;
}

function getIntervalPriceStep(interval: Interval) {
  const { to, from } = interval;
  const deltaPrice = parseFloat(to.price) - parseFloat(from.price);
  const deltaWidth = to.width - from.width;

  return deltaPrice / deltaWidth;
}

function calculatePrice(width: number, interval: Interval) {
  const priceStep = getIntervalPriceStep(interval);
  const widthDiff = width - interval.from.width;
  const newPrice = parseFloat(interval.from.price) + widthDiff * priceStep;

  return Math.round(newPrice);
}

function Recalculate({
  sizes,
  width,
  setFieldValue,
}: {
  sizes: ProductSize[];
  width: number;
  setFieldValue: (name: string, value: any) => void;
}) {
  useEffect(() => {
    const interval = getInterval(width, sizes);

    if (interval) {
      const price = calculatePrice(width, interval);

      setFieldValue("price", sanitizeInput(price, 5));
    }
  }, [width, sizes, setFieldValue]);

  return null;
}

export function PrintSizeForm(props: {
  formRef: React.MutableRefObject<any>;
  initialValue: FormValues;
  sizes: ProductSize[];
  onSubmit: (values: FormValues) => void;
}) {
  return (
    <Formik.Formik<FormValues>
      ref={props.formRef}
      initialValues={props.initialValue}
      validationSchema={Yup.object().shape({
        width: Yup.number().required(),
        height: Yup.number().required(),
        price: Yup.number(),
      })}
      onSubmit={async (values: any, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (error) {
          console.error(error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Formik.Form>
            <Row>
              <FieldWrapper>
                <Formik.FastField
                  name="width"
                  label="Width"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    if (!props.initialValue) return;

                    const val = event.target.value;
                    const width = parseFloat(props.initialValue.width);
                    const height = parseFloat(props.initialValue.height);
                    const newWidth = parseFloat(val);
                    const newHeight = (newWidth * height) / width;

                    setFieldValue("width", sanitizeInput(val, 0.5));
                    setFieldValue("height", sanitizeInput(newHeight, 0.5));
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Formik.FastField
                  name="height"
                  label="Height"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    if (!props.initialValue) return;

                    const val = event.target.value;
                    const width = parseFloat(props.initialValue.width);
                    const height = parseFloat(props.initialValue.height);
                    const newHeight = parseFloat(val);
                    const newWidth = (width * newHeight) / height;

                    setFieldValue("width", sanitizeInput(newWidth, 0.5));
                    setFieldValue("height", sanitizeInput(val, 0.5));
                  }}
                />
              </FieldWrapper>
              <div
                css={css`
                  flex: 0 0 auto;
                  width: auto;
                `}
              >
                <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  <label>Price: </label>
                </div>
                <div>
                  <strong>
                    <NumberFormat
                      value={values.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </strong>
                </div>
              </div>
            </Row>
            <Recalculate
              sizes={props.sizes}
              width={parseFloat(values.width)}
              setFieldValue={setFieldValue}
            />
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
