import React from "react";
import { Repeater } from "../../components/Repeater";
import * as Formik from "formik";
import { Row } from "../../helpers/layout";
import { TextField } from "formik-material-ui";
import styled from "styled-components";

const FieldWrapper = styled.div`
  flex: 1 0 20%;
`;

type PhotoSize = {
  width: string;
  height: string;
  price: string;
};

export const PhotoSizesField = React.memo(function(props: {
  name: string;
  value: PhotoSize[];
}) {
  return (
    <Repeater
      name={props.name}
      addNewLabel={"New size"}
      generateNewObject={() => {
        const empty: PhotoSize = {
          width: "",
          height: "",
          price: ""
        };
        return empty;
      }}
    >
      {({ idx }) => {
        return (
          <Row>
            <FieldWrapper>
              <Formik.FastField
                name={`${props.name}[${idx}].width`}
                label="Width"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            </FieldWrapper>
            <FieldWrapper>
              <Formik.FastField
                name={`${props.name}[${idx}].height`}
                label="Height"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            </FieldWrapper>
            <FieldWrapper>
              <Formik.FastField
                name={`${props.name}[${idx}].price`}
                label="Price"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            </FieldWrapper>
          </Row>
        );
      }}
    </Repeater>
  );
});
