import React, { useState, useRef, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { ListTable } from "../components/ListTable";
import Checkbox from "@material-ui/core/Checkbox";
import { Row } from "../helpers/layout";
import { DynamicForm, DynamicFormFields } from "../forms/DynamicForm";
import { Formik } from "formik";
import { Image } from "../components/Image";
import { Media } from "../types/Media";

export type AdditionalFields = Array<{
  key: string;
  name: string;
}>;

export function ModelSelector<
  T extends {
    id: number;
    name: string;
    featured_image?: Media | null;
    logo?: Media | null;
    image?: Media | null;
    [key: string]: any;
  }
>(props: {
  open: boolean;
  url: string;
  label: string;
  enableSearch?: boolean;
  formFields?: DynamicFormFields;
  onCreate?: (values: any) => Promise<void>;
  handleClose: () => void;
  onSelect: (items: T[]) => void;
  multiple?: boolean;
  canCreate?: boolean;
  additionalFields?: AdditionalFields;
  selectedValues?: T[];
}) {
  const { additionalFields } = props;
  const formRef = useRef<Formik>(null);
  const [selected, setSelected] = useState<T[]>([]);
  const [mode, setMode] = useState<"create" | "select">("select");

  useEffect(() => {
    if (props.open) {
      setSelected(props.selectedValues || []);
    }
  }, [props.open, props.selectedValues]);

  const head = [
    { label: "" },
    { label: "Image", width: "120px" },
    { label: "Name" },
  ];

  if (additionalFields) {
    additionalFields.forEach((field) => {
      head.push({
        label: field.name,
      });
    });
  }

  const onClose = () => {
    props.handleClose();
    setMode("select");
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      open={props.open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxHeight: "calc(100vh - 160px)",
        },
      }}
    >
      <DialogTitle>
        <Row justify="space-between" align="flex-end">
          <span>
            {mode === "select" ? "Select" : "Create"}{" "}
            {props.label.toLowerCase()}
          </span>

          {props.canCreate && (
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={() => {
                setMode((prevMode) =>
                  prevMode === "select" ? "create" : "select"
                );
              }}
            >
              {mode === "select" ? "Create new" : "Select from existing"}
            </Button>
          )}
        </Row>
      </DialogTitle>
      <DialogContent>
        {mode === "create" && props.formFields && props.onCreate && (
          <DynamicForm
            formRef={formRef}
            fields={props.formFields}
            onSubmit={async (values) => {
              await props.onCreate!(values);
              onClose();
            }}
          />
        )}
        {mode === "select" && (
          <ListTable<T>
            url={props.url}
            head={head}
            enableSearch={props.enableSearch && mode === "select"}
          >
            {(result) => {
              return (
                <TableRow key={result.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selected.find((s) => s.id === result.id)}
                      onChange={() => {
                        setSelected((selected) => {
                          const hasItem = selected.find(
                            (s) => s.id === result.id
                          );

                          if (hasItem) {
                            return selected.filter((s) => s.id !== result.id);
                          }

                          if (props.multiple) {
                            return [...selected, result];
                          }

                          return [result];
                        });
                      }}
                      value="checkedB"
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {typeof result.featured_image !== "undefined" && (
                      <Image
                        file={result.featured_image}
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                    {typeof result.logo !== "undefined" && (
                      <Image
                        file={result.logo}
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                    {typeof result.image !== "undefined" && (
                      <Image
                        file={result.image}
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {result.name}
                  </TableCell>
                  {additionalFields &&
                    additionalFields.map((field) => {
                      return (
                        <TableCell component="th" scope="row" key={field.key}>
                          {result[field.key] || ""}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            }}
          </ListTable>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {mode === "select" && (
          <Button
            onClick={() => {
              props.onSelect(selected);
            }}
            color="primary"
            disabled={!selected.length}
          >
            Select
          </Button>
        )}
        {mode === "create" && (
          <Button
            onClick={() => {
              if (!formRef || !formRef.current) return;

              formRef.current.submitForm();
            }}
            color="primary"
          >
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
