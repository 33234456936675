import { authorised } from "../../request";
import { Product } from "../../types/Product";

export async function updateProduct(
  id: string,
  data: Partial<Omit<Product, "id" | "serie" | "options">>
) {
  const API = authorised();

  return await API.request<Product>({
    method: "PATCH",
    url: `/api/admin/products/${id}/`,
    data,
  });
}
