import { authorised } from "../../request";
import { HelpText } from "../../types/HelpText";

export async function deleteHelpText(id: string) {
  const API = authorised();

  return await API.request<HelpText>({
    method: "DELETE",
    url: `/api/admin/help-texts/${id}/`
  });
}
