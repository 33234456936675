import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { RouteChildrenProps } from "react-router";
import { EstimateListContent } from "../../components/EstimateListContent";

export function EstimateList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/estimates/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Estimates</Typography>
      </Breadcrumbs>
      <Paper>
        <EstimateListContent />
      </Paper>
    </Layout>
  );
}
