import { authorised } from "../../request";
import { Menu, MenuItem } from "../../types/Menu";

export async function copyToMenu(id: number, data: MenuItem) {
  const API = authorised();

  const existinMenuData = await API.request<Menu>({
    method: "GET",
    url: `/api/admin/menus/${id}/`,
  });

  const newMenuData = {
    ...existinMenuData.data,
    items: [...existinMenuData.data.items, data],
  };
  return await API.request<Menu>({
    method: "PUT",
    url: `/api/admin/menus/${id}/`,
    data: newMenuData,
  });
}
