import { Media } from "../types/Media";
import { authorised } from "../request";

export async function storeUpload(upload_id: string) {
  const API = authorised();

  return await API.request<Media>({
    method: "POST",
    url: `/api/admin/store-upload/`,
    data: {
      upload_id
    }
  });
}
