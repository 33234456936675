import React from "react";
import * as Formik from "formik";
import { Column } from "../../helpers/layout";
import { EditorField } from "./EditorField";
import { PickField } from "./PickField";

export function BlockInHomesField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <EditorField name={`${field.name}.text`} label="Text" />
            <PickField
              name={`${field.name}.in_homes`}
              label="In Homes"
              url="in-homes"
              multiple
              enableSearch
              sortable
              relatedModel="InHome"
            />
          </Column>
        );
      }}
    />
  );
}
