import { authorised } from "../../request";
import { Setting } from "../../types/Setting";

export async function updateSetting(
  id: string,
  data: Partial<Omit<Setting, "id">>
) {
  const API = authorised();

  return await API.request<Setting>({
    method: "PATCH",
    url: `/api/admin/settings/${id}/`,
    data
  });
}
