import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { format } from 'date-fns';

import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { Announcement } from "../../types/Announcement";
import { RouteChildrenProps } from "react-router";

export function AnnouncementList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/announcements/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Announcements</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Announcement>
          url="announcements"
          head={[
            {
              label: "Name",
            },
            {
              label: "Location",
            },
            {
              label: 'Start Date',
            },
            {
              label: 'End Date',
            },
            {
              label: "Actions",
              align: "right",
            },
          ]}
        >
          {(result) => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.location.join(", ")}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.start_date &&
                    format(Date.parse(result.start_date), "MMM/dd/yy")}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {result.end_date &&
                    format(Date.parse(result.end_date), "MMM/dd/yy")}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/announcements/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
