import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { SelectField } from "./fields/SelectField";
import { Page } from "../types/Page";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { BlocksField } from "./fields/BlocksField";
import { SlugField } from "./fields/SlugField";
import { ImageField } from "./fields/ImageField";
import { SlugAutoCompleteField } from "./fields/SlugAutoCompleteField";
import { CheckboxWithLabel } from "formik-material-ui";
import { PickField } from "./fields/PickField";
import { getFrontendUrl } from "../helpers/router";
import FindInPage from "@material-ui/icons/FindInPage";
import { FormRow, FormLeftside, FormRightside } from "./Layout";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { css } from "styled-components/macro";
import { AxiosError } from "axios";

type FormValues = Pick<
  Page,
  | "name"
  | "slug"
  | "menu"
  | "description"
  | "about"
  | "keywords"
  | "header_style"
  | "visibility"
  | "blocks"
  | "family_friendly"
  | "featured_image"
  | "show_chat"
>;
type Callback = () => void;

export function PageForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  pagesList?: Page[];
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          slug: "",
          menu: null,
          description: "",
          about: "",
          keywords: "",
          visibility: "DRAFT",
          header_style: "DEFAULT",
          family_friendly: true,
          featured_image: null,
          blocks: [],
          show_chat: false,
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        slug: Yup.string().required(),
        description: Yup.string(),
        keywords: Yup.string(),
        visibility: Yup.string().required(),
        blocks: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(),
          })
        ),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Formik.Form>
          <FormRow>
            <FormLeftside>
              <Column>
                <Paper>
                  <Formik.FastField
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={SlugAutoCompleteField}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ field_name: "slug" }}
                  />
                  <Formik.FastField
                    name="slug"
                    label="Slug"
                    type="text"
                    fullWidth
                    component={SlugField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="description"
                    label="Description"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="about"
                    label="About"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="keywords"
                    label="Keywords"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <div
                    css={css`
                      padding: 20px 0;
                    `}
                  >
                    <div>
                      <Formik.FastField
                        name={"family_friendly"}
                        Label={{ label: "Family Friendly?" }}
                        component={CheckboxWithLabel}
                      />
                    </div>
                    <div>
                      <Formik.FastField
                        name={"show_chat"}
                        Label={{ label: "Show chat?" }}
                        component={CheckboxWithLabel}
                      />
                    </div>
                  </div>
                  <PickField
                    name="menu"
                    label="Menu"
                    url="menus"
                    enableSearch
                  />
                </Paper>
                <BlocksField
                  pagesList={props.pagesList}
                  name="blocks"
                  enableDuplicateToOtherPage
                />
              </Column>
            </FormLeftside>
            <FormRightside>
              <Column>
                <Paper>
                  <Column>
                    <SelectField
                      label="Visibility"
                      name="visibility"
                      options={[
                        {
                          value: "PUBLIC",
                          label: "Public",
                        },
                        {
                          value: "PRIVATE",
                          label: "Private",
                        },
                        {
                          value: "DRAFT",
                          label: "Draft",
                        },
                      ]}
                    />
                    <Row justify="flex-end">
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          window.open(getFrontendUrl(values.slug), "_blank");
                        }}
                      >
                        <FindInPage />
                      </Button>
                      {props.onDelete && (
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setConfirmAction(() => {
                                return props.onDelete;
                              });
                            }}
                          >
                            Delete
                          </Button>
                          {confirmAction !== null && (
                            <ConfirmModal
                              confirmAction={confirmAction}
                              handleClose={() => {
                                setConfirmAction(null);
                              }}
                              open={true}
                              label="Please confirm deletion"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : props.type === "update" ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </div>
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
                <Paper>
                  <SelectField
                    label="Header Style"
                    name="header_style"
                    options={[
                      {
                        value: "DEFAULT",
                        label: "Default",
                      },
                      {
                        value: "DARK",
                        label: "Dark",
                      },
                      {
                        value: "OVER",
                        label: "Over",
                      },
                    ]}
                  />
                </Paper>

                <Paper>
                  <ImageField name={"featured_image"} label="Featured Image" />
                </Paper>
              </Column>
            </FormRightside>
          </FormRow>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
