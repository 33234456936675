import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import * as Yup from "yup";
import { PlainSelectField, SelectField } from "./fields/SelectField";
import { Serie } from "../types/Serie";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { FormRow, FormLeftside, FormRightside } from "./Layout";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";

type FormValues = Pick<Serie, "visibility" | "layout_style">;
type Callback = () => void;

export function SerieShopConfigurationForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          visibility: "DRAFT",
          layout_style: "masonry",
        }
      }
      validationSchema={Yup.object().shape({
        visibility: Yup.string().required(),
        layout_style: Yup.string().oneOf(["masonry", "wide", "big"]).required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (error) {
          const typedError = error as any;
          const message =
            typedError.response && typedError.response.data
              ? Object.values(typedError.response.data).join(", ")
              : typedError.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, errors }) => (
        <Formik.Form>
          <FormRow>
            <FormLeftside>
              <Column>
                <Paper>
                  <Formik.FastField
                    name="layout_style"
                    render={({ form, field }: Formik.FastFieldProps) => {
                      return (
                        <div>
                          <PlainSelectField
                            name="layout_style"
                            label="Layout Style"
                            value={field.value}
                            fullWidth
                            onChange={(value) => {
                              form.setFieldValue("layout_style", value);
                            }}
                            options={[
                              {
                                value: "masonry",
                                label: "3 Column",
                              },
                              {
                                value: "wide",
                                label: "2 Column",
                              },
                              {
                                value: "big",
                                label: "Single Column",
                              },
                            ]}
                          />
                          {errors.layout_style && (
                            <Error text="Please select a layout style" />
                          )}
                        </div>
                      );
                    }}
                  />
                </Paper>
              </Column>
            </FormLeftside>
            <FormRightside>
              <Column>
                <Paper>
                  <Column>
                    <SelectField
                      label="Visibility"
                      name="visibility"
                      options={[
                        {
                          value: "PUBLIC",
                          label: "Public",
                        },
                        {
                          value: "PRIVATE",
                          label: "Private",
                        },
                        {
                          value: "DRAFT",
                          label: "Draft",
                        },
                        {
                          value: "ARCHIVED",
                          label: "Archived",
                        },
                      ]}
                    />
                    <Row justify="flex-end">
                      {props.onDelete && (
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setConfirmAction(() => {
                                return props.onDelete;
                              });
                            }}
                          >
                            Delete
                          </Button>
                          {confirmAction !== null && (
                            <ConfirmModal
                              confirmAction={confirmAction}
                              handleClose={() => {
                                setConfirmAction(null);
                              }}
                              open={true}
                              label="Please confirm deletion"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : props.type === "update" ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </div>
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
              </Column>
            </FormRightside>
          </FormRow>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
