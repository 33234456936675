import ImageIcon from "@material-ui/icons/Image";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import * as Formik from "formik";
import { EditorState, AtomicBlockUtils } from "draft-js";
import { MediaUploader } from "../../../modals/MediaUploader";
import { isImage } from "../../../helpers/utils";
import { getImageUrl } from "../../../helpers/getImageUrl";
import { Media } from "../../../types/Media";
import { Column } from "../../../helpers/layout";
import { TextField } from "formik-material-ui";

const normalizeSize = (size: string) => {
  if (size.endsWith("px") || size === "auto") {
    return size;
  }
  return `${size}px`;
};

// docs: https://github.com/facebook/draft-js/blob/main/examples/draft-0-10-0/media/media.html
const CustomImage = (props: {
  onChange: (value: string) => void;
  editorState: any;
}) => {
  const { editorState, onChange } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSizerOpen, setSizerOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<Media | null>(null);

  return (
    <div>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className="rdw-option-wrapper"
      >
        <ImageIcon />
      </button>

      {isOpen && (
        <MediaUploader
          open={true}
          handleClose={() => {
            setIsOpen(false);
          }}
          onSelect={(file) => {
            if (!isImage(file.mime_type)) {
              return;
            }
            setSelectedImage(file);
            setIsOpen(false);
            setSizerOpen(true);
          }}
        />
      )}

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
        open={isSizerOpen}
        onClose={() => setSizerOpen(false)}
      >
        <DialogTitle>Image Size</DialogTitle>
        <DialogContent>
          <Formik.Formik
            initialValues={{
              w: "auto",
              h: "auto",
            }}
            onSubmit={(values: { w: string; h: string }) => {
              const width = normalizeSize(values.w);
              const height = normalizeSize(values.h);

              // State Manipulation
              const contentState = editorState.getCurrentContent();
              const contentStateWithEntity = contentState.createEntity(
                "IMAGE",
                "MUTABLE",
                {
                  display: "block",
                  src: getImageUrl(selectedImage!),
                  width,
                  height,
                }
              );

              const entityKey =
                contentStateWithEntity.getLastCreatedEntityKey();
              const modifiedEditorState = EditorState.set(editorState, {
                currentContent: contentStateWithEntity,
              });

              const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                modifiedEditorState,
                entityKey,
                " "
              );
              // @ts-ignore
              onChange(newEditorState);

              // const firstBlock = newEditorState
              //   .getCurrentContent()
              //   .getFirstBlock();
              // const selection = newEditorState.getSelection();
              // const newNewState = AtomicBlockUtils.moveAtomicBlock(
              //   newEditorState,
              //   firstBlock,
              //   selection,
              //   "before"
              // );

              // // @ts-ignore
              // onChange(newNewState);

              setSizerOpen(false);
            }}
          >
            <Formik.Form>
              <Column>
                <Formik.FastField
                  name="w"
                  label="Image Width (100px | auto)"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
                <Formik.FastField
                  name="h"
                  label="Image Height (100px | auto)"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
                <Button variant="contained" type="submit">
                  Set
                </Button>
              </Column>
            </Formik.Form>
          </Formik.Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomImage;
