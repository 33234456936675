import { authorised } from "../../request";
import { Preset } from "../../types/Preset";

export async function createPreset(data: Omit<Preset, "id">) {
  const API = authorised();

  return await API.request<Preset>({
    method: "POST",
    url: "/api/admin/presets/",
    data
  });
}
