import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { InHomeForm } from "../../forms/InHomeForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateInHome } from "../../actions/in-home/updateInHome";
import { deleteInHome } from "../../actions/in-home/deleteInHome";
import { useDetail } from "../../useAPI";
import { InHome } from "../../types/InHome";

export function InHomeEdit(
  props: RouteChildrenProps<{
    inHomeId: string;
  }>
) {
  const inHomeId = props.match!.params.inHomeId;
  const { data, error } = useDetail<InHome>("in-homes", inHomeId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/in-homes"}>In Homes</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <InHomeForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateInHome(inHomeId, values);
          }}
          onDelete={async () => {
            await deleteInHome(inHomeId);

            props.history.push("/in-homes");
          }}
        />
      )}
    </Layout>
  );
}
