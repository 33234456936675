import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { RewarderForm } from "../../forms/RewarderForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createRewarder } from "../../actions/rewarder/createRewarder";
import { RouteChildrenProps } from "react-router";

export function RewarderCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/rewarders"}>Rewarders</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <RewarderForm
        type="create"
        onSubmit={async values => {
          const response = await createRewarder(values);

          props.history.push(`/rewarders/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
