import { authorised } from "../../request";
import { Rewarder } from "../../types/Rewarder";

export async function deleteRewarder(id: string) {
  const API = authorised();

  return await API.request<Rewarder>({
    method: "DELETE",
    url: `/api/admin/rewarders/${id}/`
  });
}
