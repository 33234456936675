import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { EstimateForm } from "../../forms/EstimateForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createEstimate } from "../../actions/estimate/createEstimate";
import { RouteChildrenProps } from "react-router";

export function EstimateCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/estimates"}>Estimates</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <EstimateForm
        type="create"
        onSubmit={async (values) => {
          const response = await createEstimate({
            name: values.name,
            custom_discount: values.custom_discount,
            items: values.items,
          });

          props.history.push(`/estimates/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
