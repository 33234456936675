import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { InHomeTagForm } from "../../forms/InHomeTagForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateInHomeTag } from "../../actions/in-home-tag/updateInHomeTag";
import { deleteInHomeTag } from "../../actions/in-home-tag/deleteInHomeTag";
import { useDetail } from "../../useAPI";
import { InHomeTag } from "../../types/InHomeTag";

export function InHomeTagEdit(
  props: RouteChildrenProps<{
    inHomeTagId: string;
  }>
) {
  const inHomeTagId = props.match!.params.inHomeTagId;
  const { data, error } = useDetail<InHomeTag>("in-home-tags", inHomeTagId);

  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/in-home-tags"}>In Home Tags</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <InHomeTagForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateInHomeTag(inHomeTagId, values);
          }}
          onDelete={async () => {
            await deleteInHomeTag(inHomeTagId);

            props.history.push("/in-home-tags");
          }}
        />
      )}
    </Layout>
  );
}
