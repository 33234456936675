import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { ProductTagForm } from "../../forms/ProductTagForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateProductTag } from "../../actions/product-tag/updateProductTag";
import { deleteProductTag } from "../../actions/product-tag/deleteProductTag";
import { useDetail } from "../../useAPI";
import { ProductTag } from "../../types/ProductTag";

export function ProductTagEdit(
  props: RouteChildrenProps<{
    productTagId: string;
  }>
) {
  const productTagId = props.match!.params.productTagId;
  const { data, error } = useDetail<ProductTag>("product-tags", productTagId);

  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/product-tags"}>Product Tags</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <ProductTagForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateProductTag(productTagId, values);
          }}
          onDelete={async () => {
            await deleteProductTag(productTagId);

            props.history.push("/product-tags");
          }}
        />
      )}
    </Layout>
  );
}
