import { authorised } from "../../request";
import { Serie } from "../../types/Serie";

export async function cloneSerie(id: string | number): Promise<{data: Serie}> {
  const API = authorised();

  return await API.request<Serie>({
    method: "POST",
    url: `/api/admin/series/${id}/clone/`
  });
}
