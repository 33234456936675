import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SerieConfigurationForm } from "../../forms/SerieConfigurationForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateSerie } from "../../actions/serie/updateSerie";
import { deleteSerie } from "../../actions/serie/deleteSerie";
import { useDetail } from "../../useAPI";
import { Serie } from "../../types/Serie";
import Button from "@material-ui/core/Button";
import { Row } from "../../helpers/layout";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { serializeDate, deserializeDate } from "../../helpers/utils";

const buttons = [
  {
    label: "Configuration",
    key: "configuration",
    url: "",
  },
  {
    label: "Landing",
    key: "landing",
    url: "/landing",
  },
  {
    label: "Shop Configuration",
    key: "shop-configuration",
    url: "/shop-configuration",
  },
  {
    label: "Product information",
    key: "product-information",
    url: "/product-information",
  },
];

export function SerieFormNavigation(props: {
  serieId: string;
  active:
    | "configuration"
    | "landing"
    | "shop-configuration"
    | "product-information";
}) {
  const history = useHistory();

  return (
    <div
      css={css`
        margin-bottom: 30px;
      `}
    >
      <Row>
        {buttons.map((button) => {
          return (
            <div key={button.key}>
              <Button
                variant="outlined"
                color={button.key === props.active ? "primary" : "secondary"}
                onClick={() => {
                  history.push(`/series/${props.serieId}${button.url}`);
                }}
              >
                {button.label}
              </Button>
            </div>
          );
        })}
      </Row>
    </div>
  );
}

export function SerieEditConfiguration(
  props: RouteChildrenProps<{
    serieId: string;
  }>
) {
  const serieId = props.match!.params.serieId;
  const { data, error } = useDetail<Serie>("series", serieId);
  return (
    <Layout>
      <Row justify="space-between" align="center">
        <Breadcrumbs>
          <Link to={"/series"}>Series</Link>
          <Typography color="textPrimary">
            {error ? error : data ? data.name : "Loading"}
          </Typography>
          <Typography color="textPrimary">Configuration</Typography>
        </Breadcrumbs>
        <SerieFormNavigation serieId={serieId} active="configuration" />
      </Row>
      {data && (
        <SerieConfigurationForm
          type="update"
          initialValues={{
            name: data.name,
            slug: data.slug,
            menu: data.menu,
            description: data.description,
            keywords: data.keywords,
            visibility: data.visibility,
            products: data.products,
            featured_image: data.featured_image,
            genre: data.genre,
            about: data.about,
            location: data.location,
            copyright_holder: data.copyright_holder,
            copyright_year: serializeDate(data.copyright_year),
            published_date: serializeDate(data.published_date),
            family_friendly: data.family_friendly,
          }}
          onSubmit={async (values) => {
            await updateSerie(serieId, {
              ...values,
              copyright_year: deserializeDate(values.copyright_year),
              published_date: deserializeDate(values.published_date),
            });
          }}
          onDelete={async () => {
            await deleteSerie(serieId);

            props.history.push("/series");
          }}
        />
      )}
    </Layout>
  );
}
