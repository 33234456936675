import { authorised } from "../../request";
import { Page } from "../../types/Page";

export async function updatePage(id: string, data: Omit<Page, "id">) {
  const API = authorised();

  return await API.request<Page>({
    method: "PATCH",
    url: `/api/admin/pages/${id}/`,
    data
  });
}
