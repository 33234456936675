import { authorised } from "../../request";
import { Estimate } from "../../types/Estimate";

export async function createEstimate(
  data: Pick<Estimate, "name" | "custom_discount" | "items">
) {
  const API = authorised();

  return await API.request<Estimate>({
    method: "POST",
    url: "/api/admin/estimates/",
    data: {
      name: data.name,
      custom_discount: data.custom_discount,
      items: data.items,
      status: "PENDING",
    },
  });
}
