import { authorised } from "../../request";
import { User } from "../../types/User";

export async function deleteUser(id: string) {
  const API = authorised();

  return await API.request<User>({
    method: "DELETE",
    url: `/api/admin/users/${id}/`,
  });
}
