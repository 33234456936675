import { authorised } from "../../request";
import { TaxRate } from "../../types/TaxRate";

export async function createTaxRate(data: Omit<TaxRate, "id">) {
  const API = authorised();

  return await API.request<TaxRate>({
    method: "POST",
    url: "/api/admin/tax-rates/",
    data
  });
}
