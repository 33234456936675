import { authorised } from "../../request";
import { Rewarder } from "../../types/Rewarder";

export async function createRewarder(data: Omit<Rewarder, "id">) {
  const API = authorised();

  return await API.request<Rewarder>({
    method: "POST",
    url: "/api/admin/rewarders/",
    data
  });
}
