import React from "react";
import { Repeater } from "../../components/Repeater";
import { Paper } from "../../components/Paper";
import { Row } from "../../helpers/layout";
import { ListTable } from "../../components/ListTable";
import { Press } from "../../types/Press";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Image } from "../../components/Image";
import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import * as Formik from "formik";
import { css } from "styled-components/macro";
import { Spacing } from "../../helpers/layout";

const SelectPressItemField = ({ name }: { name: string }) => {
  const [showSelect, setShowSelect] = React.useState(false);
  return (
    <Formik.Field
      name={name}
      render={({ form, field }: Formik.FieldProps) => {
        const res = field.value;
        return (
          <div
            css={css`
              display: flex;
              flex-flow: column nowrap;
              width: 100%;
              align-items: flex-start;
            `}
          >
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() => setShowSelect(true)}
            >
              {field.value ? "Change press" : "Select press"}
            </Button>
            {field.value && (
              <div
                css={css`
                  position: relative;
                  display: flex;
                  flex-flow: column nowrap;
                  align-items: center;
                  width: 100%;
                  margin-top: ${Spacing.l};
                `}
                key={res.id}
              >
                <Typography gutterBottom variant="h4">
                  {res.name}
                </Typography>
                <Image
                  file={res.logo}
                  style={{
                    width: "150px",
                  }}
                  width={150}
                />
              </div>
            )}
            <Dialog
              maxWidth="md"
              fullWidth
              scroll="paper"
              open={showSelect}
              onClose={() => setShowSelect(false)}
            >
              <DialogContent>
                <Paper>
                  <ListTable<Press>
                    url="press"
                    head={[
                      { label: "Cover", width: "150px" },
                      { label: "Name" },
                      { label: "Select", align: "right" },
                    ]}
                  >
                    {(result) => {
                      return (
                        <TableRow key={result.id}>
                          <TableCell className="pl-3 fw-normal">
                            <Image
                              file={result.cover}
                              style={{
                                width: "100%",
                              }}
                              width={150}
                            />
                          </TableCell>
                          <TableCell className="pl-3 fw-normal">
                            {result.name}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              onClick={() => {
                                form.setFieldValue(name, result);
                                setShowSelect(false);
                              }}
                            >
                              Select
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }}
                  </ListTable>
                </Paper>
              </DialogContent>
            </Dialog>
          </div>
        );
      }}
    />
  );
};

const BlockPressColumns = React.memo(({ name }: { name: string }) => {
  return (
    <Repeater
      name={`${name}.press_columns`}
      wrapper={Paper}
      enableReordering
      addNewLabel={"Add Press Item"}
      generateNewObject={() => {
        return {
          press_item: null,
        };
      }}
    >
      {({ idx }) => {
        return (
          <Row>
            <SelectPressItemField name={`${name}.press_columns.${idx}`} />
          </Row>
        );
      }}
    </Repeater>
  );
});

export default BlockPressColumns;
