import { authorised } from "../../request";
import { Preset } from "../../types/Preset";

export async function deletePreset(id: string) {
  const API = authorised();

  return await API.request<Preset>({
    method: "DELETE",
    url: `/api/admin/presets/${id}/`
  });
}
