import React, { useState, useEffect } from "react";
import { CommonData } from "../types/CommonData";
import { authorised } from "../request";
import { useUserState } from "./UserContext";
import { Settings } from "../types/Settings";

const CommonDataContext = React.createContext<CommonData>({
  settings: {},
});

export function CommonDataProvider(props: { children: React.ReactNode }) {
  const { isAuthenticated } = useUserState();
  const [data, setData] = useState<CommonData>({
    settings: {},
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const API = authorised();
        const settingsRequest = await API.request<Settings>({
          method: "GET",
          url: `/api/frontend/settings/`,
        });

        const settings = settingsRequest.data;

        setData((data) => {
          return {
            ...data,
            settings,
          };
        });
      } catch {
        setData((data) => {
          return {
            ...data,
            settings: {},
          };
        });
      }
    };

    if (isAuthenticated) {
      fetchSettings();
    }
  }, [isAuthenticated]);

  return (
    <CommonDataContext.Provider value={data}>
      {props.children}
    </CommonDataContext.Provider>
  );
}

export function useCommonData() {
  const context = React.useContext(CommonDataContext);

  if (context === undefined) {
    throw new Error("useCommonData must be used within a CommonDataProvider");
  }

  return context;
}
