import React, { useState } from "react";
import { OrderItem } from "../types/OrderItem";
import { css } from "styled-components/macro";
import { Row, Column, Spacing } from "../helpers/layout";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import TrashIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { ConfirmModal } from "../modals/ConfirmModal";
import { nl2br } from "../helpers/utils";
import { getImageUrl } from "../helpers/getImageUrl";

const SinglePrice = (props: { price: string; lineThrough?: boolean }) => (
  <NumberFormat
    value={props.price}
    displayType="text"
    thousandSeparator
    prefix="$"
    style={
      props.lineThrough
        ? { textDecoration: "line-through", color: "#aaa", marginLeft: "0.5em" }
        : {}
    }
  />
);

const Price = (props: { price: string; discount: string }) => {
  const price = parseFloat(props.price || "0");
  const discount = parseFloat(props.discount || "0");

  const discountedPrice = price - discount;
  if (discountedPrice === price) {
    return <SinglePrice price={props.price} />;
  }

  return (
    <div>
      <SinglePrice price={discountedPrice.toFixed(2)} />
      <SinglePrice price={props.price} lineThrough />
    </div>
  );
};

export function OrderItemPreview(props: {
  item: OrderItem;
  onEdit?: () => void;
  onDelete?: () => void;
}) {
  const [confirmAction, setConfirmAction] = useState<null | (() => void)>(null);
  const { product, size } = props.item;
  const paddingBottom =
    size && size.height && size.width
      ? (size.height / size.width) * 100
      : 56.25;
  const imageUrl = product.featured_image
    ? getImageUrl(product.featured_image)
    : "";

  const hasBookOption =
    props.item.book_option && JSON.stringify(props.item.book_option) !== "{}";

  return (
    <div
      css={css`
        padding: ${Spacing.m} 0;
        border-bottom: 1px solid #eee;
      `}
    >
      <Row gutter={Spacing.xl}>
        <div
          css={css`
            flex: 0 0 140px;
          `}
        >
          <div
            css={css`
              width: 100%;
              padding-bottom: ${paddingBottom.toFixed(2)}%;
              background-image: url(${imageUrl});
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
              height: 0;
            `}
          />
        </div>
        <div
          css={css`
            flex: 1 0 30%;
          `}
        >
          <Column>
            <Row align="center">
              <Typography variant="h5">{product.name}</Typography>
              {hasBookOption && (
                <Typography variant="body2">
                  &nbsp;({props.item.book_option!.name})
                </Typography>
              )}
            </Row>
            {product.estimate_description && (
              <div>{nl2br(product.estimate_description)}</div>
            )}
            {product.subtitle && (
              <Typography variant="body2">{product.subtitle}</Typography>
            )}
            <div>
              {props.item.size && (
                <Typography variant="body2">
                  <strong>Size:</strong> {props.item.size.width}" x{" "}
                  {props.item.size.height}"
                </Typography>
              )}
              {props.item.options.map((option) => {
                return (
                  <Typography key={option.type} variant="body2">
                    <strong>{option.type}:</strong> {option.name}
                  </Typography>
                );
              })}
            </div>
            {props.item.discount_code ? (
              <Typography variant="body2">
                Discount code: <strong>{props.item.discount_code}</strong>
              </Typography>
            ) : null}
          </Column>
        </div>
        <div
          css={css`
            flex: 0 0 50px;
          `}
        >
          <Price price={props.item.total} discount={props.item.discount} />
        </div>
        {props.onEdit && (
          <div
            css={css`
              flex: 0 0 auto;
            `}
          >
            <EditIcon
              onClick={() => {
                props.onEdit!();
              }}
            />
          </div>
        )}
        {props.onDelete && (
          <div
            css={css`
              flex: 0 0 auto;
            `}
          >
            <TrashIcon
              onClick={() => {
                setConfirmAction(() => {
                  return () => {
                    props.onDelete!();
                  };
                });
              }}
            />
            {confirmAction !== null && (
              <ConfirmModal
                confirmAction={confirmAction}
                open={true}
                handleClose={() => {
                  setConfirmAction(null);
                }}
                label="Please confirm deletion"
              />
            )}
          </div>
        )}
      </Row>
    </div>
  );
}
