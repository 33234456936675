import { authorised } from "../../request";
import { Serie } from "../../types/Serie";

export async function updateSerie(
  id: string,
  data: Partial<Omit<Serie, "id">>
) {
  const API = authorised();

  return await API.request<Serie>({
    method: "PATCH",
    url: `/api/admin/series/${id}/`,
    data
  });
}
