import React, { useState } from "react";
import * as Formik from "formik";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Row, Column } from "../../helpers/layout";
import { ModelSelector } from "../../modals/ModelSelector";
import { Product } from "../../types/Product";
import { ProductConfigurator } from "../../modals/ProductConfigurator";
import { createEstimateItem } from "../../actions/estimate/createEstimateItem";
import { updateEstimateItem } from "../../actions/estimate/updateEstimateItem";
import { EstimateItem } from "../../types/EstimateItem";
import { OrderItemPreview } from "../../components/OrderItemPreview";
import { OptionSelector } from "../../modals/OptionSelector";
import ProductIcon from "@material-ui/icons/Store";
import LineItemIcon from "@material-ui/icons/FormatListNumbered";
import { CustomLineItemModal } from "../../modals/CustomLineItemModal";
import { createProduct } from "../../actions/product/createProduct";
import uuidv4 from "uuid/v4";

export default function EstimateItemsField(props: {
  name: string;
  label: string;
  disabled?: boolean;
}) {
  const [showLineItemSelector, setShowLineItemSelector] = useState(false);
  const [showCustomLineItemCreator, setShowCustomLineItemCreator] = useState(
    false
  );
  const [showProductSelector, setShowProductSelector] = useState(false);
  const [
    showProductConfigurator,
    setShowProductConfigurator,
  ] = useState<EstimateItem | null>(null);

  return (
    <Formik.Field
      name={props.name}
      render={({ field, form }: Formik.FieldProps) => {
        return (
          <React.Fragment>
            <Column>
              <Typography variant="body2">{props.label}</Typography>

              {field.value.map((item: EstimateItem) => {
                return (
                  <OrderItemPreview
                    item={item}
                    key={`estimate-items--${item.id}`}
                    onEdit={
                      (item.product.type === "PHOTO" ||
                        item.product.type === "PAIRING") &&
                      !props.disabled
                        ? () => {
                            setShowProductConfigurator(item);
                          }
                        : undefined
                    }
                    onDelete={
                      !props.disabled
                        ? () => {
                            const newValues = field.value.filter(
                              (i: EstimateItem) => i.id !== item.id
                            );

                            form.setFieldValue(props.name, newValues);
                          }
                        : undefined
                    }
                  />
                );
              })}
              {!props.disabled && (
                <Row justify="flex-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setShowLineItemSelector(true);
                    }}
                  >
                    Add item
                  </Button>
                </Row>
              )}
            </Column>

            {/* Line Item Type Selector */}
            <OptionSelector
              open={showLineItemSelector}
              options={[
                {
                  icon: <ProductIcon />,
                  label: "Product",
                },
                {
                  icon: <LineItemIcon />,
                  label: "Custom Item",
                },
              ]}
              onSubmit={async (option) => {
                if (option.label === "Product") {
                  setShowProductSelector(true);
                } else {
                  setShowCustomLineItemCreator(true);
                }
              }}
              handleClose={() => {
                setShowLineItemSelector(false);
              }}
              buttonLabel="Next"
            />

            {/* Custom Line Item Editor */}
            <CustomLineItemModal
              open={showCustomLineItemCreator}
              handleClose={() => {
                setShowCustomLineItemCreator(false);
              }}
              initialValue={{
                name: "Custom Line Item",
                price: "0.00",
                estimate_description: "",
                featured_image: null,
              }}
              onSubmit={async (item) => {
                try {
                  const product = await createProduct({
                    name: item.name,
                    type: "LINE_ITEM",
                    slug: uuidv4(),
                    price: item.price,
                    estimate_description: item.estimate_description,
                    featured_image: item.featured_image,
                  });

                  const estimateItem = await createEstimateItem({
                    product: product.data,
                    size: null,
                    options: [],
                  });

                  form.setFieldValue(props.name, [
                    ...field.value,
                    estimateItem.data,
                  ]);

                  setShowCustomLineItemCreator(false);
                } catch (e) {
                  console.log(e);
                }
              }}
            />

            {/* Product Selector */}
            <ModelSelector<Product>
              open={showProductSelector}
              handleClose={() => {
                setShowProductSelector(false);
              }}
              label={"Product"}
              url={"products"}
              enableSearch
              onSelect={async (items: Product[]) => {
                const product = items[0];

                const response = await createEstimateItem({
                  product,
                  size: null,
                  options: [],
                });

                const item = response.data;

                form.setFieldValue(props.name, [...field.value, item]);

                setShowProductSelector(false);

                if (
                  item.product.type === "PAIRING" ||
                  item.product.type === "PHOTO"
                ) {
                  setShowProductConfigurator(response.data);
                }
              }}
            />

            {/* Print Product Configurator */}
            {showProductConfigurator && (
              <ProductConfigurator
                open={true}
                initialValue={showProductConfigurator}
                handleClose={() => {
                  setShowProductConfigurator(null);
                }}
                product={showProductConfigurator.product}
                onSubmit={async (product, size, options) => {
                  try {
                    const id = showProductConfigurator.id.toString();
                    const response = await updateEstimateItem(id, {
                      product,
                      size,
                      options,
                    });

                    form.setFieldValue(
                      props.name,
                      field.value.map((item: EstimateItem) => {
                        if (item.id === response.data.id) {
                          return response.data;
                        }

                        return item;
                      })
                    );
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            )}
          </React.Fragment>
        );
      }}
    />
  );
}
