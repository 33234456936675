import { authorised } from "../../request";
import { Post } from "../../types/Post";

export async function updatePost(id: string, data: Omit<Post, "id">) {
  const API = authorised();

  return await API.request<Post>({
    method: "PATCH",
    url: `/api/admin/posts/${id}/`,
    data
  });
}
