import React, { useState } from "react";
import * as Formik from "formik";
import * as Yup from "yup";
import { MenuItem } from "../types/Menu";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import uuidv4 from "uuid/v4";
import { Column } from "../helpers/layout";
import { SelectField } from "./fields/SelectField";
import { PickField } from "./fields/PickField";
import { copyToMenu } from "../actions/menu/copyToMenu";
import { Paper } from "../components/Paper";
import { ImageField } from "./fields/ImageField";

export function MenuItemForm(props: {
  formRef: React.MutableRefObject<any>;
  type: "create" | "update";
  initialValues: MenuItem | null;
  onSubmit: (values: MenuItem) => void;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  return (
    <Formik.Formik<MenuItem>
      ref={props.formRef}
      initialValues={
        props.initialValues || {
          uuid: uuidv4(),
          name: "",
          type: "link",
          menu: null,
          url: "",
          serie: null,
          new_window: false,
          disable_click: false,
          children: [],
          copy_to_menu: [],
          menu_item_cover: null,
          menu_item_cover_title: null,
          menu_item_cover_description: null,
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        url: Yup.string().when("type", {
          is: "link",
          then: Yup.string().required(),
        }),
        serie: Yup.string().nullable().when("type", {
          is: "serie",
          then: Yup.string().required(),
        }),
      })}
      onSubmit={async (values: MenuItem, { setSubmitting }) => {
        const { copy_to_menu, ...rest } = values;
        if (copy_to_menu && copy_to_menu.length > 0) {
          copy_to_menu.forEach(async (subMenu) => {
            await copyToMenu(subMenu.id!, rest);
          });
        }

        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (error) {
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values }) => (
        <Formik.Form>
          <Column>
            <SelectField
              label="Type"
              name="type"
              options={[
                {
                  value: "link",
                  label: "Link",
                },
                {
                  value: "title",
                  label: "Title",
                },
                {
                  value: "serie",
                  label: "Serie",
                },
                {
                  value: "search",
                  label: "Search",
                },
                {
                  value: "interior_designer",
                  label: "Interior Designer",
                },
                {
                  value: "hyfd_option",
                  label: "HYFD Option",
                },
              ]}
            />
            {values.type !== "hyfd_option" && (
              <>
                <PickField
                  name="menu"
                  label="Sub Menu"
                  url="menus"
                  pickFields={["id", "name"]}
                />
                <PickField
                  name="copy_to_menu"
                  label="Copy to Menu"
                  multiple
                  url="menus"
                  pickFields={["id", "name"]}
                />
              </>
            )}
            <Formik.FastField
              name="name"
              label="Name"
              type="text"
              fullWidth
              component={TextField}
              margin="normal"
              variant="outlined"
            />
            {values.type === "hyfd_option" && (
              <Formik.FastField
                name="show_custom_field"
                Label={{ label: 'Show "Please Specify"?' }}
                component={CheckboxWithLabel}
              />
            )}
            {values.type === "link" && (
              <React.Fragment>
                <Formik.FastField
                  name="url"
                  label="Url"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
                <Formik.FastField
                  name={"new_window"}
                  Label={{ label: "Open in a new window?" }}
                  component={CheckboxWithLabel}
                />
              </React.Fragment>
            )}
            {values.type === "serie" && (
              <PickField
                name="serie"
                label="Serie"
                url="series"
                pickFields={["id", "name"]}
              />
            )}
            {values.type !== "hyfd_option" && (
              <Paper>
                <ImageField name="menu_item_cover" label="Menu Item Cover" />
                <Formik.FastField
                  name="menu_item_cover_title"
                  label="Cover Title"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
                <Formik.FastField
                  name="menu_item_cover_description"
                  label="Cover Description"
                  type="text"
                  fullWidth
                  multiline
                  rows="4"
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
              </Paper>
            )}
            <Formik.FastField
              name={"disable_click"}
              Label={{ label: "Disable click?" }}
              component={CheckboxWithLabel}
            />
          </Column>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
