import React, { useState } from "react";
import * as Formik from "formik";
import { css } from "styled-components/macro";
import MuiTextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

export const FieldError: React.FunctionComponent<{ name: string }> = (
  props
) => {
  return (
    <Formik.ErrorMessage
      name={props.name}
      render={(errorMessage: any) => {
        if (typeof errorMessage !== "string") return null;

        return (
          <p
            css={css`
              color: red;
              font-size: 12px;
              padding-left: 10px;
            `}
          >
            {errorMessage}
          </p>
        );
      }}
    />
  );
};

const getPercentage = (value: string, subtotal: number) => {
  if (!subtotal) return 0;

  return (Number(value) / subtotal) * 100;
};

export function DiscountField(props: {
  name: string;
  label: string;
  fullWidth?: boolean;
  subtotal: number;
}) {
  const [mode, setMode] = useState<"fixed" | "percentage">("percentage");

  return (
    <div>
      <Formik.Field
        name={props.name}
        render={({ form, field }: Formik.FieldProps) => {
          const percentage = getPercentage(field.value, props.subtotal).toFixed(
            0
          );

          return (
            <div>
              <label>
                <Checkbox
                  checked={mode === "percentage"}
                  onChange={() => {
                    setMode((mode) => {
                      return mode === "percentage" ? "fixed" : "percentage";
                    });
                  }}
                  value="checkedB"
                  color="primary"
                  inputProps={{
                    "aria-label": "secondary checkbox",
                  }}
                />
                <span>Percentage</span>
              </label>
              <div>
                {mode === "fixed" && (
                  <MuiTextField
                    value={field.value}
                    label={props.label}
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => {
                      form.setFieldValue(props.name, event.target.value);
                    }}
                  />
                )}
                {mode === "percentage" && (
                  <MuiTextField
                    value={percentage}
                    label={`${props.label} (Percentage)`}
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => {
                      const { value } = event.target;
                      const fixed = (
                        (Number(value) * props.subtotal) /
                        100
                      ).toFixed(0);

                      form.setFieldValue(props.name, fixed);
                    }}
                  />
                )}

                <FieldError name={props.name} />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
