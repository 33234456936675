import { authorised } from "../../request";
import { Announcement } from "../../types/Announcement";

export async function updateAnnouncement(
  id: string,
  data: Omit<Announcement, "id">
) {
  const API = authorised();

  return await API.request<Announcement>({
    method: "PATCH",
    url: `/api/admin/announcements/${id}/`,
    data
  });
}
