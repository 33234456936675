import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { AwardForm } from "../../forms/AwardForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateAward } from "../../actions/award/updateAward";
import { deleteAward } from "../../actions/award/deleteAward";
import { useDetail } from "../../useAPI";
import { Award } from "../../types/Award";
import Button from "@material-ui/core/Button";

export function AwardEdit(
  props: RouteChildrenProps<{
    awardId: string;
  }>
) {
  const awardId = props.match!.params.awardId;
  const { data, error } = useDetail<Award>("awards", awardId);
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/awards/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create another
          </Button>
        }
      >
        <Link to={"/awards"}>Awards</Link>
        <Typography color="textPrimary">
          {error ? error : data ? `${data.reward}` : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <AwardForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateAward(awardId, values);
          }}
          onDelete={async () => {
            await deleteAward(awardId);

            props.history.push("/awards");
          }}
        />
      )}
    </Layout>
  );
}
