import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { RewarderForm } from "../../forms/RewarderForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateRewarder } from "../../actions/rewarder/updateRewarder";
import { deleteRewarder } from "../../actions/rewarder/deleteRewarder";
import { useDetail } from "../../useAPI";
import { Rewarder } from "../../types/Rewarder";

export function RewarderEdit(
  props: RouteChildrenProps<{
    rewarderId: string;
  }>
) {
  const rewarderId = props.match!.params.rewarderId;
  const { data, error } = useDetail<Rewarder>("rewarders", rewarderId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/rewarders"}>Rewarders</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <RewarderForm
          type="update"
          initialValues={data}
          onSubmit={async values => {
            await updateRewarder(rewarderId, values);
          }}
          onDelete={async () => {
            await deleteRewarder(rewarderId);

            props.history.push("/rewarders");
          }}
        />
      )}
    </Layout>
  );
}
