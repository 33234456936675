import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Announcement } from "../types/Announcement";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { EditorField } from "./fields/EditorField";
import { DateField } from "./fields/DateField";
import { formatISO } from "date-fns";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AutocompleteField } from "./fields/Autocomplete";
import { Page } from "../types/Page";
import { useList } from "../useAPI";
import { AxiosError } from "axios";

type FormValues = Pick<
  Announcement,
  | "name"
  | "location"
  | "start_date"
  | "end_date"
  | "desktop_text"
  | "mobile_text"
>;
type Callback = () => void;

export function AnnouncementForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);
  const { results } = useList<Page>("pages", 1, {
    page_size: 100,
  });
  const pageOptions = results.map((result) => {
    return {
      value: result.slug,
      label: result.name,
    };
  });

  const locationOptions = [
    {
      value: "ALL",
      label: "All pages",
    },
    {
      value: "IN_HOMES",
      label: "In Homes",
    },
    {
      value: "SHOP",
      label: "Shop pages",
    },
    ...pageOptions,
  ];

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          location: [],
          start_date: formatISO(new Date()),
          end_date: formatISO(new Date()),
          desktop_text: "",
          mobile_text: "",
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        location: Yup.string().required(),
        start_date: Yup.string().required().nullable(),
        end_date: Yup.string().required().nullable(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => {
        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Formik.FastField
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />

                      <AutocompleteField
                        name="location"
                        label="Pages"
                        options={locationOptions}
                        filterSelectedOptions
                      />
                      <DateField name="start_date" label="Start Date" />
                      <DateField name="end_date" label="End Date" />
                      <EditorField name={`desktop_text`} label="Desktop Text" />
                      <EditorField
                        name={`mobile_text`}
                        label="Mobile & Tablet Text"
                      />
                    </Column>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Row justify="flex-end">
                        {props.onDelete && (
                          <div>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                setConfirmAction(() => {
                                  return props.onDelete;
                                });
                              }}
                            >
                              Delete
                            </Button>
                            {confirmAction !== null && (
                              <ConfirmModal
                                confirmAction={confirmAction}
                                handleClose={() => {
                                  setConfirmAction(null);
                                }}
                                open={true}
                                label="Please confirm deletion"
                              />
                            )}
                          </div>
                        )}
                        <div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={25} />
                            ) : props.type === "update" ? (
                              "Update"
                            ) : (
                              "Create"
                            )}
                          </Button>
                        </div>
                      </Row>
                      {error && <Error text={error} />}
                    </Column>
                  </Paper>
                </Column>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
