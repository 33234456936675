import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import * as Yup from "yup";
import { Order } from "../types/Order";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import styled, { css } from "styled-components/macro";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import { AddressPreview } from "../components/AddressPreview";
import { OrderItemPreview } from "../components/OrderItemPreview";
import NumberFormat from "react-number-format";
import { SelectField } from "./fields/SelectField";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<Order, "status">;
type Callback = () => void;

const OrderPart = styled.div`
  width: 280px;
  flex: 1 0 280px;
`;

export function OrderForm(props: {
  type: "create" | "update";
  order: Order;
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const { order } = props;
  const [error, setError] = useState<string | null>(null);
  const paymentDate = order.payment_date ? new Date(order.payment_date) : null;
  const createdDate = new Date(order.created);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          status: "PENDING_PAYMENT",
        }
      }
      validationSchema={Yup.object().shape({
        status: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Formik.Form>
          <Row>
            <div
              css={css`
                flex: 1 0 50%;
                width: 50%;
              `}
            >
              <Column>
                <Paper>
                  <Row wrap="wrap" justify="space-between">
                    <div>
                      <Column>
                        <Typography variant="h4">
                          Order #{order.id} details
                        </Typography>
                        <div>
                          <div>
                            <strong>Payment via: </strong>
                            <span
                              css={`
                                color: ${order.payment_type === "AFFIRM"
                                  ? "red"
                                  : "black"};
                              `}
                            >
                              {order.payment_type
                                ? order.payment_type
                                : "Payment required"}
                            </span>
                          </div>

                          {paymentDate && (
                            <div>
                              <strong>Paid on:</strong>{" "}
                              {format(paymentDate, "MMM do, YYY")} at{" "}
                              {format(paymentDate, "hh:mm a")}
                            </div>
                          )}
                          <div>
                            <strong>Data created:</strong>{" "}
                            {format(createdDate, "MMM do, YYY")} at{" "}
                            {format(createdDate, "hh:mm a")}
                          </div>
                          <div>
                            <strong>Customer:</strong> {order.author.first_name}{" "}
                            {order.author.last_name}
                          </div>
                        </div>
                      </Column>
                    </div>
                    <div>
                      <SelectField
                        label="Status"
                        name="status"
                        options={[
                          {
                            value: "PENDING_PAYMENT",
                            label: "Pending Payment",
                          },
                          {
                            value: "COMPLETED",
                            label: "Completed",
                          },
                          {
                            value: "REFUNDED",
                            label: "Refunded",
                          },
                          {
                            value: "CANCELED",
                            label: "Canceled",
                          },
                          {
                            value: "PAYMENT_FAILED",
                            label: "Payment Failed",
                          },
                        ]}
                      />
                    </div>
                  </Row>
                </Paper>
                <Paper>
                  <Row wrap="wrap">
                    <OrderPart>
                      <Column>
                        <Typography variant="h5">Billing</Typography>
                        {order.billing_address ? (
                          <AddressPreview address={order.billing_address} />
                        ) : (
                          <p>Not specified</p>
                        )}
                      </Column>
                    </OrderPart>
                    <OrderPart>
                      <Column>
                        <Typography variant="h5">Shipping</Typography>
                        {order.shipping_address ? (
                          <AddressPreview address={order.shipping_address} />
                        ) : (
                          <p>Not specified</p>
                        )}
                      </Column>
                    </OrderPart>
                    <OrderPart>
                      <Column>
                        <Typography variant="h5">
                          Collector information
                        </Typography>
                        {order.collector_information ? (
                          <AddressPreview
                            address={order.collector_information}
                          />
                        ) : (
                          <p>Not specified</p>
                        )}
                      </Column>
                    </OrderPart>
                  </Row>
                </Paper>
                <Paper>
                  <Column>
                    <Typography variant="h4">Order items</Typography>
                    <div>
                      <Column>
                        {order.items.map((item) => {
                          return (
                            <OrderItemPreview
                              item={item}
                              key={`estimate-items--${item.id}`}
                            />
                          );
                        })}
                      </Column>
                    </div>
                  </Column>
                  <Row justify="flex-end">
                    <div
                      css={css`
                        text-align: right;
                      `}
                    >
                      <p>
                        Subtotal:{" "}
                        <NumberFormat
                          value={order.products_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </p>
                      <p>
                        Shipping:{" "}
                        <NumberFormat
                          value={order.shipping_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </p>
                      <p>
                        Discount:{" "}
                        <NumberFormat
                          value={order.discount_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </p>
                      <p>
                        <strong>
                          Total:{" "}
                          <NumberFormat
                            value={order.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </strong>
                      </p>
                    </div>
                  </Row>
                </Paper>
              </Column>
            </div>
            <div
              css={css`
                flex: 0 0 280px;
              `}
            >
              <Paper>
                <Column>
                  <div>
                    <p
                      css={css`
                        font-size: 12px;
                      `}
                    >
                      Saving order with "Completed" status will trigger user
                      order confirmation email.
                    </p>
                  </div>
                  <Row justify="flex-end">
                    {props.onDelete && (
                      <div>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setConfirmAction(() => {
                              return props.onDelete;
                            });
                          }}
                        >
                          Delete
                        </Button>
                        {confirmAction !== null && (
                          <ConfirmModal
                            confirmAction={confirmAction}
                            handleClose={() => {
                              setConfirmAction(null);
                            }}
                            open={true}
                            label="Please confirm deletion"
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={25} />
                        ) : props.type === "update" ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </div>
                  </Row>
                  {error && <Error text={error} />}
                </Column>
              </Paper>
            </div>
          </Row>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
