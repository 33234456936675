import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as Yup from "yup";
import { InHome } from "../types/InHome";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { InHomesProductsField } from "./fields/InHomesProductsField";
import { SlugAutoCompleteField } from "./fields/SlugAutoCompleteField";
import { SlugField } from "./fields/SlugField";
import { PickField } from "./fields/PickField";
import { InHomeTag } from "../types/InHomeTag";
import { createInHomeTag } from "../actions/in-home-tag/createInHomeTag";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<
  InHome,
  | "name"
  | "slug"
  | "description"
  | "products"
  | "tags"
  | "image"
  | "visible_in_archive"
>;
type Callback = () => void;

export function InHomeForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          name: "",
          slug: "",
          description: "",
          products: [],
          tags: [],
          image: null,
          visible_in_archive: true,
        }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        slug: Yup.string().required(),
        description: Yup.string(),
        products: Yup.array().of(Yup.object()),
        visible_in_archive: Yup.boolean(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Formik.Form>
          <Row>
            <div
              css={css`
                flex: 1 0 50%;
                width: 50%;
              `}
            >
              <Column>
                <Paper>
                  <Formik.FastField
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={SlugAutoCompleteField}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ field_name: "slug" }}
                  />
                  <Formik.FastField
                    name="slug"
                    label="Slug"
                    type="text"
                    fullWidth
                    component={SlugField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="description"
                    label="Description"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="visible_in_archive"
                    Label={{ label: "Visible in Archive?" }}
                    component={CheckboxWithLabel}
                  />
                  <PickField<InHomeTag>
                    name={`tags`}
                    multiple
                    enableSearch
                    canCreate
                    formFields={[
                      {
                        name: "name",
                        label: "Name",
                        type: "text",
                        required: true,
                      },
                      {
                        name: "caption",
                        label: "Caption",
                        type: "text",
                        required: false,
                      },
                      {
                        name: "category",
                        label: "Category",
                        type: "select",
                        defaultValue: "room",
                        options: [
                          {
                            value: "room",
                            label: "Room",
                          },
                          {
                            value: "frame",
                            label: "Frame",
                          },
                          {
                            value: "pairing",
                            label: "Pairing",
                          },
                          {
                            value: "series",
                            label: "Series",
                          },
                          {
                            value: "color",
                            label: "Color",
                          },
                        ],
                        required: true,
                      },
                      {
                        name: "image",
                        label: "Image",
                        type: "image",
                        required: true,
                      },
                    ]}
                    onCreate={async (v) => {
                      const response = await createInHomeTag(v);

                      const currentOptions = values.tags || [];

                      setFieldValue("tags", [...currentOptions, response.data]);
                    }}
                    label="Tags"
                    url="in-home-tags"
                  />
                </Paper>
                <Paper>
                  <InHomesProductsField />
                </Paper>
              </Column>
            </div>
            <div
              css={css`
                flex: 0 0 280px;
              `}
            >
              <Paper>
                <Column>
                  <Row justify="flex-end">
                    {props.onDelete && (
                      <div>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setConfirmAction(() => {
                              return props.onDelete;
                            });
                          }}
                        >
                          Delete
                        </Button>
                        {confirmAction !== null && (
                          <ConfirmModal
                            confirmAction={confirmAction}
                            handleClose={() => {
                              setConfirmAction(null);
                            }}
                            open={true}
                            label="Please confirm deletion"
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={25} />
                        ) : props.type === "update" ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </div>
                  </Row>
                  {error && <Error text={error} />}
                </Column>
              </Paper>
            </div>
          </Row>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
