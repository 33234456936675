import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { Press } from "../../types/Press";
import { RouteChildrenProps } from "react-router";
import { Image } from "../../components/Image";

export function PressList(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={() => {
              props.history.push("/press/create");
            }}
            variant="contained"
            size="large"
            color="secondary"
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">Press</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<Press>
          url="press"
          head={[
            { label: "Cover", width: "150px" },
            { label: "Name" },
            { label: "Actions", align: "right" },
          ]}
        >
          {(result) => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  <Image
                    file={result.cover}
                    style={{
                      width: "100%",
                    }}
                    width={150}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell align="right">
                  <Link to={`/press/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </Paper>
    </Layout>
  );
}
