import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SerieLandingForm } from "../../forms/SerieLandingForm";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateSerie } from "../../actions/serie/updateSerie";
import { deleteSerie } from "../../actions/serie/deleteSerie";
import { useDetail, useList } from "../../useAPI";
import { Serie } from "../../types/Serie";
import { Row } from "../../helpers/layout";
import { SerieFormNavigation } from "./SerieEditConfiguration";

export function SerieEditLanding(
  props: RouteChildrenProps<{
    serieId: string;
  }>
) {
  const serieId = props.match!.params.serieId;
  const { data, error } = useDetail<Serie>("series", serieId);
  const { results } = useList<Serie>('series', 1, {'page_size': 100});

  return (
    <Layout>
      <Row justify="space-between" align="center">
        <Breadcrumbs>
          <Link to={"/series"}>Series</Link>
          <Typography color="textPrimary">
            {error ? error : data ? data.name : "Loading"}
          </Typography>
          <Typography color="textPrimary">Landing</Typography>
        </Breadcrumbs>
        <SerieFormNavigation serieId={serieId} active="landing" />
      </Row>
      {data && (
        <SerieLandingForm
          type="update"
          initialValues={{
            visibility: data.visibility,
            blocks: data.blocks,
            header_style: data.header_style,
            slug: data.slug
          }}
          seriesList={results}
          onSubmit={async values => {
            await updateSerie(serieId, values);
          }}
          onDelete={async () => {
            await deleteSerie(serieId);

            props.history.push("/series");
          }}
        />
      )}
    </Layout>
  );
}
