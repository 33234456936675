import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Award } from "../types/Award";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { PickField } from "./fields/PickField";
import { css } from "styled-components/macro";
import { createRewarder } from "../actions/rewarder/createRewarder";
import { Rewarder } from "../types/Rewarder";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<
  Award,
  "reward" | "link" | "year" | "products" | "films" | "rewarder"
>;
type Callback = () => void;

export function AwardForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          reward: "",
          link: "",
          year: new Date().getFullYear(),
          products: [],
          films: [],
          rewarder: null,
        }
      }
      validationSchema={Yup.object().shape({
        reward: Yup.string(),
        link: Yup.string(),
        year: Yup.number(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Formik.Form>
          <Row>
            <div
              css={css`
                flex: 1 0 50%;
                width: 50%;
              `}
            >
              <Column>
                <Paper>
                  <Formik.FastField
                    name="reward"
                    label="Reward"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="year"
                    label="Year"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="link"
                    label="Link"
                    type="text"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                </Paper>
                <Paper>
                  <Column>
                    <PickField<Rewarder>
                      name="rewarder"
                      label="Rewarder"
                      url="rewarders"
                      canCreate
                      formFields={[
                        {
                          name: "name",
                          label: "Name",
                          type: "text",
                          required: true,
                        },
                        {
                          name: "logo",
                          label: "Logo",
                          type: "image",
                          required: true,
                        },
                      ]}
                      onCreate={async (values) => {
                        const response = await createRewarder(values);

                        setFieldValue("rewarder", response.data);
                      }}
                    />
                    <PickField
                      name="products"
                      label="Products"
                      url="products"
                      multiple
                      enableSearch
                    />
                    <PickField
                      name="films"
                      label="Films"
                      url="films"
                      multiple
                    />
                  </Column>
                </Paper>
              </Column>
            </div>
            <div
              css={css`
                flex: 0 0 280px;
              `}
            >
              <Paper>
                <Column>
                  <Row justify="flex-end">
                    {props.onDelete && (
                      <div>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setConfirmAction(() => {
                              return props.onDelete;
                            });
                          }}
                        >
                          Delete
                        </Button>
                        {confirmAction !== null && (
                          <ConfirmModal
                            confirmAction={confirmAction}
                            handleClose={() => {
                              setConfirmAction(null);
                            }}
                            open={true}
                            label="Please confirm deletion"
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={25} />
                        ) : props.type === "update" ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </div>
                  </Row>
                  {error && <Error text={error} />}
                </Column>
              </Paper>
            </div>
          </Row>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
}
