import { authorised } from "../../request";
import { Product } from "../../types/Product";

export async function createProduct(
  data: Partial<Omit<Product, "id" | "serie" | "options">>
) {
  const API = authorised();

  return await API.request<Product>({
    method: "POST",
    url: "/api/admin/products/",
    data,
  });
}
