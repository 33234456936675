import { authorised } from "../../request";
import { TaxRate } from "../../types/TaxRate";

export async function updateTaxRate(id: string, data: Omit<TaxRate, "id">) {
  const API = authorised();

  return await API.request<TaxRate>({
    method: "PATCH",
    url: `/api/admin/tax-rates/${id}/`,
    data
  });
}
