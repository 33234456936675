import { authorised } from "../../request";
import { Page } from "../../types/Page";

export async function clonePage(id: string | number): Promise<{data: Page}> {
  const API = authorised();

  return await API.request<Page>({
    method: "POST",
    url: `/api/admin/pages/${id}/clone/`
  });
}
