import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { ProductOptionForm } from "../../forms/ProductOptionForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createProductOption } from "../../actions/product-option/createProductOption";
import { RouteChildrenProps } from "react-router";

export function ProductOptionCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/product-options"}>Product Options</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <ProductOptionForm
        type="create"
        onSubmit={async values => {
          const response = await createProductOption(values);

          props.history.push(`/product-options/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
