import { authorised } from "../../request";
import { Serie } from "../../types/Serie";

export async function createSerie(data: Omit<Serie, "id" | "header_style">) {
  const API = authorised();

  return await API.request<Serie>({
    method: "POST",
    url: "/api/admin/series/",
    data
  });
}
