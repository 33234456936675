import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { updateSerie } from "../../actions/serie/updateSerie";
import { deleteSerie } from "../../actions/serie/deleteSerie";
import { useDetail } from "../../useAPI";
import { Serie } from "../../types/Serie";
import { SerieShopConfigurationForm } from "../../forms/SerieShopConfigurationForm";
import { SerieFormNavigation } from "./SerieEditConfiguration";
import { Row } from "../../helpers/layout";

export function SerieEditShopConfiguration(
  props: RouteChildrenProps<{
    serieId: string;
  }>
) {
  const serieId = props.match!.params.serieId;
  const { data, error } = useDetail<Serie>("series", serieId);
  return (
    <Layout>
      <Row justify="space-between" align="center">
        <Breadcrumbs>
          <Link to={"/series"}>Series</Link>
          <Typography color="textPrimary">
            {error ? error : data ? data.name : "Loading"}
          </Typography>
          <Typography color="textPrimary">Shop Configuration</Typography>
        </Breadcrumbs>
        <SerieFormNavigation serieId={serieId} active="shop-configuration" />
      </Row>
      {data && (
        <SerieShopConfigurationForm
          type="update"
          initialValues={data}
          onSubmit={async (values) => {
            await updateSerie(serieId, values);
          }}
          onDelete={async () => {
            await deleteSerie(serieId);

            props.history.push("/series");
          }}
        />
      )}
    </Layout>
  );
}
