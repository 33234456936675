import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Row } from "../helpers/layout";
import Card from "@material-ui/core/Card";
import { css } from "styled-components/macro";

export type Option = {
  label: string;
  icon?: JSX.Element;
  initialValue?: any;
  value?: string | number;
};

export function OptionSelector(props: {
  open: boolean;
  handleClose: () => void;
  options: Option[];
  onSubmit: (option: Option) => void;
  buttonLabel?: string;
}) {
  const [option, setOption] = useState<Option | null>(null);
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Select option</DialogTitle>
      <DialogContent>
        <Row wrap="wrap">
          {props.options.map((o, i) => {
            return (
              <Card
                onClick={() => {
                  setOption(o);
                }}
                key={`options-selector--${o.label}--${i}`}
                raised={option ? option.label === o.label : false}
              >
                <div
                  css={css`
                    width: 100px;
                    height: 100px;
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    cursor: pointer;
                  `}
                >
                  {o.icon && (
                    <span
                      css={css`
                        margin-bottom: 5px;
                      `}
                    >
                      {o.icon}
                    </span>
                  )}

                  {o.label}
                </div>
              </Card>
            );
          })}
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!option}
          onClick={() => {
            if (option) {
              props.onSubmit(option);
              props.handleClose();
            }
          }}
          color="primary"
        >
          {props.buttonLabel || "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
