import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

type Callback = () => void;

export function ConfirmModal(props: {
  open: boolean;
  handleClose: () => void;
  confirmAction: Callback;
  label?: string;
}) {
  return (
    <Dialog maxWidth="sm" fullWidth={true} scroll="paper" open={props.open}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>{props.label}</DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.confirmAction();
            props.handleClose();
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
