import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { useLocation, useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { useList } from "../useAPI";
import { SearchForm } from "./SearchForm";
import { Button } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

export function ListTable<T>(props: {
  url: string;
  head: Array<{
    label: string;
    align?: "inherit" | "right" | "left" | "center" | "justify";
    width?: string;
    direction?: "asc" | "desc";
    action?: (event: React.MouseEvent<HTMLElement>) => void;
  }>;
  children: (result: T) => React.ReactNode;
  enableSearch?: boolean;
  emptyMessage?: string;
  showMax?: number;
}) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryPage = params.get("page");
  const order = params.get("ordering");
  const [searchQuery, setSearchQuery] = useState("");
  const { head, children, url, showMax } = props;
  const page = queryPage ? parseInt(queryPage) : 1;
  const rowsPerPage = showMax || 100;
  const filters: {
    [key: string]: string | number;
  } = {
    page_size: rowsPerPage,
  };

  if (searchQuery) {
    filters["name"] = searchQuery;
  }
  if (order) {
    filters["ordering"] = order;
  } else {
    filters["ordering"] = "-created";
  }
  const { results, isLoading, count } = useList<T>(url, page, filters);

  return (
    <div
      css={css`
        position: relative;
        min-height: 120px;
      `}
    >
      {props.enableSearch && (
        <SearchForm
          query={searchQuery}
          onSearch={(query) => {
            setSearchQuery(query);
          }}
        />
      )}
      {results.length > 0 ? (
        <React.Fragment>
          <Table>
            <TableHead>
              <TableRow>
                {head.map((item) => {
                  return (
                    <TableCell
                      align={item.align}
                      key={item.label}
                      style={{ width: item.width ? item.width : "auto" }}
                    >
                      {item.label}
                      {item.action && (
                        <Button
                          size="small"
                          onClick={item.action}
                          style={{ minWidth: 0 }}
                        >
                          {item.direction === "asc" ? (
                            <ArrowDownwardIcon fontSize="small" />
                          ) : (
                            <ArrowUpwardIcon fontSize="small" />
                          )}
                        </Button>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {results.map((result: T) => children(result))}
            </TableBody>
          </Table>
          {!showMax && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={(_event, page) => {
                const queryPath = order
                  ? `?page=${page + 1}&ordering=${order}`
                  : `?page=${page + 1}`;
                history.push(queryPath);
              }}
            />
          )}
        </React.Fragment>
      ) : !isLoading ? (
        <Typography variant="body2">
          {props.emptyMessage || "There are no items yet"}
        </Typography>
      ) : null}

      {isLoading && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding-top: 100px;
            margin: auto;
            box-sizing: border-box;
            height: 100%;
            background: rgba(255, 255, 255, 0.9);
            text-align: center;
          `}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
