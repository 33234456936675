import { authorised } from "../../request";
import { Page } from "../../types/Page";

export async function createPage(data: Omit<Page, "id">) {
  const API = authorised();

  return await API.request<Page>({
    method: "POST",
    url: "/api/admin/pages/",
    data
  });
}
