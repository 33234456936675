import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PageForm } from "../../forms/PageForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createPage } from "../../actions/page/createPage";
import { RouteChildrenProps } from "react-router";

export function PageCreate(props: RouteChildrenProps<{}>) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/pages"}>Pages</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <PageForm
        type="create"
        onSubmit={async values => {
          const response = await createPage(values);

          props.history.push(`/pages/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
