import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { HelpText } from "../types/HelpText";
import { Row, Column } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { SlugField } from "./fields/SlugField";
import { EditorField } from "./fields/EditorField";
import { ConfirmModal } from "../modals/ConfirmModal";
import { Error } from "../components/Error";
import { AxiosError } from "axios";

type FormValues = Pick<HelpText, "title" | "label" | "identifier" | "text">;
type Callback = () => void;

export function HelpTextForm(props: {
  type: "create" | "update";
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  onDelete?: () => Promise<void>;
}) {
  const [error, setError] = useState<string | null>(null);
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  return (
    <Formik.Formik<FormValues>
      initialValues={
        props.initialValues || {
          title: "",
          label: "",
          identifier: "",
          text: "",
        }
      }
      validationSchema={Yup.object().shape({
        title: Yup.string().required(),
        label: Yup.string().required(),
        identifier: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (err) {
          const error = err as AxiosError;
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();

          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Formik.Form>
            <Row>
              <div
                css={css`
                  flex: 1 0 50%;
                  width: 50%;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Formik.FastField
                        name="title"
                        label="Title"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />

                      <Formik.FastField
                        name="identifier"
                        label="Identifier"
                        type="text"
                        fullWidth
                        component={SlugField}
                        margin="normal"
                        variant="outlined"
                      />

                      <Formik.FastField
                        name="label"
                        label="Label"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />

                      <EditorField name={`text`} label="Text" />
                    </Column>
                  </Paper>
                </Column>
              </div>
              <div
                css={css`
                  flex: 0 0 280px;
                `}
              >
                <Column>
                  <Paper>
                    <Column>
                      <Row justify="flex-end">
                        {props.onDelete && (
                          <div>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                setConfirmAction(() => {
                                  return props.onDelete;
                                });
                              }}
                            >
                              Delete
                            </Button>
                            {confirmAction !== null && (
                              <ConfirmModal
                                confirmAction={confirmAction}
                                handleClose={() => {
                                  setConfirmAction(null);
                                }}
                                open={true}
                                label="Please confirm deletion"
                              />
                            )}
                          </div>
                        )}
                        <div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={25} />
                            ) : props.type === "update" ? (
                              "Update"
                            ) : (
                              "Create"
                            )}
                          </Button>
                        </div>
                      </Row>
                      {error && <Error text={error} />}
                    </Column>
                  </Paper>
                </Column>
              </div>
            </Row>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
