import { authorised } from "../../request";
import { Announcement } from "../../types/Announcement";

export async function createAnnouncement(data: Omit<Announcement, "id">) {
  const API = authorised();

  return await API.request<Announcement>({
    method: "POST",
    url: "/api/admin/announcements/",
    data
  });
}
